import React, {Component, Fragment} from 'react';
import {Button, Col, Input, Modal, Form, List, Checkbox} from "antd";
import Resources from "../scripts/resources";
import API from '../scripts/api'
import { EditorState, convertToRaw, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const CheckboxGroup = Checkbox.Group;

class RolePermission extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allPermissions: [],
            rolePermissions: [],
            role: '',
            type: "panel",
            Data: '',
            showDrawer: true,
            DrawerData: "",
            editorState: EditorState.createEmpty(),
            editorStateShortDesc: EditorState.createEmpty(),
        }
        this.formRef = React.createRef();
    }

    handleSubmit = (data = {}) => {
        const formData = new FormData();
        console.log("submit modelllllllllllllll")
        console.log("rolePermissions", this.state.rolePermissions)
        console.log("role_id", this.state.role.id)
        formData.append('role_id', this.state.role.id);
        formData.append('permissions', this.state.rolePermissions);

        this.props.onSubmit(formData);
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();

        // this.setState({
        //     addresses: [{id: 0}],
        //     phones: []
        // });
        // window.location.reload()
    };


    handleDidMount = async (data = {}) => {
        // await this.setState({editorState : "", DrawerData:""})
        // const cloneData = await cloneDeep(data);
        // await this.formRef.current.setFieldsValue(cloneData);
        // await this.setState({editMode: !!cloneData.id, picList: []});
        // if(this.state.editMode){
        //     await this.setState({type: cloneData.type});
        // }
        await this.getRoles(data);

    }

    getRoles = (role) => {
        this.setState({role: role})
        let permissions = []
        API.acl.role(role.id)
            .then(async res => {
                await this.setState({allPermissions: res.allPermissions})
                await res.rolePermissions.map(item => {
                    permissions.push(item._id)
                })
                await this.setState({rolePermissions: permissions})
            })
    }

    onChange = (checkedValues) => {
        console.log('checked = ', checkedValues);
        this.setState({rolePermissions : checkedValues})
    }
    render() {
        const {submitting, visible} = this.props;
        const {picList, editMode, rolePermissions, allPermissions, type} = this.state;
        const {TextArea} = Input;

        const plainOptions = ['مدیریت دسترسی برای نقش خاص'];
        console.log("ffffff", rolePermissions)

        return (
            <Fragment>
                <Modal
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onCancel={this.props.onCancel}
                    footer={null}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} onFinish={(value) => this.handleSubmit(value)} layout="vertical">
                        <Form.Item name="rules" >
                            <CheckboxGroup options={plainOptions} defaultValue={['Apple']}  />

                            {allPermissions && allPermissions.length &&
                            <Checkbox.Group  value={rolePermissions} onChange={this.onChange}  className="ant-checkbox-group-ltr" style={{display: 'block'}}>
                                    <List>
                                        {
                                            allPermissions.map((item) =>
                                                <List.Item style={{direction:"ltr"}}>
                                                    <List.Item.Meta />

                                                    <div  className="ant-list-item-meta-description">
                                                        <div >
                                                            {item[0].section}
                                                        </div>
                                                        <div >
                                                            {
                                                                item.length &&
                                                                item.map((x, i) =>
                                                                    <Checkbox
                                                                        key={i}
                                                                        value={x._id}
                                                                        name={x.showName}
                                                                    >
                                                                        {x.showName}
                                                                    </Checkbox>
                                                                )
                                                            }
                                                        </div>

                                                    </div>
                                                </List.Item>
                                            )
                                        }
                                    </List>
                            </Checkbox.Group>
                            }
                        </Form.Item>

                        <Form.Item>
                            <Button style={{float: 'left'}} type="primary" htmlType="submit">
                                {Resources.confirm}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default RolePermission;
