import {message} from 'antd'

const Actions = {
    OPEN_MENU: 'OPEN_MENU',
    SET_ROUTE: 'SET_ROUTE',
    UPDATE_USER: 'UPDATE_USER',
    SET_RULES: 'SET_RULES',
    SET_TEACHER_LESSON_CHAPTER_DATA: 'SET_TEACHER_LESSON_CHAPTER_DATA',
    SET_CONSOLTANT: 'SET_CONSOLTANT'
};

const Constants = {
    /** Main Server **/
    // baseUrl: 'https://apis.shenasamelk.com/api/panel/',
    // frontUrl: 'https://shenasamelk.com/',
    // imagePath: 'https://apis.shenasamelk.com/',
    // siteUrl: 'https://apis.shenasamelk.com/',
    /** Test Server **/
    baseUrl: 'http://167.235.30.26/api/panel/',
    chatUrl: 'http://chat.irluck.ir/chat/',
    frontUrl: 'http://irluck.ir/',
    imagePath: 'http://167.235.30.26/',
    siteUrl: 'http://167.235.30.26/',
    /** Localhost **/
    // baseUrl: 'http://127.0.0.1:8000/api/panel/',
    // chatUrl: 'http://localhost:3000/chat/',
    // frontUrl: 'http://localhost:3000/',
    // imagePath: 'http://127.0.0.1:8000/',
    // siteUrl: 'http://127.0.0.1:8000/',
    dateFormat: 'YYYY-MM-DD',
    datetimeFormat: 'YYYY-MM-DD HH:mm',
    defaultLat: 36.323054,
    defaultLng: 59.554985,
    defaultZoom: 16,
    expireToken: 7,
    imagePathBrand: 'https://rokhtejarat.ir/images/brand/',
    imagePathCategory: 'https://rokhtejarat.ir/images/category/',
    imagePathProduct: 'https://rokhtejarat.ir/images/product/',
    imagePathSetting: 'https://rokhtejarat.ir/images/setting/',
    imagePathSlider: 'https://rokhtejarat.ir/images/slider/',
    jDateFormat: 'jYYYY-jMM-jDD',
    jDateTimeFormat: 'jYYYY-jMM-jDD HH:mm',
    pageSize: 100,
    mapZoom: 15,
    maxImageSize: 5242880,
    maxAge: 20,
    minAge: 0,
};

const Patterns = {
    username: /^[A-Za-z0-9]([A-Za-z0-9_]{1,48})[A-Za-z0-9_]$/,
    urlname: /^[A-Za-z]([A-Za-z0-9-]{1,48})[A-Za-z0-9-]$/,
    mobile: /^(09)[0-9]{9}$/,
    email: /^\w+([-.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
    name: /^[A-Za-z\u0600-\u06FF\u200c][A-Za-z\u0600-\u06FF\u200c ]{1,48}[A-Za-z\u0600-\u06FF\u200c]$/,
    rolename: /^[A-Za-z0-9\u0600-\u06FF\u200c][0-9A-Za-z\u0600-\u06FF\u200c ]{1,48}[A-Za-z0-9\u0600-\u06FF\u200c]$/,
    latlong: /^[0-9]{2,3}(\.)[0-9]{6}$/,
    tag: /^[a-zA-Z0-9_\u0600-\u06FF\u200c]{2,20}$/
};

const Permissions = {
    dashboard_view: 'dashboard_view',
    user_list: 'user_list',
    user_store: 'user_store',
    user_option: 'user_option',
    voip_list: 'voip_list',
    cart_list: 'cart_list',
    cart_view: 'cart_view',
    cart_option: 'cart_option',
    special_list: 'special_list',
    special_store: 'special_store',
    special_option: 'special_option',
    category_list: 'category_list',
    category_store: 'category_store',
    category_option: 'category_option',
    product_list: 'product_list',
    product_option: 'product_option',
    postman_list: 'postman_list',
    message_store: 'message_store',
    message_list: 'message_list',
    message_receipt: 'message_receipt',
    setting_list: 'setting_list'
};

export const HandleErorr = (resFromCatch) => {
    let data = resFromCatch['response']['data'];
    let status = resFromCatch['response']['status'];
    if(status === 422) {
        return Object.values(data.errors).forEach(item => message.error(`${item}`, 5000));
    }
};

const Status = {};

const Types = {};

export { Actions, Constants, Patterns, Permissions, Status, Types };
