import React, {Component, Fragment} from 'react';
import {Constants, Permissions} from "../scripts/settings";
import API from "../scripts/api";
import {
    Button,
    Col,
    Dropdown,
    Input,
    message,
    Modal,
    Row,
    Switch,
    Table,
    Tooltip,
    Checkbox,
    Breadcrumb,
    Menu,
    Avatar,
    Form, Select
} from "antd";
import {
    ReadOutlined,
    SearchOutlined,
    MenuOutlined,
    PlusOutlined,
    DeleteOutlined,
    ControlFilled
} from '@ant-design/icons'
import Resources from "../scripts/resources";
import {connect} from "react-redux";
import {CheckPermission} from "../scripts/helpers";
import {SetConsulting, SetRoute} from "../scripts/action";
import AddAgent from "../forms/AddAgentToAdvertise";
import Rules from "../scripts/rules";

class AgentToAdvertiseManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            Filter: '',
            countryList: [],
            neighborhoodList: [],
            cityList: [],
            countrySearchSelected: false,
            citySearchSelected: false,
            neighborhoodSearchSelected: false,
            show_name: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: Constants.pageSize,
                size: 'small'
            },
            selected: {}
        };

        this.formRef = React.createRef();
        this.searchListFormRef = React.createRef();
    }

    fetch = (params = {}) => {
        const { filterLevel, filterSearch } = this.state;

        params.q = filterSearch;
        params.level = filterLevel;

        this.setState({ loading: true });
        console.log("my params", params)

        API.agentToAdvertise.list(params)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        data: result.data,
                        pagination: {
                            ...this.state.pagination, ...{
                                current: result.data.current_page,
                                pageSize: result.data.per_page,
                                total: result.data.total
                            }
                        }
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    componentDidMount() {
        const { onSetRoute, routeName, parentName } = this.props;
        onSetRoute({ openKey: parentName, selectedKey: routeName });
        // this.fetch({country_id: this.state.countrySearchSelected,
        //     city_search_id: this.state.citySearchSelected,
        // })
        // this.getSearchCountries()
        this.fetch()

    }

    HandleCountryChange = (e) => {
        this.setState(
            {countrySearchSelected : e}
        )
        this.getSearchCities(e)
    }
    HandleCityChange = (e) => {
        this.setState(
            {citySearchSelected : e}
        )
        this.getSearchNeighborhoods(e)
    }

    HandleNeighborhoodChange = (e) => {
        this.setState(
            {neighborhoodSearchSelected : e}
        )
    }

    getSearchCountries = (params = {}) => {
        API.countries.list()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        countryList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getSearchCities = (params = {}) => {
        this.setState({ loading: true });
        API.countryCities.list(params)

            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        cityList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    getSearchNeighborhoods = (params = {}) => {
        this.setState({ loading: true });
        API.cityNeighbourhood.list(params)

            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        neighborhoodList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };



    showAddForm = (user = {}) => {
        this.setState({ visibleAddForm: true, selected: user });

        setTimeout(() => {
            this.formRef.current.handleDidMount(user);
        }, 0);
    };

    hideAddForm = () => {
        this.setState({ visibleAddForm: false });

        setTimeout(() => {
            this.formRef.current.handleWillUnmount();
        }, 0);
    };

    handleAdd = (values, i) => {
        console.log("id is: ", values, i)
        this.setState({ submitting: true });
        const action = i ? API.agentToAdvertise.update : API.agentToAdvertise.add;

        if(action === API.agentToAdvertise.update){
            values.id = i
        }

        action(values)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideAddForm();
                this.fetch({country_id: this.state.countrySearchSelected,
                    city_id: this.state.citySearchSelected,
                    neighborhood_id: this.state.neighborhoodSearchSelected,
                })
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };

    handleFilter = (value) => {
        this.setState({ Filter: value });
    }
    handleDelete = (record) => {
        Modal.confirm({
            title: Resources.confirmDelete,
            okText: Resources.yes,
            okType: 'danger',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({loading : true});
                API.agentToAdvertise.del(record.id)
                    .then(res => {
                        message.success(Resources.successful);
                        this.fetch({deal_type_id: this.state.dealTypeSelected,
                            land_type_id: this.state.landTypeSelected,
                            show_name:  this.state.show_name,
                        })
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };

    render() {
        const{ visibleAddForm, submitting, data, countryList, Filter, loading, cityList, neighborhoodList } = this.state;
        const { permissions } = this.props;
        // const [form] = Form;
        const columns = [

            // {
            //     title: Resources.country,
            //     dataIndex: 'country',
            //     className: 'col-center',
            //     width: '120px'
            // },
            // {
            //     title: Resources.city,
            //     dataIndex: 'city',
            //     className: 'col-center',
            //     width: '120px'
            // },
            // {
            //     title: Resources.neighborhood,
            //     dataIndex: 'neighborhood',
            //     className: 'col-center',
            //     width: '120px'
            // },
            // {
            //     title: Resources.dealType,
            //     dataIndex: 'deal_type',
            //     className: 'col-center',
            //     width: '120px'
            // },
            // {
            //     title: Resources.landType,
            //     dataIndex: 'land_type',
            //     className: 'col-center',
            //     width: '120px'
            // },
            {
                title: Resources.agent,
                dataIndex: 'agent',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.phone,
                dataIndex: 'phone',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.action,
                dataIndex: 'id',
                className: 'col-center',
                width: '80px',
                render: (val, record) => (
                    <Dropdown overlay={<Menu>
                        <Menu.Item>
                            <span onClick={() => this.showAddForm(record)}><ReadOutlined /> {Resources.edit}</span>
                        </Menu.Item>
                        <Menu.Item>
                            <span onClick={() => this.handleDelete(record)}><DeleteOutlined /> {Resources.delete}</span>
                        </Menu.Item>



                    </Menu>}>
                        <Button type="link" icon={<MenuOutlined />} />
                    </Dropdown>
                )
            },

        ];

        const NewsData = data.filter(o => {
            let query = false;

            if (Filter) {
                if (o.link && o.link.indexOf(Filter) >= 0)
                    query = true;

                if (o.section && o.section.indexOf(Filter) >= 0)
                    query = true;
            } else {
                query = true;
            }
            return query;
        });
        const onFinish = (values) => {
            this.setState({
                countrySearchSelected: values.country_search_id,
                citySearchSelected: values.city_search_id,
                neighborhoodSearchSelected: values.neighborhood_search_id,
            });
            this.fetch({country_id: this.state.countrySearchSelected,
                city_id: this.state.citySearchSelected,
                neighborhood_id: this.state.neighborhoodSearchSelected,
            })
            console.log('Success:', values);
        };

        return (
            <Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item>{Resources.addAgentToAdvertise}</Breadcrumb.Item>
                </Breadcrumb>
                <Fragment>
                    {/*<Form ref={this.searchListFormRef} layout="vertical"   onFinish={onFinish} >*/}
                        <Row gutter={24}>
                            <Col xs={24} md={4}>
                                <Button type="primary" icon={<PlusOutlined />} onClick={() => this.showAddForm()} block>{Resources.add}</Button>
                            </Col>
                        </Row>

                {/*        <Row gutter={24} style={{marginTop: "3%"}}>*/}
                {/*            /!*<Col xs={24} md={14} style={{ paddingTop: 8 }}>*!/*/}
                {/*            /!*    <Checkbox.Group onChange={value => console.log(value)} />*!/*/}
                {/*            /!*</Col>*!/*/}


                {/*            <Col xs={24} md={6}>*/}
                {/*                <Form.Item name="country_search_id" label={Resources.country}  >*/}
                {/*                    <Select onChange={(e) => this.HandleCountryChange(e)} allowClear={true}>*/}
                {/*                        {countryList.map(item => (*/}
                {/*                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>*/}
                {/*                        ))}*/}
                {/*                    </Select>*/}
                {/*                </Form.Item>*/}
                {/*            </Col>*/}
                {/*            <Col xs={24} md={6}>*/}
                {/*                <Form.Item name="city_search_id" label={Resources.city}  >*/}
                {/*                    <Select onChange={(e) => this.HandleCityChange(e)} allowClear={true}>*/}
                {/*                        {cityList.map(item => (*/}
                {/*                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>*/}
                {/*                        ))}*/}
                {/*                    </Select>*/}
                {/*                </Form.Item>*/}
                {/*            </Col>*/}
                {/*            <Col xs={24} md={6}>*/}
                {/*                <Form.Item name="neighborhood_search_id" label={Resources.neighborhood}  >*/}
                {/*                    <Select onChange={(e) => this.HandleNeighborhoodChange(e)} allowClear={true}>*/}
                {/*                        {neighborhoodList.map(item => (*/}
                {/*                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>*/}
                {/*                        ))}*/}
                {/*                    </Select>*/}
                {/*                </Form.Item>*/}
                {/*            </Col>*/}

                {/*            <Col xs={24} md={4} style={{marginTop: "3%"}}>*/}

                {/*                <Button type="primary" htmlType="submit">{Resources.search}</Button>*/}
                {/*            </Col>*/}

                {/*        </Row>*/}
                {/*    </Form>*/}
                </Fragment>
                <div className="ant-content">
                    <Table
                        columns={columns}
                        dataSource={NewsData}
                        loading={loading}
                        // onChange={this.handleTableChange}
                        // pagination={pagination}
                        size="small"
                        rowKey="id"
                        // scroll={{ x: 900 }}
                    />
                    {/*{!!pagination.total && <PaginationInfo current={pagination.current} pageSize={pagination.pageSize} total={pagination.total} />}*/}
                </div>
                <AddAgent ref={this.formRef} onCancel={this.hideAddForm} onSubmit={this.handleAdd} submitting={submitting} visible={visibleAddForm} />
            </Fragment>
        );
    }
}

export default connect(
    (state) => {
        return {
            permissions: {
                action: CheckPermission(state.app.user, Permissions.user_option),
                add: CheckPermission(state.app.user, Permissions.user_store)
            }
        };
    },
    (dispatch) => {
        return {
            onSetRoute: (data) => dispatch(SetRoute(data)),
            setConsulting: data => dispatch(SetConsulting(data))
        };
    }
)(AgentToAdvertiseManagement);