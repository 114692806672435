import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {CheckPermission} from "../scripts/helpers";
import {Constants, Permissions} from "../scripts/settings";
import {SetConsulting, SetRoute} from "../scripts/action";
import API from "../scripts/api";
import {
    Button,
    Col,
    Dropdown,
    Input,
    message,
    Row,
    Table,
    Tooltip,
    Checkbox,
    Breadcrumb,
    Menu,
    Avatar,
    Modal
} from "antd";
import {ReadOutlined, SearchOutlined, MenuOutlined, PlusOutlined, DeleteOutlined} from '@ant-design/icons'
import Resources from "../scripts/resources";
import AdverOfferFieldLogo from "../forms/AdverOfferFieldLogo";


class FormBuilderAdverOfferFieldLogo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            Filter: '',
            pagination: {
                total: 0,
                current: 1,
                pageSize: Constants.pageSize,
                size: 'small'
            },
            selected: {}
        };

        this.formRef = React.createRef();
    }

    fetch = (params = {}) => {
        const { filterLevel, filterSearch } = this.state;

        params.q = filterSearch;
        params.level = filterLevel;

        this.setState({ loading: true });

        API.formBuilderAdverOffer.distinct_list()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        data: result.data,
                        pagination: {
                            ...this.state.pagination, ...{
                                current: result.data.current_page,
                                pageSize: result.data.per_page,
                                total: result.data.total
                            }
                        }
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    componentDidMount() {
        const { onSetRoute, routeName, parentName } = this.props;
        onSetRoute({ openKey: parentName, selectedKey: routeName });
        this.fetch({ page: 1 });

    }

    handleSubmit = (values, i) => {
        this.setState({ submitting: true });
        // const action = i ? API.formBuilderAdverOffer.addLogo : API.formBuilderAdverOffer.addLogo;
        //
        // if(action === API.formBuilderAdverOffer.addLogo){
        //     values.append("name", i)
        // }

        const action = this.state.selected.id ? API.formBuilderAdverOffer.addLogo : API.formBuilderAdverOffer.addLogo;
        if (action === API.formBuilderAdverOffer.addLogo) {
            // values.id = this.state.selected.name;
            values.append("name", this.state.selected.name)
        }

        action(values)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideAddForm();
                this.fetch({ page: this.state.pagination.current });
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };

    showAddForm = (user = {}) => {
        this.setState({ visibleAddForm: true, selected: user });

        setTimeout(() => {
            this.formRef.current.handleDidMount(user);
        }, 0);
    };

    hideAddForm = () => {
        this.setState({ visibleAddForm: false });

        setTimeout(() => {
            this.formRef.current.handleWillUnmount();
        }, 0);
    };

    handleDelete = (record) => {
        Modal.confirm({
            title: Resources.confirmDelete,
            okText: Resources.yes,
            okType: 'danger',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({loading : true});
                API.socialNetwork.del(record.id)
                    .then(res => {
                        message.success(Resources.successful);
                        this.fetch()
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };

    handleFilter = (value) => {
        this.setState({ Filter: value });
    }


    render() {
        const{ visibleAddForm, submitting, data, podcastCat, Filter, loading } = this.state;
        const { permissions } = this.props;

        const columns = [
            {
                title: Resources.title,
                dataIndex: 'show_label',
                className: 'col-center',
                width: '120px'
            },{
                title: Resources.image,
                dataIndex: 'logo',
                className: 'col-center',
                width: '80px',
                render: val => <Tooltip title={<Avatar src={`${Constants.imagePath}${val}`} shape="square" size={200} />}><Avatar src={`${Constants.imagePath}${val}`} shape="square" size="small" /></Tooltip>
            }, {
                title: Resources.action,
                dataIndex: 'id',
                className: 'col-center',
                width: '80px',
                render: (val, record) => (
                    <Dropdown overlay={<Menu>
                        <Menu.Item>
                            <span onClick={() => this.showAddForm(record)}><ReadOutlined /> {Resources.edit}</span>
                        </Menu.Item>

                    </Menu>}>
                        <Button type="link" icon={<MenuOutlined />} />
                    </Dropdown>
                )
            }
        ];

        const SupportData = data.filter(o => {
            let query = false;

            if (Filter) {
                if (o.show_label && o.show_label.indexOf(Filter) >= 0)
                    query = true;
            } else {
                query = true;
            }
            return query;
        });

        return (
            <Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item>{Resources.AdverOfferFieldLogo}</Breadcrumb.Item>
                </Breadcrumb>
                <div className='ant-content'>
                    <Row gutter={24}>
                        {/*<Col xs={24} md={4}>*/}
                        {/*    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.showAddForm()} block>{Resources.add}</Button>*/}
                        {/*</Col>*/}
                        <Col xs={24} md={14} style={{ paddingTop: 8 }}>
                            <Checkbox.Group onChange={value => console.log(value)} />
                        </Col>
                        <Col xs={24} md={6}>
                            <Input
                                type="text"
                                prefix={<SearchOutlined />}
                                placeholder={Resources.search}
                                onChange={({ target: { value } }) => this.handleFilter(value)}
                                allowClear
                            />
                        </Col>
                    </Row>
                </div>
                <div className="ant-content">
                    <Table
                        columns={columns}
                        dataSource={SupportData}
                        loading={loading}
                        // onChange={this.handleTableChange}
                        // pagination={pagination}
                        size="small"
                        rowKey="id"
                        // scroll={{ x: 900 }}
                    />
                    {/*{!!pagination.total && <PaginationInfo current={pagination.current} pageSize={pagination.pageSize} total={pagination.total} />}*/}
                </div>
                <AdverOfferFieldLogo ref={this.formRef} onCancel={this.hideAddForm} onSubmit={this.handleSubmit} submitting={submitting} visible={visibleAddForm} />
            </Fragment>
        );
    }
}

export default connect(
    (state) => {
        return {
            permissions: {
                action: CheckPermission(state.app.user, Permissions.user_option),
                add: CheckPermission(state.app.user, Permissions.user_store)
            }
        };
    },
    (dispatch) => {
        return {
            onSetRoute: (data) => dispatch(SetRoute(data)),
            setConsulting: data => dispatch(SetConsulting(data))
        };
    }
)(FormBuilderAdverOfferFieldLogo);