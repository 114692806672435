import React, {Component, Fragment} from 'react';
import {cloneDeep} from "lodash";
import {Button, Col, Input, Modal, Row, Upload, Form, message, Select, Radio, Drawer} from "antd";
import {ExceptionOutlined, MailOutlined, LayoutOutlined, LoadingOutlined, UploadOutlined} from '@ant-design/icons';
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import API from '../scripts/api'
import { EditorState, convertToRaw, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {stateToHTML} from "draft-js-export-html";
import JoditEditor from "jodit-react";

class Article extends Component {

    constructor(props) {
        super(props);
        this.state = {
            picList: [],
            type: "editor",
            Data: '',
            showDrawer: true,
            DrawerData: "",
            editorState: EditorState.createEmpty(),
            editorStateShortDesc: EditorState.createEmpty(),
        }
        this.formRef = React.createRef();
    }

    handleSubmit = (data = {}) => {
        const {picList} = this.state;
        const formData = new FormData();
        picList.map((item, i) => {
            formData.append(`images[]`, item)
        });
        formData.append('type', data.type);
        formData.append('title', data.title);
        formData.append('read_time', data.read_time);
        formData.append('writer', data.writer);
        // formData.append('description', stateToHTML(this.state.editorState.getCurrentContent()));
        if(this.myContent !== null){
            formData.append('description', this.myContent);
        }else{
            formData.append('description', this.state.DrawerData);
        }
        
        formData.append('short_desc', data.short_desc);
        formData.append('raw_description', data.raw_description);


        this.props.onSubmit(formData);
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();

        this.setState({
            addresses: [{id: 0}],
            phones: []
        });
        window.location.reload()
    };


    handleDidMount = async (data = {}) => {
        await this.setState({editorState : "", DrawerData:""})
        const cloneData = await cloneDeep(data);
        await this.formRef.current.setFieldsValue(cloneData);
        await this.setState({editMode: !!cloneData.id, picList: []});
        if(this.state.editMode){
            await this.setState({type: cloneData.type});
            if(cloneData.description){
                await this.setState({DrawerData: cloneData.description})
                // this.setState({ editorState: EditorState.createWithContent(
                //         ContentState.createFromBlockArray(
                //             convertFromHTML(cloneData.description)
                //         )
                //     )});
            }


            // this.setState({ editorStateShortDesc: EditorState.createWithContent(
            //         ContentState.createFromBlockArray(
            //             convertFromHTML(cloneData.short_desc)
            //         )
            //     )});
        }

    }

    onCloseDrawer = () => {
        this.setState({
            showDrawer: false,
            DrawerData: '',
            picList : []
        })
    }

    changeDrawer = (data) => {
        console.log("data is: ", data)
        this.setState({DrawerData: data})
        document.getElementById("jodiEditorId").focus()

    }

    myContent = null;

    render() {
        const {submitting, visible} = this.props;
        const {picList, editMode, editorState, editorStateShortDesc, type} = this.state;
        const {TextArea} = Input;
        console.log("piclist is: ", picList)

        const picProps = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.picList.indexOf(file);
                    const newFileList = state.picList.slice();
                    newFileList.splice(index, 1);
                    return {picList: newFileList};
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    picList: [...state.picList, file],
                }));
                return false;
            },
            showUploadList: {
                showRemoveIcon: true
            },

            picList
        };
        console.log("picProps is: ", picProps)
        console.log("picList is: ", picList)
        const config = {
            readonly: false,
            defaultLineHeight: 2,
            uploader: {
                insertImageAsBase64URI: true,
                video: true,
            }
        }
        return (
            <Fragment>
                <Modal
                    width='80%'
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onCancel={this.props.onCancel}
                    footer={null}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} onFinish={(value) => this.handleSubmit(value)} layout="vertical">
                        <Row gutter={24} style={{marginBottom: '15px'}}>
                            <Col xs={24} md={12}>
                                <Form.Item name="title" label={Resources.title} rules={Rules.required}>
                                    <Input disabled={this.props.submitting} type="text" prefix={<ExceptionOutlined/>}
                                           placeholder={Resources.title}/>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="read_time" label={Resources.read_time} rules={Rules.required}>
                                    <Input disabled={this.props.submitting} type="text" prefix={<ExceptionOutlined/>}
                                           placeholder={Resources.read_time}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="type" label={Resources.type} rules={Rules.required} initialValue= {type}>
                                    <Radio.Group>
                                        <Radio value="editor">{Resources.editor}</Radio>
                                        <Radio value="raw">{Resources.raw}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="writer" label={Resources.writer} rules={Rules.required}>
                                    <Input disabled={this.props.submitting} type="text" prefix={<ExceptionOutlined/>}
                                           placeholder={Resources.writer}/>
                                </Form.Item>
                            </Col>



                            <Col xs={24} md={24}>
                                <Form.Item name="short_desc" label={Resources.short_desc} rules={Rules.required}>
                                    <TextArea disabled={this.props.submitting} type="text" prefix={<ExceptionOutlined/>}
                                           placeholder={Resources.short_desc}/>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={24}>
                                <Form.Item name="raw_description" label={Resources.raw_description} >
                                    <TextArea disabled={this.props.submitting} type="text" prefix={<ExceptionOutlined/>}
                                              placeholder={Resources.raw_description}/>
                                </Form.Item>
                            </Col>

                            {/*<Col xs={24} md={24}>*/}
                            {/*    <span> {Resources.description}</span>*/}
                            {/*    <Editor*/}
                            {/*        editorState={editorState}*/}
                            {/*        toolbarClassName="toolbarClassName"*/}
                            {/*        wrapperClassName="wrapperClassName"*/}
                            {/*        editorClassName="editorClassName"*/}
                            {/*        onEditorStateChange={this.onEditorStateChange}*/}
                            {/*    />;*/}
                            {/*</Col>*/}

                            <Col xs={24} md={24}>
                                    <JoditEditor
                                        ref={this.formRef} // tabIndex of textarea
                                        tabIndex={1} // tabIndex of textarea
                                        value={this.state.DrawerData}
                                        config={config}
                                        // onChange={this.changeDrawer}
                                        onBlur={(newContent) => {
                                            // this.setState({DrawerData: newContent})
                                            this.myContent = newContent;
                                        }}
                                    />
                            </Col>




                            <Col xs={12} md={12}>
                                <Upload beforeUpload={()=> {
                                    /* update state here */
                                    return false; }}  disabled={this.props.submitting} {...picProps} className="ant-upload-block">
                                    <Button block icon={
                                        <UploadOutlined/>}>{`${Resources.add} ${Resources.image}`}</Button>
                                </Upload>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button loading={this.props.submitting} style={{float: 'left'}} type="primary"
                                    htmlType="submit">
                                {Resources.confirm}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default Article;
