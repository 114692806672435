import React, {Component, Fragment} from 'react';
import {Button, Col, Input, Modal, Row, Upload, Form, Avatar, Image} from "antd";
import Resources from "../scripts/resources";
import {Constants} from "../scripts/settings";
import {cloneDeep} from "lodash";
import axios from "axios";
import API from "../scripts/api";

class LegalDevices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vidList: [],
            picList: [],
            Data: '',
        }
        // this.formRef = this.MediaRef;
    }

    handleWillUnmount = (data = {}) => {
        // this.formRef.current.resetFields();

    };

    handleDidMount = (data = {}) => {
        console.log("data is: ", data)
        this.setState({
            Data: data.userAgents
        })
    }

    HandleDelete = (item) => {
        this.props.onDelete(item)

    }


    render() {
        const { submitting, visible} = this.props;
        const {Data} = this.state;

        return (
            <Fragment>
                <Modal
                    title={`${Resources.bankInfo}`}
                    visible={visible} onOk={this.handleSubmit}
                    onCancel={this.props.onCancel}
                    footer={null}
                >
                    {
                        Data ?
                        <div>
                            {
                                Data.map(item =>
                                    <Row key={item.id} gutter={24} style={{marginBottom: '15px'}}>
                                        < Col xs={24} md={12}>
                                            <span>{item.user_agent}</span>
                                        </Col>
                                        < Col xs={24} md={6}>
                                            <span>{item.date}</span>
                                        </Col>
                                        <Col xs={6} style={{display: 'flex',alignItems: 'center'}}>
                                            <Button loading={submitting} type='danger' onClick={()=>this.HandleDelete(item)}>
                                                {Resources.delete}
                                            </Button>
                                        </Col>
                                    </Row>
                                    )
                            }
                        </div> : <div>
                                <h5>{Resources.notfound}</h5>
                            </div>
                    }
                </Modal>
            </Fragment>
        );
    }
}

export default LegalDevices;