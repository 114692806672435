import {createSelector} from 'reselect'


const selectUser = state => state.app;


export const selectUserr = createSelector(
    [selectUser],
    app => app.user
);

export const selectUserRules = createSelector(
    [selectUserr],
    user => user.rules
);

