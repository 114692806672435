import React, {Component, Fragment} from 'react';
import {cloneDeep} from "lodash";
import {Col, Modal, Row, Input, Form, Upload} from "antd";
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import {ProjectOutlined, InboxOutlined, DeploymentUnitOutlined, ExceptionOutlined} from '@ant-design/icons';

class RejectAdvertice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileList: [],
            title: '',
            userRoles: '',
            selectedRoles: null,
            addresses: [{id: 0}],
            phones: [],
            selectedAddress: 0,
            visibleMap: false,
            id: ''
        };

        this.formRef = React.createRef();
        this.mapRef = React.createRef();
    }


    handleSubmit = () => {
        this.formRef.current.validateFields()
            .then(values => {
                const formData = new FormData;
                formData.append('description', values.reject_reason);
                // formData.append('address', values.address);
                // if (values.logo && values.logo.fileList && values.logo.fileList.length)
                //     formData.append('logo', values.logo.file, values.logo.file.name);
                if (this.state.editMode){
                    formData.append("id", this.state.id);
                    this.props.onSubmit(formData, this.state.id);
                } else {
                    this.props.onSubmit(formData);
                }
            });
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();
    };

    handleDidMount = (data = {}) => {
        const cloneData = cloneDeep(data);
        this.formRef.current.setFieldsValue(cloneData);
        console.log(!!cloneData._id);
        this.setState({editMode: !!cloneData._id, id: cloneData._id});
    }


    render() {
        const {onCancel, submitting, visible} = this.props;
        const {addresses, editMode, phones, visibleMap, fileList} = this.state;

        const {TextArea} = Input;
        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return { fileList: newFileList };
                });
            },
            beforeUpload: file => {
                this.setState({ fileList: [file], fileData: file });
                return false;
            },
            // fileList
        };

        return (
            <Fragment>
                <Modal
                    title={Resources.rejectAdd}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} layout="vertical">
                        <Row gutter={24}>

                            <Col xs={24} md={24}>
                                <Form.Item name="reject_reason" label={Resources.description} rules={Rules.required}>
                                    <TextArea disabled={this.props.submitting} type="text" prefix={<ExceptionOutlined/>}
                                              placeholder={Resources.description}/>
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default RejectAdvertice;
