import React, {Component} from 'react';
import {cloneDeep} from 'lodash';
import {Checkbox, Form, List, Modal, Button, Row, Col, Switch} from 'antd';
import Resources from '../scripts/resources';
import {CheckPermission, HadleAclCheckbox, handleAclData} from "../scripts/helpers";
import API from "../scripts/api";
import {createStructuredSelector} from "reselect";
import connect from "react-redux/es/connect/connect";
import {Permissions} from "../scripts/settings";
import {SetRoute} from "../scripts/action";
import {selectUserRules} from "../scripts/user.selector";

class PermissionForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allPermission: '',
            userPermission: ''
        };

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const {rules, visible, selected} = this.props;
        if (visible && !this.state.userPermission) {
            API.acl.user(selected.id)
                .then(res => {
                    let arr = [];
                    for (const [key, value] of Object.entries(res)) {
                        value.map(item => arr.push(item.id))
                    }
                    this.setState({userPermission: {rules: arr}})
                })
        } else if (!visible && this.state.userPermission) {
            this.setState({userPermission: ''})
        }
    }

    render() {
        const {onCancel, submitting, visible} = this.props;
        const {permisionData} = this.props;

        return (
            <Modal
                title={Resources.edit}
                visible={visible}
                onOk={this.handleSubmit}
                footer={null}
                onCancel={onCancel}
                okText={Resources.confirm}
                cancelText={Resources.cancel}
                okButtonProps={{loading: submitting}}
                cancelButtonProps={{disabled: submitting}}
                width={700}>
                {this.state.userPermission &&
                <Form onFinish={(value) => this.props.onSubmit(value.rules)} layout="vertical"
                      initialValues={this.state.userPermission}
                      onFieldsChange={(_,allFields)=>{
                          console.log(allFields)
                          console.log(_)
                      }}
                >
                    <Form.Item name="rules">
                        <Checkbox.Group  className="ant-checkbox-group-ltr" style={{display: 'block'}}>
                            <List>
                                {
                                    permisionData && handleAclData(permisionData).map((item, i) =>
                                        <List.Item key={i}>
                                            <List.Item.Meta title={item}/>
                                            <div className="ant-list-item-meta-description">
                                                {
                                                    HadleAclCheckbox(item, permisionData).map((x, i) =>
                                                        <Checkbox
                                                            key={i}
                                                            value={x.id}
                                                            name={x.id}
                                                        >
                                                            {x.name}
                                                        </Checkbox>
                                                    )
                                                }
                                            </div>
                                        </List.Item>
                                    )
                                }
                            </List>
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item>
                        <Button style={{float: 'left'}} type="primary" htmlType="submit">
                            {Resources.confirm}
                        </Button>
                    </Form.Item>
                </Form>
                }
            </Modal>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    rules: selectUserRules
});
export default connect(mapStateToProps)(PermissionForm);
