import React, {Component, Fragment} from 'react';
import {Button, Col, Input, Modal, Row, Upload, Form, Avatar, Image} from "antd";
import Resources from "../scripts/resources";
import {Constants} from "../scripts/settings";
import {cloneDeep} from "lodash";
import Rules from "../scripts/rules";
import {ProjectOutlined} from "@ant-design/icons";

class AdvertiseFacility extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vidList: [],
            picList: [],
            Data: '',
        }
        // this.formRef = this.MediaRef;
    }

    handleWillUnmount = (data = {}) => {
        // this.formRef.current.resetFields();

    };

    handleDidMount = (data = {}) => {
        console.log("imagesss", data.images);
        this.setState({
            Data: data.facility
        })
    }

    HandleDelete = (img) => {
        this.props.onDelete(img.id);
    }


    render() {
        const { submitting, visible} = this.props;
        const {Data} = this.state;

        return (
            <Fragment>
                <Modal
                    title={`${Resources.bankInfo}`}
                    visible={visible} onOk={this.handleSubmit}
                    onCancel={this.props.onCancel}
                    footer={null}
                >
                    {
                        Data ?
                        <div>
                            {
                                Data.map(facility =>
                                    <Row key={facility.id} gutter={24} style={{marginBottom: '15px'}}>

                                        {/*<Col xs={6} style={{display: 'flex',alignItems: 'center'}}>*/}
                                        {/*    <Button loading={submitting} type='danger' onClick={()=>this.HandleDelete(img)}>*/}
                                        {/*        {Resources.delete}*/}
                                        {/*    </Button>*/}
                                        {/*</Col>*/}
                                        <Col xs={24} md={12}>
                                            {/*<Form.Item name="name" label={Re.name} rules={Rules.required}>*/}
                                                <Input type="text"  value={facility.name}/>
                                            {/*</Form.Item>*/}
                                            {/*<Form.Item name="address" label={Resources.address}>*/}
                                            {/*    <Input placeholder={Resources.address} prefix={<DeploymentUnitOutlined />}/>*/}
                                            {/*</Form.Item>*/}
                                        </Col>
                                    </Row>
                                    )
                            }
                        </div> : <div>
                                <h5>{Resources.notfound}</h5>
                            </div>
                    }
                </Modal>
            </Fragment>
        );
    }
}

export default AdvertiseFacility;