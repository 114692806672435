import React, {Component, Fragment} from 'react';
import {cloneDeep} from "lodash";
import {Col, Modal, Row, Input, Form, Upload, Select, Radio} from "antd";
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import {ProjectOutlined, InboxOutlined, DeploymentUnitOutlined} from '@ant-design/icons';
import API from "../scripts/api";

class AddFormBuilderIsOwner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileList: [],
            dealTypesList: [],
            landTypeList: [],
            inputTypeList: [],
            fieldTypeList: [],
            visibleMap: false,
            isOwner: false,
            dealTypeSelected: false,
            landTypeSelected: false,
            inputTypeSelected: false,
            fieldTypeSelected: false,
            is_owner: "",
            id: '',
            apiFormElements: [],
            ownerFormElements: [],
            formValues: [] // STATE HATO INJA BAYAD BEZARI
        };

        this.formRef = React.createRef();
        this.formRefIsOwner = React.createRef();
        this.mapRef = React.createRef();

    }

    ReturnFieldsForm = (fieldType) => {

        let InpuParams = {
            isOwner : this.state.is_owner,
            fieldType : fieldType,
        }
        API.ReturnFieldsFormIsOwner.list(InpuParams)
            .then((result) => {
                console.log(result)
                if (result) {

                    this.setState({
                        loading: false,
                        apiFormElements: result,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    handleSubmit = () => {
        this.formRefIsOwner.current.validateFields()
            .then(values => {
                this.props.onSubmit(values, this.state.id);
            });
    };

    handleWillUnmount = () => {
        this.formRefIsOwner.current.resetFields();
    };
    getFieldType = (params = {}) => {
        API.fieldType.list()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        fieldTypeList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    handleDidMount = (data = {}) => {
        const cloneData = cloneDeep(data);
        this.formRefIsOwner.current.setFieldsValue(cloneData);
        console.log(!!cloneData.id);

        this.setState({editMode: !!cloneData.id, id: cloneData.id});
        if(this.state.editMode){
            console.log("cloneData", cloneData)
            this.setState({id: cloneData.id});
            this.setState({isOwner: cloneData.is_owner});
            this.setState({fieldTypeSelected: cloneData.field_type});
        }
        this.getFieldType()
        if(this.state.editMode){
            this.ReturnFieldsForm(this.state.fieldTypeSelected)
        }

    }

    HandleSelect = (e) => {

        this.ReturnFieldsForm(e)
    }

    HandleIsOwnerChange = (e) => {
        this.setState( {
            is_owner : e.target.value,
        })
    }


    render() {
        const {onCancel, submitting, visible} = this.props;
        const { editMode, apiFormElements, fieldTypeList, fieldTypeSelected} = this.state; // INJA STATE HATO MIYARI TO RENDER INJA FARAKHONI MISHE AZ BALA



        return (

            <Fragment>
                <Modal
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRefIsOwner} layout="vertical" >
                        <Row gutter={24} >


                            <Col xs={24} md={8} >
                                <Form.Item initialValue= "false" name="is_owner" label={Resources.isOwner} rules={Rules.required} >
                                    <Radio.Group onChange={(e) => this.HandleIsOwnerChange(e)}>
                                        <Radio value="true">{Resources.yes}</Radio>
                                        <Radio value="false">{Resources.no}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>


                            <Col xs={24} md={8}>
                                <Form.Item  name="field_type" label={Resources.fieldType}  initialValue= {fieldTypeSelected}>
                                    <Select onChange={(e) => this.HandleSelect(e)} >
                                        {fieldTypeList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            {
                                apiFormElements.map(item =>


                                          <Col xs={24} md={8}>

                                              {  ((item.type == "input") && (item.required)) ?
                                                  <Form.Item name={item.name} label={item.showName}  rules={Rules.required}>
                                                      <Input type={item.fieldType} placeholder={item.showName}  />
                                                  </Form.Item> :
                                                  ((item.type == "input") && (!item.required)) ?
                                                      <Form.Item name={item.name} label={item.showName}  >
                                                          <Input type={item.fieldType} placeholder={item.showName}  />
                                                      </Form.Item>
                                                      :item.type == "select" ?
                                                      <Form.Item name={item.name} label={item.showName} >
                                                          <Select>
                                                              {item.data.map(item => (
                                                                  <Select.Option key={item._id}  value={item._id}>{item.name}</Select.Option>
                                                              ))}
                                                          </Select>
                                                      </Form.Item>
                                                      :
                                                          <Form.Item name={item.name} label={item.showName} rules={Rules.required} >
                                                              <Radio.Group>
                                                                  <Radio value="yes">{Resources.yes}</Radio>
                                                                  <Radio value="no" >{Resources.no}</Radio>
                                                              </Radio.Group>
                                                          </Form.Item>
                                              }
                                              </Col>
                                )

                            }
                        </Row>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default AddFormBuilderIsOwner;
