import React, {Component, Fragment} from 'react';
import {
    Breadcrumb,
    Button,
    Col,
    Input,
    Row,
    Table,
    Tooltip,
    Checkbox,
    message,
    Dropdown,
    Menu,
    Switch,
    Modal,
    Pagination, Avatar,
} from "antd";
import { NavLink } from "react-router-dom";
import Resources from "../scripts/resources";
import {
    PlusOutlined, SearchOutlined, EyeOutlined, DingtalkOutlined, FormOutlined, FileSearchOutlined, DeleteOutlined
    , EditOutlined, DollarOutlined, LockOutlined, MenuOutlined, ReadOutlined
} from '@ant-design/icons'
import {Constants, Permissions} from "../scripts/settings";
import { debounce } from 'lodash';
import AddState from "../forms/AddState";
import API from "../scripts/api";
import {connect} from "react-redux";
import {CheckPermission, handleAclData, PriceFormat} from "../scripts/helpers";
import {SetRoute} from "../scripts/action";
import PermissionForm from "../forms/Permission";
import {Link} from "react-router-dom";

class ChatManagement extends Component {
    constructor() {
        super();

        this.state = {
            country: '',
            data: [],
            aclData: '',
            filter: [],
            Filter: '',
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: Constants.pageSize,
                size: 'small',
            },
            visibleDeleteForm: false,
            selected: {},
            mobile: '',
            search:[]
        };

        this.formRef = React.createRef();

        // this.handleFilter = debounce(this.handleFilter, 1000);
    }

    fetch = (params = {}) => {
        const { filterLevel, filterSearch } = this.state;


        this.setState({ loading: true });
        API.chat.index(params)
            .then((result) => {

                    this.setState({
                        loading: false,
                        mobile: result.mobile,
                    });


            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    componentDidMount() {

        const { onSetRoute, routeName, parentName } = this.props;
        onSetRoute({ openKey: parentName, selectedKey: routeName });
        console.log("this.props.history", this.props.history.location.state)
        const country = this.props.history.location.state;
        this.setState({
            country: country
        })
        this.fetch({ page: 1 });

    }





    handleFilter = (value) => {
        this.setState({ Filter: value });
    }
    render() {
        const{ visibleForm, submitting, data, loading, pagination, visibleDeleteForm ,mobile} = this.state;
        const { permissions } = this.props;

        // const columns = [{
        //     title: Resources.title,
        //     dataIndex: 'name',
        //     className: 'col-center',
        //     width: '120px'
        // }
        // ];


        return (
            <Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item>{Resources.chatManagement}</Breadcrumb.Item>
                </Breadcrumb>
                <iframe src={`${Constants.chatUrl}?mobile=${mobile}`} height="500" width="100%"/>,
                {/*<div className='ant-content'>*/}
                {/*    <Row gutter={24}>*/}
                {/*        <Col xs={24} md={4}>*/}
                {/*            <Button type="primary" icon={<PlusOutlined />} onClick={() => this.showForm()} block>{Resources.add}</Button>*/}
                {/*        </Col>*/}
                {/*        <Col xs={24} md={14} style={{ paddingTop: 8 }}>*/}
                {/*            <Checkbox.Group onChange={value => console.log(value)} />*/}
                {/*        </Col>*/}
                {/*        <Col xs={24} md={6}>*/}
                {/*            <Tooltip title={Resources.steftaatSearch}>*/}
                {/*                <Input*/}
                {/*                    type="text"*/}
                {/*                    prefix={<SearchOutlined />}*/}
                {/*                    placeholder={Resources.search}*/}
                {/*                    onChange={({ target: { value } }) => this.handleFilter(value, 'filterSearch')}*/}
                {/*                    allowClear*/}
                {/*                />*/}
                {/*            </Tooltip>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</div>*/}
                {/*<div className="ant-content">*/}
                {/*    <Table*/}
                {/*        columns={columns}*/}
                {/*        dataSource={SupportData}*/}
                {/*        loading={loading}*/}
                {/*        onChange={this.handleTableChange}*/}
                {/*        pagination={pagination}*/}
                {/*        size="small"*/}
                {/*        rowKey="id"*/}
                {/*        // scroll={{ x: 900 }}*/}
                {/*    />*/}
                {/*    /!*{!!data.length && <Pagination onChange={this.handleChange1} current={this.state.current} pageSize={this.state.pagination.pageSize} total={data.length} />}*!/*/}
                {/*</div>*/}
                {/*<PermissionForm selected={this.state.selected} permisionData={aclData} onCancel={this.hidePermission} onSubmit={this.handlePermissions} submitting={submitting} visible={visiblePermission} />*/}

                {/*<AddState ref={this.formRef} onCancel={this.hideForm} onSubmit={this.handleSubmit} submitting={submitting} visible={visibleForm} />*/}
                {/*<DeleteImageBook ref={this.mediaRef} data={this.props.history.location.state} ifUpdate={this.state.selected.id} onCancel={this.hideDeleteForm} onSubmit={this.handleDeleteSubmit} onDelete={this.handleDeleteSubmit} visible={visibleDeleteForm} />*/}

            </Fragment>
        );
    }
}

export default connect(
    (state) => {
        return {
            permissions: {
                action: CheckPermission(state.app.user, Permissions.user_option),
                add: CheckPermission(state.app.user, Permissions.user_store),
            }
        };
    },
    (dispatch) => {
        return {
            onSetRoute: (data) => dispatch(SetRoute(data))
        };
    }
)(ChatManagement);
