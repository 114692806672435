import React, {Component, Fragment} from 'react';
import {Cookies} from "react-cookie";
import {Button, Form, Input, message} from 'antd';
import {MobileOutlined, KeyOutlined} from '@ant-design/icons';
import { NumberOutlined } from '@ant-design/icons';
// import {Messaging} from '../scripts/messaging';
import API from '../scripts/api';
import Resources from '../scripts/resources';
import Rules from '../scripts/rules';

class LoginPage extends Component {
    constructor() {
        super();

        this.state = {
            submitting: false,
            step: 1,
            mobile: ''
        };

        this.cookies = new Cookies();
    }

    handleToken = () => {
        return true;
        // return new Promise((resolve) => {
        //     Messaging.getToken()
        //         .then((token) => {
        //             resolve(token || '');
        //         })
        //         .catch((err) => {
        //             resolve('');
        //         });
        // });
    };

    handleLogin = values => {

        //
        // const data = {
        //     mobile
        // }

        this.setState({submitting: true});

        // this.handleToken().then((token) => {
        //     values.fcmToken = token;

            API.user.login(values)
                .then((result) => {
                    if (result.status) {
                        message.success(result.message);
                        this.setState({ mobile: values.mobile , step: 2, submitting: false });
                    } else {
                        message.error(result.message);
                        this.setState({ submitting: false });
                    }
                })
                .catch(() => {
                    this.setState({ submitting: false });
                });
        // })
    };

    handleToken = () => {
        return true;
        // return new Promise((resolve) => {
        //     Messaging.getToken()
        //         .then((token) => {
        //             resolve(token || '');
        //         })
        //         .catch((err) => {
        //             resolve('');
        //         });
        // });
    }

    handleVerify = (values) => {
        this.setState({ submitting: true });

        // this.handleToken().then((token) => {
            values.fcmToken = "";

            const data = {
                "scope": "*",
                "grant_type": "password",
                // "client_id": "6311aab49e58000002007d82", //local
                "client_id": "652d504e8e7100008d0004f2",///server
                // "client_secret": "AjIRfEQVfcYGAzAFtpVEorErY8sh2hTzYXW8f2Mk",  //local
                "client_secret": "rB1MuZ1HUkv4fPduYRYqzTmVtpG76vZumBZNgZqS", ///server
                "username": `${this.state.mobile}`,
                "password": `${values.password}`
            };

            API.user.verify(data)
                .then((result) => {
                    if (result.access_token) {
                        const date = new Date();
                        date.setSeconds(date.getSeconds() + result.expires_in);
                        this.cookies.set('AccessToken', result.access_token, { path: '/', expires: date, domain: window.location.hostname });
                        window.location = '/';
                    } else {
                        message.error(result.message);
                        this.setState({ submitting: false });
                    }
                })
                .catch(() => {
                    this.setState({ submitting: false });
                });
        // });
    }

    render() {
        const {formValue, mobile, step, submitting } = this.state;

        return (
            <Fragment>
                <img width={160} src="/images/logo-big.png" alt="shenasa_melk"/>
                <h1>{Resources.login}</h1>
                {/*<Form initialValues={formValue} onFinish={this.handleLogin}>*/}
                    {step === 1 && <Form initialValues={{ mobile: mobile }} onFinish={this.handleLogin}>
                        <Form.Item name="mobile" rules={Rules.mobile} >
                            <Input
                                type="text"
                                size="large"
                                dir="ltr"
                                suffix={<MobileOutlined />}
                                // onPressEnter={this.handleLogin}
                                placeholder={Resources.enterMobile}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" size="large" htmlType="submit" loading={submitting} block>{Resources.sendCode}</Button>
                        </Form.Item>
                    </Form>}
                    {step === 2 && <Form initialValues={{ mobile: mobile }} onFinish={this.handleVerify}>
                        <Form.Item name="password" rules={Rules.required}>
                            <Input
                                autoFocus
                                type="text"
                                size="large"
                                dir="ltr"
                                suffix={<NumberOutlined />}
                                // onPressEnter={this.handleVerify}
                                placeholder={Resources.enterCode}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" size="large"
                                    htmlType="submit"
                                    loading={submitting} block>{Resources.enter}</Button>
                        </Form.Item>
                    </Form>}

            </Fragment>
        )
    }
}

export default LoginPage;
