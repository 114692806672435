import axios from 'axios';
import { Cookies } from "react-cookie";
import { message } from 'antd';
import { Constants } from './settings';

const _cookies = new Cookies();

axios.defaults.baseURL = Constants.baseUrl;
axios.defaults.headers.common['Authorization'] = `Bearer ${_cookies.get('AccessToken') || ''}`;

const handleError = (resFromCatch) => {
    let data = resFromCatch['response']['data'];
    let status = resFromCatch['response']['status'];
    if(status === 422) {
        return Object.values(data.errors).forEach(item => message.error(`${item}`));

    }
    if(status === 401) {
        _cookies.remove('AccessToken', { path: '/', domain: window.location.hostname });
        window.location = '/account/login';
    }
    if(status === 403) {
        window.location = '/403'
    }
    if(status === 404) {
        window.location = '/404'
    }
    if(status === 500){
        message.error("خطای سمت سرور.")
    }
};

// panel/

const upload = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(url, params, { headers: { 'content-type': 'multipart/form-data' } })
            .then((response) => {
                if (response && response.data) {
                    if (response.status === 200) {
                        resolve(response.data);
                    } else {
                        handleError(response.data);
                        reject(response.data);
                    }
                }
            })
            .catch((error) => {
                handleError(error && error.response && error.response.data);
                reject(error && error.response && error.response.data);
            });
    });
};

const get = (url) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {headers: {'socketToken' : localStorage.getItem("socketApiToken")}})
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                handleError(error);
                reject(error && error.response && error.response.data);
            });
    });
};

const post = (url, params = {}, skip) => {
    return new Promise((resolve, reject) => {
        axios.post(url, params, {headers: {'socketToken' : localStorage.getItem("socketApiToken")}})
            .then((response) => {
                if (response && response.data) {
                    if (skip || response.status === 200) {
                        resolve(response.data);
                    } else {
                        handleError(response.data);
                        reject(JSON.parse(response.data));
                    }
                }
            })
            .catch((error) => {
                handleError(error);
                reject(error && error.response && error.response.data);
            });
    });
};

const del = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        axios.delete(url, params, {headers: {'socketToken' : localStorage.getItem("socketApiToken")}})
            .then((response) => {
                if (response && response.data) {
                    if (response.status === 200) {
                        resolve(response.data);
                    } else {
                        reject(response.data);
                    }
                }
            })
            .catch((error) => {
                handleError(error);
                reject(error && error.response && error.response.data);
            });
    });
};

export default {
    acl: {
        all: (params = {}) => {
            return get(`acl/permissions?q=${params.q || ''}`);
        },
        rules: (params = {}) => {
            return get(`acl/roles`);
        },
        user: (id) => {
            return get(`acl/permissions/user?userId=${id}`);
        },
        role: (id) => {
            return get(`acl/permissions/role?roleId=${id}`);
        },
        save: (values ,id) => {
            return post(`acl/permissions/${id}`, values);
        },
    },
    common: {
        grades: (params = {}) => {
            return get(`api/panel/common/grades`);
        },
        levels: (params = {}) => {
            return get(`api/panel/common/levels`);
        },
        fields: (params = {}) => {
            return get(`api/panel/common/fields`);
        },
        books: (params = {}) => {
            return get(`api/panel/common/books`);
        },
        teacherTeaches: (params = {}) => {
            return get(`api/panel/common/teacherTeaches`);
        },
        teacherBooks: (params = {}) => {
            return get(`api/panel/common/teacherBooks`);
        },
        bookChapter: (params = {}) => {
            return get(`api/panel/common/bookChapter/${params}`);
        },
        podcastCat: (params ={}) => {
            return get(`api/panel/common/podcastCat`);
        }
    },
    teacherManege: {
        allList: (params = {}) => {
            return get(`api/panel/manager/teacher?q=${params.q || ''}`);
        },
        deleteTeaches: (params = {}) => {
            return del(`api/panel/manager/teacher/teaches/${params}`);
        },
        teache: (params = {}) => {
            return get(`api/panel/manager/teacher/teaches/${params}`);
        },
        teacherModify: (params = {}) => {
            return post(`api/panel/manager/teacher`, params);
        },
        update: (params = {}) => {
            return post(`api/panel/manager/teacher/update`, params);
        },
        teacher: (id) => {
            return get(`api/panel/manager/teacher/${id}`);
        },
        teacherAdd: (values ,id) => {
            return post(`api/panel/manager/teacher`, {
                permissions: values,
                user_id: id
            });
        },
    },
    teacherBookManege: {
        allList: (params = {}) => {
            return get(`api/panel/manager/teacher?q=${params.q || ''}`);
        },
        deleteTeaches: (params = {}) => {
            return del(`api/panel/manager/teacher/teaches/${params}`);
        },
        teache: (params = {}) => {
            return get(`api/panel/manager/teacher/teaches/${params}`);
        },
        teacherModify: (params = {}) => {
            return post(`api/panel/manager/teacher`, params);
        },
        update: (params = {}) => {
            return post(`api/panel/manager/teacher/update`);
        },
        teacher: (id) => {
            return get(`api/panel/manager/teacher/${id}`);
        },
        teacherAdd: (values ,id) => {
            return post(`api/panel/manager/teacher`, {
                permissions: values,
                user_id: id
            });
        },
    },
    teacherLessonManege: {
        allLesson: (params = {}) => {
            return get(`api/panel/manager/teacher?q=${params.q || ''}`);
        },
        allList: (params = {}) => {
            return get(`api/panel/manager/teacher?q=${params.q || ''}`);
        },
        teacherModify: (params = {}) => {
            return post(`api/panel/manager/teacher`, params);
        },
        update: (params = {}) => {
            return post(`api/panel/manager/teacher/update`);
        },
        teacher: (id) => {
            return get(`api/panel/manager/teacher/${id}`);
        },
        teacherAdd: (values ,id) => {
            return post(`api/panel/manager/teacher`, {
                permissions: values,
                user_id: id
            });
        },
    },
    teacherLessonChapter: {
        list: (params = {}) => {
            return get(`api/panel/teacher/lesson/chapter/list/${params}`);
        },
        chapter: (params = {}) => {
            return get(`api/panel/teacher/lesson/chapter/${params}`);
        },
        add: (params = {}) => {
            return post(`api/panel/teacher/lesson/chapter`, params);
        },
        update: (params = {}) => {
            return post(`api/panel/teacher/lesson/chapter/update`, params);
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/teacher/lesson/chapter/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        },
    },
    teacherLessonChapterSection: {
        list: (params = {}) => {
            return get(`api/panel/teacher/lesson/chapter/section/list/${params}`);
        },
        vidList: (params = {}) => {
            return post(`api/panel/teacher/lesson/chapter/section/getVideosList`, params);
        },
        section: (params = {}) => {
            return get(`api/panel/teacher/lesson/chapter/section/${params}`);
        },
        add: (params = {}) => {
            return post(`api/panel/teacher/lesson/chapter/section`, params);
        },
        update: (params = {}) => {
            return post(`api/panel/teacher/lesson/chapter/section/update`, params);
        },
        addVideoPoster: (params = {}) => {
            return post(`api/panel/teacher/lesson/chapter/section/addVideoPoster`, params);
        },
        delete: (params = {}) => {
            return del(`api/panel/teacher/lesson/chapter/section/${params}`);
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/teacher/lesson/chapter/section/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        },
        posterEdit: (params = {}) => {
            return post(`api/panel/teacher/lesson/chapter/section/addVideoPoster`, params);
        },
    },
    user: {
        add: (params = {}) => {
            return post(`user/save`, params);
        },
        delete: (params = {}) => {
            return del(`user/${params.id}/destroy`);
        },
        details: (id) => {
            return get(`user/get/${id}`);
        },
        list: (params = {}) => {
            let src = `user/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.name)
                src = `${src}&name=${params.name}`;

            if (params.mobile)
                src = `${src}&mobile=${params.mobile}`;
            if (params.email)
                src = `${src}&email=${params.email}`;
            if (params.family)
                src = `${src}&family=${params.family}`;

            if (params.identifier)
                src = `${src}&identifier=${params.identifier}`;
            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        update: (params) => {
            return post(`user/update`, params);
        },
        increaseCredit: (params = {}) => {
            return post(`user/${params.id}/chargeAccount`, params);
        },
        decreaseCredit: (params = {}) => {
            return post(`user/${params.id}/removalAccount`, params);
        },
        permissionUpdate: (params = {}) => {
            return post(`user/${params.id}/permissionUpdate`, params);
        },
        statusUpdate: (params = {}) => {
            return get(`user/${!!parseInt(params.status) ? 'deActive' : 'active'}?userId=${params.id}`);
        },
        vipUpdate: (params = {}) => {
            return post(`user/${params.id}/VipUpdate?isVip=${!!parseInt(params.isVip) ? 0 : 1}`);
        },
        login: (params = {}) => {
            return post('getToken', params, true);
        },
        verify: (params = {}) => {
            return post('oauth/token', params, true);
        },
        deleteAgent: (id = {}) => {
            return del(`user/userAgent/${id}`, {}, true);
        }
    },
    grade: {
        list: (params = {}) => {
            let src = `api/panel/manager/grade?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        add: (params = {}) => {
            return post(`api/panel/manager/grade`, params);
        },
        update: (id , name) => {
            return post('api/panel/manager/grade/update', {"id" : id, "name": name});
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/manager/grade/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        }
    },
    level: {
        list: (params = {}) => {
            let src = `api/panel/manager/level?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        add: (params = {}) => {
            return post(`api/panel/manager/level`, params);
        },
        update: (id , name) => {
            return post('api/panel/manager/level/update', {"id" : id, "name": name});
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/manager/level/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        }
    },
    book: {
        list: (params = {}) => {
            let src = `api/panel/manager/book?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        add: (params = {}) => {
            return post(`api/panel/manager/book`, params);
        },
        update: (id , name) => {
            return post('api/panel/manager/book/update', {"id" : id, "name": name});
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/manager/book/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        }
    },
    bookPackage: {
        list: (params = {}) => {
            return get(`api/panel/manager/book/package/list/${params}`);
        },
        bookPackage: (params = {}) => {
            return get(`api/panel/manager/book/package/${params}`);
        },
        add: (params = {}) => {
            return post('api/panel/manager/book/package', params);
        },
        update: (params= {}) => {
            return post('api/panel/manager/book/package/update', params);
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/manager/book/package/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        }
    },
    orientation: {
        list: (params = {}) => {
            let src = `api/panel/manager/field?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        add: (params = {}) => {
            return post(`api/panel/manager/field`, params);
        },
        update: (id , name) => {
            return post('api/panel/manager/field/update', {"id" : id, "name": name});
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/manager/field/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        }
    },
    podcast: {
        list: (params = {}) => {
            return get(`api/panel/podcast/list`)
        },
        podcast: (params = {}) => {
            return get(`api/panel/podcast/${params}`)
        },
        add: (params = {}) => {
            return post(`api/panel/podcast`, params);
        },
        update: (params = {}) => {
            return post('api/panel/podcast/update', params);
            // id , podcast_cat , title , description , info , image
        },
        del: (params = {}) => {
            return del(`api/panel/podcast/delete/${params}`);
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/podcast/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        }
    },


    podcastSection: {
        list: (params = {}) => {
            return get(`api/panel/podcast/section/list/${params}`)
        },
        podcastSection: (params = {}) => {
            return get(`api/panel/podcast/section/${params}`)
        },
        add: (params = {}) => {
            return post(`api/panel/podcast/section`, params);
        },
        update: (params = {}) => {
            return post('api/panel/podcast/section/update', params);
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/podcast/section/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        },
        del: (params = {}) => {
            return del(`api/panel/podcast/section/delete/${params}`);
        },
    },
    podcastCat: {
        list: (params = {}) => {
            return get(`api/panel/manager/podcastCat`)
        },
        podcast: (params = {}) => {
            return get(`api/panel/manager/podcastCat/${params}`)
        },
        add: (params = {}) => {
            return post(`api/panel/manager/podcastCat`, params);
        },
        update: (params = {}) => {
            return post('api/panel/manager/podcastCat/update', params);
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/manager/podcastCat/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        }
    },
    consultant: {
        list: (params = {}) => {
            return get(`api/panel/consultant/list`)
        },
        consultant: (params = {}) => {
            return get(`api/panel/consultant/${params}`)
        },
        add: (params = {}) => {
            return post(`api/panel/consultant`, params);
        },
        update: (params = {}) => {
            return post('api/panel/consultant/update', params);
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/consultant/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        }
    },
    consultantDuration: {
        list: (params = {}) => {
            return get(`api/panel/consultant/duration/list/${params}`)
        },
        duration: (params = {}) => {
            return get(`api/panel/consultant/duration/${params}`)
        },
        add: (params = {}) => {
            return post(`api/panel/consultant/duration`, params);
        },
        update: (params = {}) => {
            return post('api/panel/consultant/duration/update', params);
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/consultant/duration/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        }
    },
    message: {
        list: (params = {}) => {
            return get(`api/panel/manager/message/toCustomer`);
        },
        message: (params = {}) => {
            return get(`api/panel/manager/message/toCustomer/${params}`);
        },
        add: (params = {}) => {
            return post(`api/panel/manager/message/toCustomer`, params);
        },
        update: (params = {}) => {
            return post(`api/panel/manager/message/toCustomer/update`, params);
        },
        delete: (params = {}) => {
            return del(`api/panel/manager/message/toCustomer/${params}`);
        },
    },
    discountCode: {
        list: (params = {}) => {
            return get(`api/panel/manager/discountCode`);
        },
        discountCode: (params = {}) => {
            return get(`api/panel/manager/discountCode/${params.id}/${params.type}`);
        },
        add: (params = {}) => {
            return post(`api/panel/manager/discountCode`, params);
        },
        update: (params = {}) => {
            return post(`api/panel/manager/discountCode/update`, params);
        },
        delete: (params = {}) => {
            return del(`api/panel/manager/discountCode/${params.id}/${params.type}`);
        },
    },
    advertise: {
        list: (params = {}) => {
            return get(`api/panel/manager/advertise`);
        },
        discountCode: (params = {}) => {
            return get(`api/panel/manager/advertise/${params}`);
        },
        add: (params = {}) => {
            return post(`api/panel/manager/advertise`, params);
        },
        update: (params = {}) => {
            return post(`api/panel/manager/advertise/update`, params);
        },
        delete: (params = {}) => {
            return del(`api/panel/manager/advertise/${params}`);
        },
    },
    getMessage: {
        list: (params = {}) => {
            return get(`api/panel/manager/message/toManage`);
        },
        message: (params = {}) => {
            return get(`api/panel/manager/message/toManage/${params}`);
        },
        delete: (params = {}) => {
            return del(`api/panel/manager/message/toManage/${params}`);
        },
    },
    cristicSugg: {
        list: (params = {}) => {
            return get(`api/panel/manager/cristSugg`);
        },
        delete: (params = {}) => {
            return del(`api/panel/manager/cristSugg/${params}`);
        },
    },
    Cooperation: {
        list: (params = {}) => {
            return get(`api/panel/manager/cooperation`);
        },
        delete: (params = {}) => {
            return del(`api/panel/manager/cooperation/${params}`);
        },
    },
    dashboard: {
        sliderList: (params = {}) => {
            return get(`api/panel/manager/slider/slidersList`);
        },
        slider: (params = {}) => {
            return get(`api/panel/manager/slider/getSlider/${params}`)
        },
        sliderAdd: (params = {}) => {
            return post(`api/panel/manager/slider/saveSlider`, params);
        },
        sliderUpdate: (params = {}) => {
            return post('api/panel/manager/slider/updateSlider', params);
        },
        sliderDelete: (params = {}) => {
            return del(`api/panel/manager/slider/deleteSlider/${params}`);
        },
        helpList: (params = {}) => {
            return get(`api/panel/manager/help/helpsList`);
        },
        help: (params = {}) => {
            return get(`api/panel/manager/help/getHelp/${params}`);
        },
        helpAdd: (params = {}) => {
            return post(`api/panel/manager/help/saveHelp`, params);
        },
        helpUpdate: (params = {}) => {
            return post('api/panel/manager/help/updateHelp', params);
        },
        helpDelete: (params = {}) => {
            return del(`api/panel/manager/help/deleteHelp/${params}`);
        },
        examList: (params = {}) => {
            return get(`api/panel/manager/exam/examsList`);
        },
        exam: (params = {}) => {
            return get(`api/panel/manager/exam/getExam/${params}`);
        },
        examAdd: (params = {}) => {
            return post(`api/panel/manager/exam/saveExam`, params);
        },
        examUpdate: (params = {}) => {
            return post('api/panel/manager/exam/updateExam', params);
        },
        examDelete: (params = {}) => {
            return del(`api/panel/manager/exam/deleteExam/${params}`);
        },
    },
    report: {
        lessonList: () => {
            return get(`api/panel/manager/report/lessonPaymentList`)
        },
        lessonDetail: (params = {}) => {
            return get(`api/panel/manager/report/lessonPaymentDetail/${params}`)
        },
        consultList: () => {
            return get(`api/panel/manager/report/consultPaymentList`);
        },
        consultDetail: (params = {}) => {
            return get(`api/panel/manager/report/consultPaymentDetail/${params}`);
        },
        onlineChargeList: (params = {}) => {
            return get('api/panel/manager/report/onlineChargeList');
        }
    },
    profile: {
        info: ( params = {} ) => {
            return get('user/info')
        },
        update: ( params = {} ) => {
            return post('user/updateProfile', params)
        },
        token: ( params = {} ) => {
            return post('api/admin/getToken', params)
        },
        logout: ( params = {} ) => {
            return post('user/logout', params)
        }
    },
    PodcastComment: {
        list: ( params = {} ) => {
            return get(`api/panel/podcast/comment/${params}`)
        },
        remove: ( params = {} ) => {
            return del(`api/panel/podcast/comment/${params}`)
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/podcast/comment/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        }
    },
    ConsultantComment: {
        list: ( params = {} ) => {
            return get(`api/panel/consultant/comment/${params}`)
        },
        remove: ( params = {} ) => {
            return del(`api/panel/consultant/comment/${params}`)
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/consultant/comment/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        }
    },
    TeacherComment: {
        list: ( params = {} ) => {
            return get(`api/panel/teacher/comment/${params}`)
        },
        remove: ( params = {} ) => {
            return del(`api/panel/teacher/comment/${params}`)
        },
        statusUpdate: (params = {}) => {
            return get(`api/panel/teacher/comment/${!!parseInt(params.status) ? 'deActive' : 'active'}/${params.id}`);
        }
    },
    social: {
        list: (params = {}) => {
            return get('api/panel/manager/social')
        },
        social: (params = {}) => {
            return get(`api/panel/manager/social/${params}`)
        },
        add: (params = {}) => {
            return post("api/panel/manager/social", params)
        },
        update: (params = {}) => {
            return post("api/panel/manager/social/update", params)
        },
        del: (params = {}) => {
            return del(`api/panel/manager/social/delete/${params}`)
        }
    },

//    new
    document: {
        list: (params = {}) => {
            return get('document/list')
        },
        social: (params = {}) => {
            return get(`document?document_id=${params}`)
        },
        add: (params = {}) => {
            return post("document/save", params)
        },
        update: (params = {}) => {
            return post("document/update", params)
        },
        del: (params = {}) => {
            return del(`document/delete/${params}`)
        }
    },
    facility: {
        list: (params = {}) => {
            return get(`facility/list`)
        },

        add: (params = {}) => {
            return post(`facility/save`, params)
        },
        update: ( params = {}) => {
            return post(`facility/update`, params)
        },
        del: ( params = {}) => {
            return del(`facility/delete/${params}`)
        }
    },
    heatCool: {
        list: (params = {}) => {
            return get('heatCool/list')
        },
        social: (params = {}) => {
            return get(`heatCool/${params}`)
        },
        add: (params = {}) => {
            return post("heatCool/save", params)
        },
        update: (params = {}) => {
            return post("heatCool/update", params)
        },
        del: (params = {}) => {
            return del(`heatCool/delete/${params}`)
        }
    },
    floorCover: {
        list: (params = {}) => {
            return get('floorCover/list')
        },
        social: (params = {}) => {
            return get(`floorCover/${params}`)
        },
        add: (params = {}) => {
            return post("floorCover/save", params)
        },
        update: (params = {}) => {
            return post("floorCover/update", params)
        },
        del: (params = {}) => {
            return del(`floorCover/delete/${params}`)
        }
    },
    houseView: {
        list: (params = {}) => {
            return get('houseView/list')
        },
        social: (params = {}) => {
            return get(`houseView/${params}`)
        },
        add: (params = {}) => {
            return post("houseView/save", params)
        },
        update: (params = {}) => {
            return post("houseView/update", params)
        },
        del: (params = {}) => {
            return del(`houseView/delete/${params}`)
        }
    },
    cupboard: {
        list: (params = {}) => {
            return get('cupboard/list')
        },
        social: (params = {}) => {
            return get(`cupboard/${params}`)
        },
        add: (params = {}) => {
            return post("cupboard/save", params)
        },
        update: (params = {}) => {
            return post("cupboard/update", params)
        },
        del: (params = {}) => {
            return del(`cupboard/delete/${params}`)
        }
    },
    documentType: {
        list: (params = {}) => {
            return get('documentType/list')
        },
        social: (params = {}) => {
            return get(`documentType/${params}`)
        },
        add: (params = {}) => {
            return post("documentType/save", params)
        },
        update: (params = {}) => {
            return post("documentType/update", params)
        },
        del: (params = {}) => {
            return del(`documentType/delete/${params}`)
        }
    },
    positionLand: {
        list: (params = {}) => {
            return get('positionLand/list')
        },
        social: (params = {}) => {
            return get(`positionLand/${params}`)
        },
        add: (params = {}) => {
            return post("positionLand/save", params)
        },
        update: (params = {}) => {
            return post("positionLand/update", params)
        },
        del: (params = {}) => {
            return del(`positionLand/delete/${params}`)
        }
    },
    jobCat: {
        list: (params = {}) => {
            return get('jobCat/list')
        },
        social: (params = {}) => {
            return get(`jobCat/${params}`)
        },
        add: (params = {}) => {
            return post("jobCat/save", params)
        },
        update: (params = {}) => {
            return post("jobCat/update", params)
        },
        del: (params = {}) => {
            return del(`jobCat/delete/${params}`)
        }
    },
    jobType: {
        list: (params = {}) => {
            let src = `jobType/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.jobCat_id)
                src = `${src}&jobCat_id=${params.jobCat_id}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        social: (params = {}) => {
            return get(`jobType/${params}`)
        },
        add: (params = {}) => {
            return post("jobType/save", params)
        },
        update: (params = {}) => {
            return post("jobType/update", params)
        },
        del: (params = {}) => {
            return del(`jobType/delete/${params}`)
        }
    },
    jobRank: {
        list: (params = {}) => {
            return get('jobRank/list')
        },
        social: (params = {}) => {
            return get(`jobRank/${params}`)
        },
        add: (params = {}) => {
            return post("jobRank/save", params)
        },
        update: (params = {}) => {
            return post("jobRank/update", params)
        },
        del: (params = {}) => {
            return del(`jobRank/delete/${params}`)
        }
    },
    country: {
        list: (params = {}) => {
            return get('country/list')
        },
        social: (params = {}) => {
            return get(`country/${params}`)
        },
        add: (params = {}) => {
            return post("country/save", params)
        },
        update: (params = {}) => {
            return post("country/update", params)
        },
        del: (params = {}) => {
            return del(`country/delete/${params}`)
        }
    },
    states: {
        list: (params = {}) => {
            let src = `state/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.country_id)
                src = `${src}&country_id=${params.country_id}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`state/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`state/save`, params);
        },
        update: (params = {}) => {
            return post(`state/update`, params);
        },

    },
    chat: {
        index: (params = {}) => {
            let src = `chat?q=${params.q || ''}`;
            return get(src);
        },
    },

    city: {
        list: (params = {}) => {
            let src = `city/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.state_id)
                src = `${src}&state_id=${params.state_id}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`city/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`city/save`, params);
        },
        update: (params = {}) => {
            return post(`city/update`, params);
        },

    },
    aboutUs: {
        list: (params = {}) => {
            let src = `aboutUs/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.state_id)
                src = `${src}&state_id=${params.state_id}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`aboutUs/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`aboutUs/save`, params);
        },
        update: (params = {}) => {
            return post(`aboutUs/update`, params);
        },

    },
    terms: {
        list: (params = {}) => {
            let src = `terms/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.state_id)
                src = `${src}&state_id=${params.state_id}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`terms/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`terms/save`, params);
        },
        update: (params = {}) => {
            return post(`terms/update`, params);
        },

    },
    news: {
        list: (params = {}) => {
            let src = `news/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.state_id)
                src = `${src}&state_id=${params.state_id}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`news/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`news/save`, params);
        },
        update: (params = {}) => {
            return post(`news/update`, params);
        },

    },
    employer: {
        list: (params = {}) => {
            let src = `employer/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.state_id)
                src = `${src}&state_id=${params.state_id}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`employer/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`employer/save`, params);
        },
        update: (params = {}) => {
            return post(`employer/update`, params);
        },

    },
    slider: {
        list: (params = {}) => {
            let src = `slider/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.state_id)
                src = `${src}&state_id=${params.state_id}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`slider/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`slider/save`, params);
        },
        update: (params = {}) => {
            return post(`slider/update`, params);
        },

    },
    formBuilderAdverOffer: {
        list: (params = {}) => {
            let src = `formBuilderAdverOffer/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.land_type_id)
                src = `${src}&land_type_id=${params.land_type_id}`;

            if (params.deal_type_id)
                src = `${src}&deal_type_id=${params.deal_type_id}`;

            if (params.show_name)
                src = `${src}&show_name=${params.show_name}`;
            if (params.name)
                src = `${src}&name=${params.name}`;
            if (params.placeholder)
                src = `${src}&placeholder=${params.placeholder}`;
            if (params.show_list_type)
                src = `${src}&show_list_type=${params.show_list_type}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },

        delete: (params = {}) => {
            return del(`formBuilderAdverOffer/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`formBuilderAdverOffer/save`, params);
        },

        addLogo: (params = {}) => {
            return post(`formBuilderAdverOffer/addLogo`, params);
        },
        update: (params = {}) => {
            return post(`formBuilderAdverOffer/update`, params);
        },
        show_in_search: (params = {}) => {
            return post(`formBuilderAdverOffer/show_in_search`, params);
        },
        show_in_slider: (params = {}) => {
            return get(`formBuilderAdverOffer/show_in_slider/${params}`);
        },
        show_as_buttons: (params = {}) => {
            return get(`formBuilderAdverOffer/show_as_buttons/${params}`);
        },

        setFieldForRole: (params = {}) => {
            return post(`formBuilderAdverOffer/setFieldForRole`, params);
        },

        setPriorities: (params = {}) => {
            return post(`formBuilderAdverOffer/setPriorities`, params);
        },

    },
    formBuilderAdverRequest: {
        list: (params = {}) => {
            let src = `formBuilderAdverRequest/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.land_type_id)
                src = `${src}&land_type_id=${params.land_type_id}`;

            if (params.deal_type_id)
                src = `${src}&deal_type_id=${params.deal_type_id}`;

            if (params.show_name)
                src = `${src}&show_name=${params.show_name}`;
            if (params.name)
                src = `${src}&name=${params.name}`;
            if (params.placeholder)
                src = `${src}&placeholder=${params.placeholder}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },

        distinct_list: (params = {}) => {
            let src = `formBuilderAdverRequest/distinct_list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`formBuilderAdverRequest/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`formBuilderAdverRequest/save`, params);
        },
        addLogo: (params = {}) => {
            return post(`formBuilderAdverRequest/addLogo`, params);
        },
        update: (params = {}) => {
            return post(`formBuilderAdverRequest/update`, params);
        },
        show_as_buttons: (params = {}) => {
            return get(`formBuilderAdverRequest/show_as_buttons/${params}`);
        },

    },
    formBuilderSearchAdverOffer: {
        list: (params = {}) => {
            let src = `formBuilderSearchAdverOffer/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.land_type_id)
                src = `${src}&land_type_id=${params.land_type_id}`;

            if (params.deal_type_id)
                src = `${src}&deal_type_id=${params.deal_type_id}`;

            if (params.show_name)
                src = `${src}&show_name=${params.show_name}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },

        distinct_list: (params = {}) => {
            let src = `formBuilderSearchAdverOffer/distinct_list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`formBuilderSearchAdverOffer/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`formBuilderSearchAdverOffer/save`, params);
        },
        update: (params = {}) => {
            return post(`formBuilderSearchAdverOffer/update`, params);
        },
        show_in_first_page: (params = {}) => {
            return get(`formBuilderSearchAdverOffer/show_in_first_page/${params}`);
        },
        show_in_second_page: (params = {}) => {
            return get(`formBuilderSearchAdverOffer/show_in_second_page/${params}`);
        },

    },
    searchFields: {
        list: (params = {}) => {
            let src = `searchfields/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`searchfields/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`searchfields/save`, params);
        },
        update: (params = {}) => {
            return post(`searchfields/update`, params);
        },
  

    },
    formBuilderAdverOfferIsOwner: {
        list: (params = {}) => {
            let src = `formBuilderAdverOfferIsOwner/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.state_id)
                src = `${src}&state_id=${params.state_id}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`formBuilderAdverOfferIsOwner/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`formBuilderAdverOfferIsOwner/save`, params);
        },
        update: (params = {}) => {
            return post(`formBuilderAdverOfferIsOwner/update`, params);
        },
        setFieldForRole: (params = {}) => {
            return post(`formBuilderAdverOfferIsOwner/setFieldForRole`, params);
        },
    },
    inputType: {
        list: (params = {}) => {
            let src = `formBuilderInfo/inputTypeList?q=${params.q || ''}`;
            return get(src);
        },
    },
    fieldType: {
        list: (params = {}) => {
            let src = `formBuilderInfo/fieldTypeList?q=${params.q || ''}`;
            return get(src);
        },
    },

    ReturnFieldsForm: {
        list: (params = {}) => {
            let src = `formBuilderInfo/ReturnFieldsForm?q=${params.q || ''}`;
            return post(src, params);
        },
    },
    ReturnExistFields: {
        list: (params = {}) => {
            let src = `formBuilderInfo/ReturnExistFieldsForm`;
            return post(src, params);
        },
    },
    ReturnExistRequestFields: {
        list: (params = {}) => {
            let src = `formBuilderInfo/ReturnExistRequestFieldsForm`;
            return post(src, params);
        },
    },
    getApiListData: {
        list: (params = {}) => {
            let src = `formBuilderInfo/getApiListData`;
            return post(src, params);
        },
    },
    ReturnFieldsFormSearch: {
        list: (params = {}) => {
            let src = `formBuilderSearchInfo/ReturnFieldsForm?q=${params.q || ''}`;
            return post(src, params);
        },
    },
    ReturnExistFieldsSearch: {
        list: (params = {}) => {
            let src = `formBuilderSearchInfo/ReturnExistFieldsForm`;
            return post(src, params);
        },
    },

    ReturnFieldsFormIsOwner: {
        list: (params = {}) => {
            let src = `formBuilderInfo/isOwnerAdver?q=${params.q || ''}`;
            return post(src, params);
        },
    },


    banner: {
        list: (params = {}) => {
            let src = `banner/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.state_id)
                src = `${src}&state_id=${params.state_id}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`banner/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`banner/save`, params);
        },
        update: (params = {}) => {
            return post(`banner/update`, params);
        },

    },


    counterItem: {
        list: (params = {}) => {
            let src = `counterItem/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.state_id)
                src = `${src}&state_id=${params.state_id}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`counterItem/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`counterItem/save`, params);
        },
        update: (params = {}) => {
            return post(`counterItem/update`, params);
        },

    },

    street: {
        list: (params = {}) => {
            let src = `street/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.city_id)
                src = `${src}&city_id=${params.city_id}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`street/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`street/save`, params);
        },
        update: (params = {}) => {
            return post(`street/update`, params);
        },

    },
    subNeighborhood: {
        list: (params = {}) => {
            let src = `subNeighborhood/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.neighborhood_id)
                src = `${src}&neighborhood_id=${params.neighborhood_id}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`subNeighborhood/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`subNeighborhood/save`, params);
        },
        update: (params = {}) => {
            return post(`subNeighborhood/update`, params);
        },

    },
    roles: {
        list: (params = {}) => {
            let src = `roles/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`roles/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`roles/save`, params);
        },
        update: (params = {}) => {
            return post(`roles/update`, params);
        },

    },
    landType: {
        list: (params = {}) => {
            let src = `landType/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            if (params.deal_type_id)
                src = `${src}&deal_type_id=${params.deal_type_id}`;
            return get(src);
        },
        delete: (params = {}) => {
            return del(`landType/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`landType/save`, params);
        },
        update: (params = {}) => {
            return post(`landType/update`, params);
        },

    },

    isOwnerAdver: {
        list: (params = {}) => {
            let src = `formBuilderInfo/isOwnerAdver?isOwner=${params.isOwner}`;
            return get(src);
        },

    },
    dealType: {
        list: (params = {}) => {
            let src = `dealType/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`dealType/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`dealType/save`, params);
        },
        update: (params = {}) => {
            return post(`dealType/update`, params);
        },

    },
    dealTypesListWithType: {
        offerList: (params = {}) => {
            return get(`dealType/offerList`)
        },
        requestList: (params = {}) => {
            return get(`dealType/requestList`)
        },
    },
    roomFacility: {
        list: (params = {}) => {
            let src = `roomFacility/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`roomFacility/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`roomFacility/save`, params);
        },
        update: (params = {}) => {
            return post(`roomFacility/update`, params);
        },

    },
    realStateRole: {
        list: (params = {}) => {
            let src = `realStateRole/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`realStateRole/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`realStateRole/save`, params);
        },
        update: (params = {}) => {
            return post(`realStateRole/update`, params);
        },

    },
    expertHousing: {
        list: (params = {}) => {
            let src = `expertHousing/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`expertHousing/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`expertHousing/save`, params);
        },
        update: (params = {}) => {
            return post(`expertHousing/update`, params);
        },

    },
    priceCurrency: {
        list: (params = {}) => {
            let src = `priceCurrency/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`priceCurrency/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`priceCurrency/save`, params);
        },
        update: (params = {}) => {
            return post(`priceCurrency/update`, params);
        },

    },
    areaUnit: {
        list: (params = {}) => {
            let src = `areaUnit/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`areaUnit/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`areaUnit/save`, params);
        },
        update: (params = {}) => {
            return post(`areaUnit/update`, params);
        },

    },


    articleCat: {
        list: (params = {}) => {
            return get('articleCat/list')
        },
        social: (params = {}) => {
            return get(`articleCat/${params}`)
        },
        add: (params = {}) => {
            return post("articleCat/save", params)
        },
        update: (params = {}) => {
            return post("articleCat/update", params)
        },
        del: (params = {}) => {
            return del(`articleCat/delete/${params}`)
        }
    },

    article: {
        list: (params = {}) => {
            let src = `article/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.articleCat_id)
                src = `${src}&articleCat_id=${params.articleCat_id}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`article/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`article/save`, params);
        },
        update: (params = {}) => {
            return post(`article/update`, params);
        },

    },

    buildingStep: {
        list: (params = {}) => {
            let src = `buildingStep/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`buildingStep/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`buildingStep/save`, params);
        },
        update: (params = {}) => {
            return post(`buildingStep/update`, params);
        },

    },

    gallery: {

        delete: (params = {}) => {
            return get(`common/delete/img/${params}`);
        },
    },

    connectUs: {
        list: (params = {}) => {
            let src = `connectUs/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`connectUs/delete/${params}`);
        },


    },

    socialNetwork: {
        list: (params = {}) => {
            let src = `socialNetwork/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        del: (params = {}) => {
            return del(`socialNetwork/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`socialNetwork/save`, params);
        },
        update: (params = {}) => {
            return post(`socialNetwork/update`, params);
        },

    },
    uploader: {
        list: (params = {}) => {
            let src = `uploader/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        del: (params = {}) => {
            return del(`uploader/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`uploader/save`, params);
        },


    },

    contactUs: {
        list: (params = {}) => {
            let src = `contactUs/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        delete: (params = {}) => {
            return del(`contactUs/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`contactUs/save`, params);
        },
        update: (params = {}) => {
            return post(`contactUs/update`, params);
        },

    },


    offerHousing: {
        list: (params = {}) => {
            let src = `advertiseHousingOffer/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.per_page)
                src = `${src}&per_page=${params.per_page}`;
            if (params.country_id)
                src = `${src}&country_id=${params.country_id}`;
            if (params.city_id)
                src = `${src}&city_id=${params.city_id}`;
            if (params.neighborhood_id)
                src = `${src}&neighborhood_id=${params.neighborhood_id}`;
            if (params.deal_type_id)
                src = `${src}&deal_type_id=${params.deal_type_id}`;
            if (params.land_type_id)
                src = `${src}&land_type_id=${params.land_type_id}`;
            if (params.track_code)
                src = `${src}&track_code=${params.track_code}`;
            if (params.mobile)
                src = `${src}&mobile=${params.mobile}`;

            if (params.identifier)
                src = `${src}&identifier=${params.identifier}`;
            if (params.status)
                src = `${src}&status=${params.status}`;
            if (params.fromDate)
                src = `${src}&fromDate=${params.fromDate}`;

            if (params.toDate)
                src = `${src}&toDate=${params.toDate}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        del: (params = {}) => {
            return del(`advertiseHousingOffer/delete/${params}`);
        },
        active: (params = {}) => {
            return get(`advertiseHousingOffer/active/${params}`);
        },
        deActive: (params = {}) => {
            return post(`advertiseHousingOffer/deActive`, params);
        },
        wait: (params = {}) => {
            return get(`advertiseHousingOffer/wait/${params}`);
        },

        vip: (params = {}) => {
            return get(`advertiseHousingOffer/vip/${params}`);
        },

        slider: (params = {}) => {
            return get(`advertiseHousingOffer/slider/${params}`);
        },
        add: (params = {}) => {
            return post(`advertiseHousingOffer/save`, params);
        },
        update: (params = {}) => {
            return post(`advertiseHousingOffer/update`, params);
        },
        changeAgent: (params = {}) => {
            return post(`advertiseHousingOffer/changeAgent`, params);
        },
    },
    requestHousing: {
        list: (params = {}) => {
            let src = `advertiseHousingRequest/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.per_page)
                src = `${src}&per_page=${params.per_page}`;
            if (params.country_id)
                src = `${src}&country_id=${params.country_id}`;
            if (params.city_id)
                src = `${src}&city_id=${params.city_id}`;
            if (params.neighborhood_id)
                src = `${src}&neighborhood_id=${params.neighborhood_id}`;
            if (params.deal_type_id)
                src = `${src}&deal_type_id=${params.deal_type_id}`;
            if (params.land_type_id)
                src = `${src}&land_type_id=${params.land_type_id}`;
            if (params.track_code)
                src = `${src}&track_code=${params.track_code}`;
            if (params.mobile)
                src = `${src}&mobile=${params.mobile}`;
            if (params.identifier)
                src = `${src}&identifier=${params.identifier}`;

            if (params.status)
                src = `${src}&status=${params.status}`;
            if (params.fromDate)
                src = `${src}&fromDate=${params.fromDate}`;

            if (params.toDate)
                src = `${src}&toDate=${params.toDate}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        del: (params = {}) => {
            return del(`advertiseHousingRequest/delete/${params}`);
        },
        active: (params = {}) => {
            return get(`advertiseHousingRequest/active/${params}`);
        },
        deActive: (params = {}) => {
            return post(`advertiseHousingRequest/deActive`, params);
        },
        wait: (params = {}) => {
            return get(`advertiseHousingRequest/wait/${params}`);
        },
        vip: (params = {}) => {
            return get(`advertiseHousingRequest/vip/${params}`);
        },
        add: (params = {}) => {
            return post(`advertiseHousingRequest/save`, params);
        },
        update: (params = {}) => {
            return post(`advertiseHousingRequest/update`, params);
        },
        get: (params = {}) => {
            return get(`advertiseHousingRequest/get/${params}`);
        },
        formData: (params = {}) => {
            let src = `advertiseHousingRequest/formData?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.deal_type_id)
                src = `${src}&deal_type_id=${params.deal_type_id}`;
            if (params.land_type_id)
                src = `${src}&land_type_id=${params.land_type_id}`;


            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        manage: (id,params = {}) => {
            return post(`advertiseHousingRequest/manage/${id}`, params);
        },
        changeAgent: (params = {}) => {
            return post(`advertiseHousingRequest/changeAgent`, params);
        },

    },
    jobOffer: {
        list: (params = {}) => {
            let src = `jobOffer/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        del: (params = {}) => {
            return del(`jobOffer/delete/${params}`);
        },
        active: (params = {}) => {
            return get(`jobOffer/active/${params}`);
        },
        deActive: (params = {}) => {
            return post(`jobOffer/deActive`, params);
        },
        wait: (params = {}) => {
            return get(`jobOffer/wait/${params}`);
        },
        vip: (params = {}) => {
            return get(`jobOffer/vip/${params}`);
        },


    },
    jobRequest: {
        list: (params = {}) => {
            let src = `jobRequest/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;

            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        del: (params = {}) => {
            return del(`jobRequest/delete/${params}`);
        },
        active: (params = {}) => {
            return get(`jobRequest/active/${params}`);
        },
        deActive: (params = {}) => {
            return post(`jobRequest/deActive`, params);
        },
        wait: (params = {}) => {
            return get(`jobRequest/wait/${params}`);
        },


    },

    agent: {
        list: (params = {}) => {
            let src = `agent/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.city_id)
                src = `${src}&city_id=${params.city_id}`;
            if (params.neighborhood_id)
                src = `${src}&neighborhood_id=${params.neighborhood_id}`;
            if (params.phone)
                src = `${src}&phone=${params.phone}`;
            if (params.title)
                src = `${src}&title=${params.title}`;
            if (params.code)
                src = `${src}&code=${params.code}`;
            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        del: (params = {}) => {
            return del(`agent/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`agent/save`, params);
        },
        update: (params = {}) => {
            return post(`agent/update`, params);
        },
        info: (params = {}) => {
            return get(`agent/info/${params}`);
        },

    },
    FBItem: {
        list: (params = {}) => {
            let src = `FBItem/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            return get(src);
        },
        del: (params = {}) => {
            return del(`FBItem/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`FBItem/save`, params);
        },
        update: (params = {}) => {
            return post(`FBItem/update`, params);
        },

    },
    FBItemWebService: {
        list: (params = {}) => {
            let src = `FBItem/web_service/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            return get(src);
        },
        del: (params = {}) => {
            return del(`FBItem/web_service/delete/${params}`);
        },
        add: (params = {}) => {
            return post(`FBItem/web_service/save`, params);
        },
        update: (params = {}) => {
            return post(`FBItem/web_service/update`, params);
        },

    },
    FBItemWebServiceData: {
        list: (params = {}) => {
            let src = `FBItem/web_service/data/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.webService_id)
                src = `${src}&webService_id=${params.webService_id}`;
            return get(src);
        },
        del: (id = {},params={} ) => {
            return post(`FBItem/web_service/data/delete/${id}`, params);
        },
        add: (params = {}) => {
            return post(`FBItem/web_service/data/save`, params);
        },
        update: (params = {}) => {
            return post(`FBItem/web_service/data/update`, params);
        },

    },
    agentToAdvertise: {
        list: (params = {}) => {
            let src = `agentToAdvertise/list?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.city_id)
                src = `${src}&city_id=${params.city_id}`;
            if (params.neighborhood_id)
                src = `${src}&neighborhood_id=${params.neighborhood_id}`;
            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        rawList: (params = {}) => {
            let src = `agentToAdvertise/rawList?q=${params.q || ''}`;
            if (params.page)
                src = `${src}&page=${params.page}`;
            if (params.city_id)
                src = `${src}&city_id=${params.city_id}`;
            if (params.neighborhood_id)
                src = `${src}&neighborhood_id=${params.neighborhood_id}`;
            if (params.level && params.level.length)
                params.level.forEach(o => { src = `${src}&level[]=${o}`; });
            return get(src);
        },
        del: (params = {}) => {
            return del(`agentToAdvertise/delete/${params}`);
        },

        add: (params = {}) => {
            return post(`agentToAdvertise/save`, params);
        },
        update: (params = {}) => {
            return post(`agentToAdvertise/update`, params);
        },


    },

    countries: {
        list: (params = {}) => {
            let src = `countries`;
            return get(src);
        },
    },
    countryCities: {
        list: (params = {}) => {
            let src = `cities/country/${params}`;
            return get(src);
        },
    },
    cityNeighbourhood: {
        list: (params = {}) => {
            let src = `neighbourhood/${params}`;
            return get(src);
        },
        info: (params = {}) => {
            let src = `neighbourhood/info/${params}`;
            return get(src);
        },
    },
    realStateRoleList: {
        list: (params = {}) => {
            let src = `realStateRole`;
            return get(src);
        },
    },
    expertHousingList: {
        list: (params = {}) => {
            let src = `expertHousing`;
            return get(src);
        },
    },

};
