import React, {Component, Fragment, useState} from 'react';
import {cloneDeep} from "lodash";
import {Col, Modal, Row, Input, Form, Upload, Select, Radio, Button} from "antd";
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import API from "../scripts/api";

class AddFormBuilderAdverOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            dealTypesList: [],
            landTypeList: [],
            inputTypeList: [],
            fieldTypeList: [],
            existFieldsList: [],
            api_data_custom_list: [],
            visibleMap: false,
            isOwner: false,
            dealTypeSelected: false,
            landTypeSelected: false,
            inputTypeSelected: false,
            fieldTypeSelected: false,
            existFieldSelected: false,
            itemTypeSelected: false,
            is_owner: "",
            id: '',
            apiFormElements: [],
            formValues: [], // STATE HATO INJA BAYAD BEZARI
            selectOptionsArr: [],
            selectOptionCounter: 0,
        };

        this.formRef = React.createRef();
        this.mapRef = React.createRef();

    }



    handleSubmit = () => {
        let {selectOptionsArr} = this.state
        let names = []
        this.formRef.current.validateFields()
            .then(values => {
                selectOptionsArr.map((item) => {
                    Object.entries(values).map(([key, value]) => (
                        (key == `name-${item.counter}`) ? names.push({"counter": item.counter, "id": item.id , "value":value}) : ""
                    ))
                })
                let selectOptions = {"names" : names}
                this.props.onSubmit(values,selectOptions, this.state.id);
            });
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();
    };

    handleDidMount = async (data = {}) => {
        // const [selectOptionsArr, setSelectOptionsArr] = useState([]);
        // const [artists, setArtists] = useState([]);
        this.setState({selectOptionCounter: 0, selectOptionsArr:[], apiFormElements:[], api_data_custom_list:[]});
        const cloneData = cloneDeep(data);
        console.log("selectOptionsArr0000", this.state.selectOptionsArr);
        console.log(!!cloneData.id);


         this.setState({editMode: !!cloneData.id, id: cloneData.id});
        if( this.state.editMode){
            console.log("cloneData", cloneData)
            await this.setState({id: cloneData.id});
            await this.setState({isOwner: cloneData.is_owner});
            await this.setState({dealTypeSelected: cloneData.deal_type});
            await this.setState({landTypeSelected: cloneData.land_type});
            // await this.setState({inputTypeSelected: cloneData.input_type});
            // await this.setState({fieldTypeSelected: cloneData.field_type});
            await this.setState({itemTypeSelected: cloneData.item_type_id});
            // let { selectOptionsArr} = this.state
            console.log("1111111");
            console.log("selectOptionsArr1111", this.state.selectOptionsArr);
            await Promise.all(
                 await cloneData.selectOptions.map(async (item) => {
                    let counter = this.state.selectOptionCounter + 1
                    let tempName = "name-"+counter
                    this.setState({selectOptionCounter: counter});
                    await this.setState({ selectOptionsArr: [...this.state.selectOptionsArr, {"name": item.name,  "counter": counter, "id": item._id, [tempName]: item.name}] })
                     cloneData[tempName] = item.name

                 })
            )

            console.log("22222222");
            console.log("selectOptionsArr222", this.state.selectOptionsArr);
        }
        this.formRef.current.setFieldsValue(cloneData);

        await this.getDealTypes()
        if( this.state.editMode){
            await this.getLandType()
            await this.ReturnFieldsForm(this.state.itemTypeSelected)
            if(cloneData.api_data){
                await this.HandleChangeApiListData(cloneData.api_data)
            }

        }else{
            await this.getExistFields()
        }
        await this.getFieldType()
    }

    getDealTypes = (params = {}) => {
        this.setState({ loading: true });
        API.dealTypesListWithType.offerList()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        dealTypesList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getLandType = (params = {}) => {
        this.setState({ loading: true });
        let paramsInput = {"deal_type_id" : params}
        if(this.state.editMode){
             paramsInput = {"deal_type_id" : this.state.dealTypeSelected}
        }

        API.landType.list(paramsInput)

            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        landTypeList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getFieldType = (params = {}) => {
        this.setState({ loading: true });
        API.fieldType.list()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        fieldTypeList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getExistFields = (params = {}) => {
        this.setState({ loading: true });
        API.FBItem.list()

            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        existFieldsList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    ReturnFieldsForm = (itemTypeId, existField=null) => {
        let InpuParams = {
            isOwner : this.state.is_owner,
            dealType : this.state.dealTypeSelected,
            landType : this.state.landTypeSelected,
            item_type_id : itemTypeId,
        }
        API.ReturnFieldsForm.list(InpuParams)
            .then(async (result) => {
                console.log(result)
                if (result) {

                    await this.setState({
                        loading: false,
                        apiFormElements: await result,
                    });
                    if (existField){
                        this.ReturnExistFieldsFormList(existField)
                    }
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    ReturnExistFieldsForm = (id) => {
        let InpuParams = {
            isOwner : this.state.is_owner,
            dealType : this.state.dealTypeSelected,
            landType : this.state.landTypeSelected,
            id : id,
        }
        API.ReturnExistFields.list(InpuParams)
            .then((result) => {
                console.log(result)
                if (result.data) {
                    let cloneData = result.data[0]
                    // this.setState({itemTypeSelected: cloneData.item_type_id});
                    this.ReturnFieldsForm(this.state.itemTypeSelected, cloneData.id)
                    this.setState({
                        loading: false,
                        // apiFormElements: result.data,
                        });

                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    ReturnExistFieldsFormList = (id) => {
        let InpuParams = {
            isOwner : this.state.is_owner,
            dealType : this.state.dealTypeSelected,
            landType : this.state.landTypeSelected,
            id : id,
        }
        API.ReturnExistFields.list(InpuParams)
            .then((result) => {
                console.log(result)
                if (result.data) {
                    let cloneData = cloneDeep(result.data[0]);

                    this.formRef.current.setFieldsValue(cloneData);
                    this.setState({
                        loading: false,
                        // apiFormElements: result.data,
                        });
                    console.log("cloneData", cloneData)
                    // this.setState({id: cloneData.id});
                    // this.setState({isOwner: cloneData.is_owner});
                    // this.setState({dealTypeSelected: cloneData.deal_type});
                    // this.setState({landTypeSelected: cloneData.land_type});
                    // this.setState({inputTypeSelected: cloneData.input_type});
                    // this.setState({itemTypeSelected: cloneData.item_type_id});

                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    HandleChangeItemType = (e) => {
        this.setState({itemTypeSelected : e})
        this.ReturnFieldsForm(e)
    }

    HandleSelectExistField = (e) => {

        this.ReturnExistFieldsForm(e)
    }

    HandleDealTypeChange = (e) => {
        this.setState(
            {dealTypeSelected : e}
        )
        this.getLandType(e)
    }
    HandleLandTypeChange = (e) => {
        this.setState(
            {landTypeSelected : e}
        )
    }
    HandleIsOwnerChange = (e) => {
        this.setState(
            {is_owner : e.target.value}
        )

    }

    HandleChangeApiListData = (val) => {

        this.setState({api_data_custom_list:[]})
        API.getApiListData.list({api_data:val})
            .then((result) => {

                if (result.data) {
                    console.log("result.data", result.data)
                    this.setState({
                        loading: false,
                    });
                    this.setState({api_data_custom_list: result.data});

                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    addOptionForSelect = (param = {}) => {
        let {selectOptionCounter, selectOptionsArr} = this.state
        let counter = this.state.selectOptionCounter + 1
        this.setState({selectOptionCounter: counter });
        selectOptionsArr.push({"name": "", "counter": counter, "id":""})
        this.setState({selectOptionsArr: selectOptionsArr });
    }

    deleteOptionSelect = (param = {}) => {
        let {selectOptionCounter, selectOptionsArr} = this.state
        selectOptionsArr.pop({"name": param})
        this.setState({selectOptionsArr:selectOptionsArr });
    }

    render() {
        const {onCancel, submitting, visible} = this.props;
        const { editMode, landTypeList, dealTypesList, isOwner, dealTypeSelected,api_data_custom_list,
            landTypeSelected, apiFormElements, selectOptionsArr, itemTypeSelected
            , fieldTypeList,inputTypeList, existFieldsList, existFieldSelected} = this.state; // INJA STATE HATO MIYARI TO RENDER INJA FARAKHONI MISHE AZ BALA
        const {TextArea} = Input;
        // this.setState(selectOptionsArr => ({ ...selectOptionsArr }));
        // this.setState({ selectOptionsArr: [...this.state.selectOptionsArr] })

        return (

            <Fragment>
                <Modal
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                    width={1000}
                >
                    <Form ref={this.formRef} layout="vertical"  >
                        <Row gutter={24} >
                            <Col xs={24} md={12}>
                                <Form.Item name="deal_type" label={Resources.dealType} initialValue= {dealTypeSelected} >
                                    <Select showSearch
                                            filterOption={(input, option) => {
                                                return (
                                                    option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                            onChange={(e) => this.HandleDealTypeChange(e)}>
                                        {dealTypesList.map(item => (
                                            <Select.Option title={item.name} key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="land_type" label={Resources.landType}  initialValue= {landTypeSelected}>
                                    <Select
                                        showSearch
                                        filterOption={(input, option) => {
                                            return (
                                                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );

                                        }}
                                        onChange={(e) => this.HandleLandTypeChange(e)}>
                                        {landTypeList.map(item => (
                                            <Select.Option title={item.name} key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {!editMode &&
                            <Col xs={24} md={12}>
                                <Form.Item name="fb_item_id" label={Resources.field}
                                           initialValue={existFieldSelected}>
                                    <Select
                                        showSearch
                                        // filterOption={(input, option) => {
                                        //     return (
                                        //         (option.key) ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : ""
                                        //     );
                                        // }}

                                        filterOption={(inputValue, option) =>
                                            option.props.children
                                                .toString()
                                                .toLowerCase()
                                                .includes(inputValue.toLowerCase())
                                        }
                                        onChange={(e) => this.HandleSelectExistField(e)}>
                                        {existFieldsList.map(item => (
                                            <Select.Option  title={item.name} key={item.id} value={item.id}>{item.show_label}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            }

                            <Col xs={24} md={12}>
                                <Form.Item  name="item_type_id" label={Resources.fieldType}  initialValue= {itemTypeSelected}>
                                    <Select
                                        showSearch
                                        filterOption={(input, option) => {
                                            return (
                                                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );

                                        }}
                                        onChange={(e) => this.HandleChangeItemType(e)} >
                                        {fieldTypeList.map(item => (
                                            <Select.Option title={item.name} key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>



                            {
                                apiFormElements.map(item =>

                                          <Col xs={24} md={12}>

                                              { ((item.type == "input") && (item.required)) ?
                                                          <Form.Item name={item.name} label={item.showName}  rules={Rules.required}>
                                                              <Input type={item.fieldType} placeholder={item.showName}  />
                                                          </Form.Item> :
                                                          ((item.type == "input") && (!item.required)) ?
                                                              <Form.Item name={item.name} label={item.showName}  >
                                                                  <Input type={item.fieldType} placeholder={item.showName}  />
                                                              </Form.Item>
                                                      :item.type == "select" ?

                                                              <div>
                                                                  {
                                                                      item.name == "api_data" ? (

                                                                              <Form.Item name={item.name} label={item.showName} >
                                                                                  <Select
                                                                                      showSearch
                                                                                      onChange={(e) => this.HandleChangeApiListData(e)}
                                                                                      optionFilterProp="children"
                                                                                      filterOption={(input, option) =>
                                                                                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                      }
                                                                                  >
                                                                                      {item.data.map(item => (
                                                                                          <Select.Option key={item._id}  value={item._id}>{item.name}</Select.Option>
                                                                                      ))}
                                                                                  </Select>
                                                                              </Form.Item>

                                                                            ) : (
                                                                          <Form.Item name={item.name} label={item.showName} >
                                                                              <Select>
                                                                                  {item.data.map(item => (
                                                                                      <Select.Option key={item._id}  value={item._id}>{item.name}</Select.Option>
                                                                                  ))}
                                                                              </Select>
                                                                          </Form.Item>
                                                                      )
                                                                  }
                                                              </div>



                                                      :item.type == "multiselect" ?
                                                                  <Form.Item name={item.name}
                                                                             label={item.showName}>
                                                                      <Select mode="multiple" showSearch
                                                                              optionFilterProp="children"

                                                                          // filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                      >
                                                                          {item.data.map(item => (
                                                                              <Select.Option title={item.name}
                                                                                             key={item._id}
                                                                                             value={item._id}>{item.name}</Select.Option>
                                                                          ))}
                                                                      </Select>
                                                                  </Form.Item>

                                                      :item.type == "textarea" ?
                                                                  <Form.Item name={item.name} label={item.showName} >
                                                                      <TextArea  placeholder={item.showName}
                                                                               />
                                                                  </Form.Item>


                                                      :item.type == "embedsList" ?

                                                                      // () => { console.log(selectOptionsArr)}
                                                                      <div className="row">
                                                                          <div  style={{ textAlign: "center", background: '#d1c8c8',
                                                                              boxShadow:"box-shadow: 3px 4px 5px", marginBottom:"10px", fontSize:"large"}}> مقادیر slect</div>
                                                                          {selectOptionsArr &&
                                                                          selectOptionsArr.map( option =>(

                                                                              <Row gutter={24}>
                                                                                  <Col xs={24} md={16}  >
                                                                                      <Form.Item initialValue={option.name}
                                                                                                 name={`name-${option.counter}`}
                                                                                                 label={Resources.title}>
                                                                                          <Input type="text" dir="rtl"  />
                                                                                      </Form.Item>

                                                                                  </Col>

                                                                                  <Col xs={24} md={8} style={{
                                                                                      display: 'flex',
                                                                                      alignItems: 'center'
                                                                                  }}>
                                                                                      <Button style={{width: "100%"}}
                                                                                              loading={submitting}
                                                                                              type='danger'
                                                                                              onClick={() => this.deleteOptionSelect(option)}>
                                                                                          {Resources.delete}
                                                                                      </Button>
                                                                                  </Col>
                                                                              </Row>
                                                                          ))
                                                                          }
                                                                          <Col xs={24} style={{display: 'flex',alignItems: 'center'}}>
                                                                              <Button style={{width: "100%"}} loading={submitting} type='success' onClick={() => this.addOptionForSelect()} >
                                                                                  {Resources.addOptionSelect}
                                                                              </Button>
                                                                          </Col>
                                                                      </div>


                                                      :
                                                          <Form.Item name={item.name} label={item.showName} rules={Rules.required} >
                                                              <Radio.Group>
                                                                  <Radio value="yes">{Resources.yes}</Radio>
                                                                  <Radio value="no" >{Resources.no}</Radio>
                                                              </Radio.Group>
                                                          </Form.Item>
                                              }
                                              </Col>
                                )
                            }
                        </Row>
                        <Row>
                            {
                               !! api_data_custom_list.length  &&
                                <Col xs={24} md={24}>
                                    <Form.Item name="api_data_custom" label={Resources.facilityFieldsList} >
                                        <Select mode="multiple" showSearch
                                                optionFilterProp="children"
                                            // filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {api_data_custom_list.map(item => (
                                                <Select.Option title={item.name} key={item.id}  value={item.id}>{item.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            }

                        </Row>

                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default AddFormBuilderAdverOffer;
