import React, {Component, Fragment} from 'react';
import {cloneDeep} from "lodash";
import {Col, Modal, Row, Input, Form, Upload, Radio, Select} from "antd";
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import {ProjectOutlined, InboxOutlined, DeploymentUnitOutlined} from '@ant-design/icons';

class AddFBItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileList: [],
            title: '',
            userRoles: '',
            selectedRoles: null,
            addresses: [{id: 0}],
            phones: [],
            selectedAddress: 0,
            visibleMap: false,
            id: ''
        };

        this.formRef = React.createRef();
        this.mapRef = React.createRef();
    }


    handleSubmit = () => {
        this.formRef.current.validateFields()
            .then(values => {
                const formData = new FormData;
                formData.append('name', values.name);
                formData.append('show_label', values.show_label);
                formData.append('min_length', values.min_length);
                formData.append('max_length', values.max_length);
                formData.append('col_count_in_row', values.col_count_in_row);
                formData.append('priority', values.priority);
                formData.append('show_in_detail_page', values.show_in_detail_page);
                formData.append('show_position_in_site', values.show_position_in_site);
                formData.append('required_field', values.required_field);
                formData.append('description', values.description);
                formData.append('show_header_title', values.show_header_title);
                if (values.logo && values.logo.fileList && values.logo.fileList.length)
                    formData.append('logo', values.logo.file, values.logo.file.name);
                if (this.state.editMode){
                    formData.append("id", this.state.id);
                    this.props.onSubmit(formData, this.state.id);
                } else {
                    this.props.onSubmit(formData);
                }
            });
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();
    };

    handleDidMount = (data = {}) => {
        const cloneData = cloneDeep(data);
        this.formRef.current.setFieldsValue(cloneData);
        console.log(!!cloneData.id);
        this.setState({editMode: !!cloneData.id, id: cloneData.id});
    }


    render() {
        const {onCancel, submitting, visible} = this.props;
        const {addresses, editMode, phones, visibleMap, fileList} = this.state;


        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return { fileList: newFileList };
                });
            },
            beforeUpload: file => {
                this.setState({ fileList: [file], fileData: file });
                return false;
            },
            // fileList
        };

        return (
            <Fragment>
                <Modal
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                    width={1000}
                >
                    <Form ref={this.formRef} layout="vertical">
                        <Row gutter={24}>
                            <Col xs={24} md={12}>
                                <Form.Item name="name" label={Resources.name} rules={Rules.required}>
                                    <Input type="text" prefix={<ProjectOutlined/>} placeholder={Resources.name}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="show_label" label={Resources.show_title} >
                                    <Input type="text" prefix={<ProjectOutlined/>} placeholder={Resources.show_title}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="min_length" label={Resources.min_length} >
                                    <Input type="text" prefix={<ProjectOutlined/>} placeholder={Resources.min_length}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="max_length" label={Resources.max_length} >
                                    <Input type="text" prefix={<ProjectOutlined/>} placeholder={Resources.max_length}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>

                                <Form.Item name="col_count_in_row" label={Resources.col_count_in_row}  >
                                    <Select >
                                        <Select.Option   value="4">4</Select.Option>
                                        <Select.Option   value="5">5</Select.Option>
                                        <Select.Option   value="6">6</Select.Option>
                                        <Select.Option   value="7">7</Select.Option>
                                        <Select.Option   value="8">8</Select.Option>
                                        <Select.Option   value="9">9</Select.Option>
                                        <Select.Option   value="10">10</Select.Option>
                                        <Select.Option   value="11">11</Select.Option>
                                        <Select.Option   value="12">12</Select.Option>
                                        <Select.Option   value="13">13</Select.Option>
                                        <Select.Option   value="14">14</Select.Option>
                                        <Select.Option   value="15">15</Select.Option>
                                        <Select.Option   value="16">16</Select.Option>
                                        <Select.Option   value="17">17</Select.Option>
                                        <Select.Option   value="18">18</Select.Option>
                                        <Select.Option   value="19">19</Select.Option>
                                        <Select.Option   value="20">20</Select.Option>
                                        <Select.Option   value="21">21</Select.Option>
                                        <Select.Option   value="22">22</Select.Option>
                                        <Select.Option   value="23">23</Select.Option>
                                        <Select.Option   value="24">24</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="priority" label={Resources.priority} >
                                    <Input type="text" prefix={<ProjectOutlined/>} placeholder={Resources.priority}/>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="show_in_detail_page" label={Resources.show_in_detail_page} >
                                    <Input type="text" prefix={<ProjectOutlined/>} placeholder={Resources.show_in_detail_page}/>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="show_in_site_position" label={Resources.show_in_site_position} >
                                    <Input type="text" prefix={<ProjectOutlined/>} placeholder={Resources.show_in_site_position}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="required_field" label={Resources.required}  >
                                    <Radio.Group>
                                        <Radio value="yes">{Resources.yes}</Radio>
                                        <Radio value="no" >{Resources.no}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="description" label={Resources.description} >
                                    <Input type="text" prefix={<ProjectOutlined/>} placeholder={Resources.description}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="show_header_title" label={Resources.show_header_title}  >
                                    <Radio.Group>
                                        <Radio value="yes">{Resources.yes}</Radio>
                                        <Radio value="no" >{Resources.no}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col xs={24} md={12}>
                                <Form.Item name="logo" label={Resources.image} >
                                    <Upload.Dragger disabled={submitting} {...props} className="ant-upload-block">
                                        <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                        <p className="ant-upload-hint">{Resources.uploadText}</p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default AddFBItem;
