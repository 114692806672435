import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Switch } from 'react-router';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ConfigProvider } from 'antd';
import * as serviceWorker from './serviceWorker';
import fa_IR from 'antd/lib/locale-provider/fa_IR';
import Routes from './scripts/routes';
import store from './scripts/store';
import SocketProvider from './scripts/soketProvider';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import 'leaflet/dist/leaflet.css';
import './styles/index.less';

import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker
//         .register('/serviceWorker.js')
//         .then(function (registration) {
//             console.log('Registration successful, scope is:', registration.scope);
//         })
//         .catch(function (err) {
//             console.log('Service worker registration failed, error:', err);
//         });
// }

ReactDOM.render(
    <ConfigProvider direction="rtl" locale={fa_IR}>
        <SocketProvider>
            <Provider store={store}>
                <Router>
                    <Switch>
                        {Routes.map(item => {
                            return (
                                <Route key={item.name} path={item.path} component={item.component} />
                            )
                        })}
                    </Switch>
                </Router>
            </Provider>
        </SocketProvider>
    </ConfigProvider>
    ,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();