import { createStore, applyMiddleware, compose } from 'redux';
import reducer from './reducer';
import {responsiveStoreEnhancer} from "redux-responsive";
import {logger} from "redux-logger";

const middlewares = [logger];

const store = createStore(
    reducer,
    compose(
        responsiveStoreEnhancer,
        applyMiddleware(...middlewares)
    )
);

export default store;