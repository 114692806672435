import React, {Component, Fragment} from 'react';
import {cloneDeep} from "lodash";
import {Button, Col, Input, Modal, Row, Upload, Form, message, Select, Radio, Drawer} from "antd";
import {ExceptionOutlined, MailOutlined, LayoutOutlined, LoadingOutlined, UploadOutlined} from '@ant-design/icons';
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import API from '../scripts/api'
import { EditorState, convertToRaw, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {stateToHTML} from "draft-js-export-html";
import JoditEditor from "jodit-react";

class AddRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            picList: [],
            type: "panel",
            Data: '',
            showDrawer: true,
            DrawerData: "",
            editorState: EditorState.createEmpty(),
            editorStateShortDesc: EditorState.createEmpty(),
        }
        this.formRef = React.createRef();
    }

    handleSubmit = (data = {}) => {
        const formData = new FormData();

        formData.append('type', data.type);
        formData.append('name', data.name);
        formData.append('slug', data.slug);

        this.props.onSubmit(formData);
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();

        this.setState({
            addresses: [{id: 0}],
            phones: []
        });
        // window.location.reload()
    };


    handleDidMount = async (data = {}) => {
        await this.setState({editorState : "", DrawerData:""})
        const cloneData = await cloneDeep(data);
        await this.formRef.current.setFieldsValue(cloneData);
        await this.setState({editMode: !!cloneData.id, picList: []});
        if(this.state.editMode){
            await this.setState({type: cloneData.type});
        }

    }



    render() {
        const {submitting, visible} = this.props;
        const {picList, editMode, editorState, editorStateShortDesc, type} = this.state;
        const {TextArea} = Input;



        return (
            <Fragment>
                <Modal
                    width='80%'
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onCancel={this.props.onCancel}
                    footer={null}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} onFinish={(value) => this.handleSubmit(value)} layout="vertical">
                        <Row gutter={24} style={{marginBottom: '15px'}}>
                            <Col xs={24} md={12}>
                                <Form.Item name="name" label={Resources.title} rules={Rules.required}>
                                    <Input disabled={this.props.submitting} type="text" prefix={<ExceptionOutlined/>}
                                           placeholder={Resources.title}/>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="slug" label={Resources.slug} rules={Rules.required}>
                                    <Input disabled={this.props.submitting} type="text" prefix={<ExceptionOutlined/>}
                                           placeholder={Resources.slug}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="type" label={Resources.type} rules={Rules.required} initialValue= {type}>
                                    <Radio.Group>
                                        <Radio value="panel">{Resources.panel}</Radio>
                                        <Radio value="site">{Resources.site}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Form.Item>
                            <Button loading={this.props.submitting} style={{float: 'left'}} type="primary"
                                    htmlType="submit">
                                {Resources.confirm}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default AddRole;
