import React from 'react';
import {
    LaptopOutlined,
    SettingOutlined,
    UserOutlined,
    ReadOutlined,
    ProjectOutlined,
    FundProjectionScreenOutlined,
    IdcardOutlined,
    SoundOutlined,
    TeamOutlined,
    GroupOutlined,
    NotificationOutlined,
    WechatOutlined,
    MailOutlined,
    BugOutlined,
    UserAddOutlined,
    QrcodeOutlined,
    SketchOutlined,
    SlackOutlined,
    DollarOutlined,
    DropboxOutlined,
    RedditOutlined,
} from '@ant-design/icons';

import { Permissions } from '../scripts/settings';

// account
import AccountLayout from '../layouts/Account';
import LoginPage from '../pages/Login';

// errors
import NotFoundPage from '../pages/NotFound';
import UnAuthorizedPage from '../pages/UnAuthorized';

// app
import BasicLayout from '../layouts/Basic';
import DashboardPage from '../pages/Dashboard';
import Resources from './resources';
import UserManagement from "../pages/UserManagement";
import LimitAccess from "../pages/LimitAccess";
import DocumentManagement from "../pages/DocumentManagement";
import FacilityManagement from "../pages/FacilityManagement";
import HeatCoolManagement from "../pages/HeatCoolManagement";
import FloorCoverManagement from "../pages/FloorCoverManagement";
import HouseViewManagement from "../pages/HouseViewManagement";
import CupboardManagement from "../pages/CupboardManagement";
import DocumentTypeManagement from "../pages/DocumentTypeManagement";
import PositionLandManagement from "../pages/PositionLandManagement";
import JobCatManagement from "../pages/JobCatManagement";
import JobTypeManagement from "../pages/JobTypeManagement";
import JobRankManagement from "../pages/JobRankManagement";
import CountryManagement from "../pages/CountryManagement";
import StatesManagement from "../pages/StatesManagement";
import CityManagement from "../pages/CityManagement";
import StreetManagement from "../pages/StreetManagement";
import LandTypeManagement from "../pages/LandTypeManagement";
import DealTypeManagement from "../pages/DealTypeManagement";
import RoomFacilityManagement from "../pages/RoomFacilityManagement";
import RealStateManagement from "../pages/RealStateManagement";
import ExpertHousingManagement from "../pages/ExpertHousingManagement";
import PriceCurrencyManagement from "../pages/PriceCurrencyManagement";
import AreaUnitManagement from "../pages/AreaUnitManagement";
import BuildingStepManagement from "../pages/BuildingStepManagement";
import ArticleCatManagement from "../pages/ArticleCatManagement";
import ArticleManagement from "../pages/ArticleManagement";
import ConnectUsManagement from "../pages/ConnectUsManagement";
import SocialNetwork from "../pages/SocialNetwork";
import ContactUsManagement from "../pages/ContactUsManagement";
import OfferHousingManagement from "../pages/OfferHousingManagement";
import requestHousingManagement from "../pages/RequestHousingManagement";
import JobOfferManagement from "../pages/JobOfferManagement";
import JobRequestManagement from "../pages/JobRequestManagement";
import SliderManagement from "../pages/SliderManagement";
import CounterItemManagement from "../pages/CounterItemManagement";
import AboutUsManagement from "../pages/AboutUsManagement";
import TermsManagement from "../pages/TermsManagement";
import EmployerManagement from "../pages/EmployerManagement";
import BannerManagement from "../pages/BannerManagement";
import FormBuilderAdverOffer from "../pages/FormBuilderAdverOffer";
import FormBuilderAdverOfferIsOwner from "../pages/FormBuilderAdverOfferIsOwner";
import FormBuilderRequest from "../pages/FormBuilderAdverRequest";
import FormBuilderSearchAdverOffer from "../pages/FormBuilderSearchAdverOffer";
import FormBuilderAdverOfferFieldLogo from "../pages/FormBuilderAdverOfferFieldLogo";
import AgentManagement from "../pages/AgentManagement";
import AgentToAdvertiseManagement from "../pages/AgentToAdvertiseManagement";
import News from "../pages/News";
import UploaderManagement from "../pages/UploaderManagement";
import SubNeighborHoodManagement from "../pages/SubNeighborHoodManagement";
import NewRequestHousingManagement from "../pages/NewRequestHousingManagement";
import RoleManagement from "../pages/RoleManagement";
import FBItemManagement from "../pages/FBItemManagement";
import ChatManagement from "../pages/ChatManagement";
import FBItemWebServiceManagement from "../pages/FBItemWebServiceManagement";
import FBItemWebServiceDataManagement from "../pages/FBItemWebServiceDataManagement";
import {Slider} from "antd";


export default [
    // errors
    {
        path: '/403',
        name: 'unauthorized',
        component: UnAuthorizedPage
    },
    {
        path: '/404',
        name: 'notfound',
        component: NotFoundPage
    },
    {
        path: '/403',
        name: 'LimitAccess',
        component: LimitAccess
    },
    // account
    {
        path: '/account',
        name: 'account',
        component: AccountLayout,
        routes: [
            {
                path: '/account',
                redirect: '/account/login'
            },
            {
                path: '/account/logout',
                redirect: '/account/login'
            },
            {
                path: '/account/login',
                name: 'login',
                title: Resources.login,
                component: LoginPage
            }
        ]
    },
    // app
    {
        path: '/',
        name: 'app',
        component: BasicLayout,
        routes: [
            {
            name: 'dashboardRedirect',
            path: '/',
            redirect: '/dashboard',
            hidden: true,
            authority: ["admin","podcast","teacher", "consultant"]
        },
            {
            name: 'dashboard',
            icon: <LaptopOutlined />,
            path: '/dashboard',
            title: Resources.dashboard,
            component: DashboardPage,
            authority: ["admin","podcast","teacher", "consultant"]
        },

            {
                name: 'panel_management',
                icon: <SettingOutlined/>,
                title: Resources.panelManagement,
                authority: ["admin"],
                childs: [
                    {
                        name: 'user-management',
                        // icon: <UserOutlined />,
                        path: '/user-management',
                        title: Resources.userManagement,
                        component: UserManagement,
                        authority: ["admin"]
                    },
                    {
                        name: 'role-management',
                        // icon: <UserOutlined />,
                        path: '/role-management',
                        title: Resources.roles,
                        component: RoleManagement,
                        authority: ["admin"]
                    },
                    {
                        name: "agent",
                        path: "/agent",
                        title: Resources.agent,
                        component: AgentManagement,
                    },
                    {
                        name: "agentToAdvertise",
                        path: "/agentToAdvertise",
                        title: Resources.addAgentToAdvertise,
                        component: AgentToAdvertiseManagement,
                    },
                    {
                        name: "country",
                        // icon: <ProjectOutlined />,
                        path: "/country",
                        title: Resources.countryManagement,
                        component: CountryManagement,
                    },
                    {
                        name: "countryStates",
                        // icon: <ProjectOutlined />,
                        path: "/country/states",
                        title: Resources.statesManagement,
                        component: StatesManagement,
                        hidden: true
                    },{
                        name: "countryStateCites",
                        // icon: <ProjectOutlined />,
                        path: "/country/state/cities",
                        title: Resources.cityManagement,
                        component: CityManagement,
                        hidden: true
                    },{
                        name: "countryStateCiteStreet",
                        // icon: <ProjectOutlined />,
                        path: "/country/state/city/streets",
                        title: Resources.streetManagement,
                        component: StreetManagement,
                        hidden: true
                    }
                    ,{
                        name: "subNeighborhood",
                        // icon: <ProjectOutlined />,
                        path: "/subNeighborhood",
                        title: Resources.subNeighborhood,
                        component: SubNeighborHoodManagement,
                        hidden: true
                    },
                    {
                        name: "counterItem",
                        // icon: <ProjectOutlined />,
                        path: "/counterItem",
                        title: Resources.counterItem,
                        component: CounterItemManagement,
                    },
                    {
                        name: "terms",
                        // icon: <ProjectOutlined />,
                        path: "/terms",
                        title: Resources.terms,
                        component: TermsManagement,
                    },
                ]
            },
            {
                name: 'property_management',
                icon: <SettingOutlined/>,
                title: Resources.propertyManagement,
                authority: ["admin"],
                childs: [
                    {
                        name: "FBItems",
                        // icon: <ProjectOutlined/>,
                        path: "/FBItems",
                        title: Resources.FBItems,
                        component: FBItemManagement,
                    },
                    {
                        name: "FBItemWebService",
                        // icon: <ProjectOutlined/>,
                        path: "/FBItemWebService",
                        title: Resources.formBuilderItemDataWebService,
                        component: FBItemWebServiceManagement,
                    },
                    {
                        name: "FBItemWebServiceData",
                        // icon: <ProjectOutlined />,
                        path: "/FBItemWebService/data",
                        title: Resources.formBuilderItemData,
                        component: FBItemWebServiceDataManagement,
                        hidden: true
                    },
                    {
                        name: "formBuilder",
                        // icon: <ProjectOutlined/>,
                        path: "/formBuilder",
                        title: Resources.advertiseOfferFormBuilder,
                        component: FormBuilderAdverOffer,
                    },
                    {
                        name: "formBuilderRequest",
                        // icon: <ProjectOutlined/>,
                        path: "/formBuilderRequest",
                        title: Resources.advertiseRequestFormBuilder,
                        component: FormBuilderRequest,
                    },
                    {
                        name: "offerHousing",
                        // icon: <ProjectOutlined/>,
                        path: "/offerHousing",
                        title: Resources.offerHousing,
                        component: OfferHousingManagement,
                    },
                    {
                        name: "requestHousing",
                        // icon: <ProjectOutlined/>,
                        path: "/requestHousing",
                        title: Resources.requestHousing,
                        component: requestHousingManagement,
                    },
                    {
                        name: "newRequestHousing",
                        // icon: <ProjectOutlined/>,
                        path: "/newRequestHousing",
                        title: Resources.newOfferHousing,
                        component: NewRequestHousingManagement,
                        hidden: true
                    },
                    {
                        name: 'list_management',
                        icon: <SettingOutlined/>,
                        title: Resources.listManagement,
                        // path: "/list_management",
                        authority: ["admin","teacher"],
                        childs: [
                            {
                                name: "dealType",
                                // icon: <ProjectOutlined />,
                                path: "/dealType",
                                title: Resources.dealTypeManagement,
                                component: DealTypeManagement,
                            },
                            {
                                name: "landType",
                                // icon: <ProjectOutlined />,
                                path: "/dealType/landType",
                                title: Resources.landTypeManagement,
                                component: LandTypeManagement,
                                hidden: true
                            },

                            // {
                            //     name: "facility",
                            //     // icon: <ProjectOutlined />,
                            //     path: "/facility",
                            //     title: Resources.facilityManagement,
                            //     component: FacilityManagement,
                            // },
                            // {
                            //     name: "priceCurrency",
                            //     // icon: <ProjectOutlined />,
                            //     path: "/priceCurrency",
                            //     title: Resources.priceCurrencyManagement,
                            //     component: PriceCurrencyManagement,
                            // },
                            // {
                            //     name: "document",
                            //     // icon: <ProjectOutlined />,
                            //     path: "/document",
                            //     title: Resources.documentsManagement,
                            //     component: DocumentManagement,
                            // },
                            // {
                            //     name: "positionLand",
                            //     // icon: <ProjectOutlined />,
                            //     path: "/positionLand",
                            //     title: Resources.positionLandManagement,
                            //     component: PositionLandManagement,
                            // },
                            // {
                            //     name: "buildingStep",
                            //     // icon: <ProjectOutlined />,
                            //     path: "/buildingStep",
                            //     title: Resources.buildingStepManagement,
                            //     component: BuildingStepManagement,
                            // },
                            // {
                            //     name: "heatCool",
                            //     // icon: <ProjectOutlined />,
                            //     path: "/heatCool",
                            //     title: Resources.heatCoolManagement,
                            //     component: HeatCoolManagement,
                            // },
                            // {
                            //     name: "floorCover",
                            //     // icon: <ProjectOutlined />,
                            //     path: "/floorCover",
                            //     title: Resources.floorCoverManagement,
                            //     component: FloorCoverManagement,
                            // },
                            // {
                            //     name: "houseView",
                            //     // icon: <ProjectOutlined />,
                            //     path: "/houseView",
                            //     title: Resources.houseViewManagement,
                            //     component: HouseViewManagement,
                            // },
                            // {
                            //     name: "cupboard",
                            //     // icon: <ProjectOutlined />,
                            //     path: "/cupboard",
                            //     title: Resources.cupboardManagement,
                            //     component: CupboardManagement,
                            // }
                        ]
                    }
                ]
            },


            {
                name: 'articles_management',
                icon: <SettingOutlined/>,
                title: Resources.articlesManagement,
                authority: ["admin"],
                childs: [
                    {
                        name: "news",
                        // icon: <ProjectOutlined />,
                        path: "/news",
                        title: Resources.news,
                        component: News,
                    },

                    {
                        name: "slider",
                        // icon: <ProjectOutlined />,
                        path: "/slider",
                        title: Resources.slider,
                        component: SliderManagement,
                    },
                    {
                        name: "banner",
                        // icon: <ProjectOutlined />,
                        path: "/banner",
                        title: Resources.banner,
                        component: BannerManagement,
                    },
                    {
                        name: "uploader",
                        // icon: <ProjectOutlined />,
                        path: "/uploader",
                        title: Resources.uploader,
                        component: UploaderManagement,
                    },
                ]
            },

            {
                name: 'about_us_management',
                icon: <SettingOutlined/>,
                title: Resources.aboutUsManagement,
                authority: ["admin"],
                childs: [
                    {
                        name: 'connectUs',
                        // icon: <ProjectOutlined />,
                        path: '/connectUs',
                        title: Resources.connectUs,
                        component: ConnectUsManagement,
                        authority: ["admin"],
                    }, {
                        name: 'socialNetwork',
                        // icon: <ProjectOutlined />,
                        path: '/socialNetwork',
                        title: Resources.socialNetworkManagement,
                        component: SocialNetwork,
                        authority: ["admin"],
                    },
                ]
            },

            {
                name: "chat",
                icon: <ProjectOutlined />,
                path: "/chat",
                title: Resources.chatManagement,
                component: ChatManagement,
            },

            // {
            //     name: 'offer_housing_form_builder',
            //     icon: <SettingOutlined/>,
            //     title: Resources.advertiseOfferFormBuilder,
            //     authority: ["admin"],
            //     childs: [
            //
            //
            //
            //         // {
            //         //     name: "formBuilderIsOwner",
            //         //     icon: <ProjectOutlined/>,
            //         //     path: "/formBuilderIsOwner",
            //         //     title: Resources.isOwnerFormBuilder,
            //         //     component: FormBuilderAdverOfferIsOwner,
            //         // },
            //         // {
            //         //     name: "adverOfferFieldLogo",
            //         //     icon: <ProjectOutlined/>,
            //         //     path: "/adverOfferFieldLogo",
            //         //     title: Resources.AdverOfferFieldLogo,
            //         //     component: FormBuilderAdverOfferFieldLogo,
            //         // }
            //
            //     ]
            // },
            // {
            //     name: 'search_form_builder',
            //     icon: <SettingOutlined/>,
            //     title: Resources.searchFormBuilder,
            //     authority: ["admin"],
            //     childs: [
            //         {
            //             name: "search_formBuilder",
            //             icon: <ProjectOutlined/>,
            //             path: "/search/formBuilder",
            //             title: Resources.offerHousing,
            //             component: FormBuilderSearchAdverOffer,
            //         },
            //
            //
            //     ]
            // },




        //     {
        //     name: 'articleCat',
        //     icon: <TeamOutlined />,
        //     path: '/articleCat',
        //     title: Resources.articleCatManagement,
        //     component: ArticleCatManagement,
        //     authority: ["admin"]
        // }, {
        //     name: 'article',
        //     icon: <ProjectOutlined />,
        //     path: '/articleCat/article',
        //     title: Resources.articleManagement,
        //     component: ArticleManagement,
        //     authority: ["admin"],
        //     hidden: true
        // },
        //     {
        //         name: 'contactUs',
        //         icon: <ProjectOutlined />,
        //         path: '/contactUs',
        //         title: Resources.contactInfo,
        //         component: ContactUsManagement,
        //         authority: ["admin"],
        //     },



            // {
            //     name: 'land',
            //     icon: <SettingOutlined/>,
            //     title: Resources.landManagement,
            //     authority: ["admin"],
            //     childs: [
            //
            //
            //     ]
            // },

            // {
            //     name: 'job',
            //     icon: <SettingOutlined/>,
            //     title: Resources.jobManagement,
            //     authority: ["admin"],
            //     childs: [
            //         {
            //             name: "jobOffer",
            //             icon: <ProjectOutlined/>,
            //             path: "/jobOffer",
            //             title: Resources.jobOffer,
            //             component: JobOfferManagement,
            //         },
            //         {
            //             name: "jobRequest",
            //             icon: <ProjectOutlined/>,
            //             path: "/jobRequest",
            //             title: Resources.jobRequest,
            //             component: JobRequestManagement,
            //         }
            //         ]
            // },



    // {
    //         name: 'BaseData',
    //         icon: <SettingOutlined />,
    //         title: Resources.baseData,
    //         authority: ["admin"],
    //         childs : [
    //
    //            {
    //                 name: "documentType",
    //                 icon: <ProjectOutlined />,
    //                 path: "/documentType",
    //                 title: Resources.documentTypeManagement,
    //                 component: DocumentTypeManagement,
    //             },
    //             {
    //                 name: "facility",
    //                 icon: <ProjectOutlined />,
    //                 path: "/landType/facility",
    //                 title: Resources.facilityManagement,
    //                 component: FacilityManagement,
    //                 hidden: true
    //             },,{
    //                 name: "jobCat",
    //                 icon: <ProjectOutlined />,
    //                 path: "/jobCat",
    //                 title: Resources.jobCatManagement,
    //                 component: JobCatManagement,
    //             },{
    //                 name: "jobType",
    //                 icon: <ProjectOutlined />,
    //                 path: "/jobCat/jobType",
    //                 title: Resources.jobTypeManagement,
    //                 component: JobTypeManagement,
    //                 hidden: true
    //             },{
    //                 name: "jobRank",
    //                 icon: <ProjectOutlined />,
    //                 path: "/jobRank",
    //                 title: Resources.jobRankManagement,
    //                 component: JobRankManagement,
    //             },
    //
    //             {
    //                 name: "roomFacility",
    //                 icon: <ProjectOutlined />,
    //                 path: "/roomFacility",
    //                 title: Resources.roomFacilityManagement,
    //                 component: RoomFacilityManagement,
    //             },{
    //                 name: "realStateRole",
    //                 icon: <ProjectOutlined />,
    //                 path: "/realStateRole",
    //                 title: Resources.realStateRoleManagement,
    //                 component: RealStateManagement,
    //             },{
    //                 name: "expertHousing",
    //                 icon: <ProjectOutlined />,
    //                 path: "/expertHousing",
    //                 title: Resources.expertHousingManagement,
    //                 component: ExpertHousingManagement,
    //             },
    //             {
    //                 name: "areaUnit",
    //                 icon: <ProjectOutlined />,
    //                 path: "/areaUnit",
    //                 title: Resources.areaUnitManagement,
    //                 component: AreaUnitManagement,
    //             },
    //
    //
    //
    //
    //             {
    //                 name: "aboutUs",
    //                 icon: <ProjectOutlined />,
    //                 path: "/aboutUs",
    //                 title: Resources.aboutUs,
    //                 component: AboutUsManagement,
    //             },
    //
    //             {
    //                 name: "employer",
    //                 icon: <ProjectOutlined />,
    //                 path: "/employer",
    //                 title: Resources.employer,
    //                 component: EmployerManagement,
    //             },
    //
    //         ]
    //
    //     }
        ]
    }
];
