import React, {Component} from 'react';
import {Button, Layout, Result} from "antd";
import Resources from "../scripts/resources";
import {Link} from "react-router-dom";

class LimitAccess extends Component {
    render() {
        return (
            <Layout>
                <Result
                    status="403"
                    title={Resources.LimitAccess}
                    subTitle={Resources.LimitAccess}
                    extra={<Link to="/"><Button type="primary">{Resources.backHome}</Button></Link>}
                />
            </Layout>
        );
    }
}

export default LimitAccess;