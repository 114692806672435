import React, {Component, Fragment} from 'react';
// import {Map, TileLayer, Marker, withLeaflet} from 'react-leaflet';
// import {ReactLeafletSearch} from "react-leaflet-search";
import {cloneDeep} from 'lodash';
import {Button, Col, Form, Input, Modal, Row, Select, List, Upload} from 'antd';
import API from "../scripts/api";
import {
    CalendarOutlined,
    MailOutlined,
    MobileOutlined,
    UserOutlined,
    InboxOutlined
} from '@ant-design/icons';
import Resources from '../scripts/resources';
import Rules from '../scripts/rules';
import JoditEditor from "jodit-react";
import ModalForm from "../components/Modal";


class AdvertiseHousing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileList: [],
            title: '',
            userRoles: '',
            selectedRoles: null,
            addresses: [{id: 0}],
            phones: [],
            selectedAddress: 0,
            visibleMap: false
        };

        this.formRef = React.createRef();
        this.mapRef = React.createRef();
    }

    handleChangeDate = (date, obj) => {
        const state = {};
        state[obj] = date;
        this.formRef.current.setFieldsValue(state);
    };

    handleSubmit = () => {
        this.formRef.current.validateFields()
            .then(values => {
                const formData = new FormData();

                if (values.avatar && values.avatar.fileList && values.avatar.fileList.length)
                    formData.append('avatar', values.avatar.file, values.avatar.file.name);
                formData.append('name', values.name);
                formData.append('mobile', values.mobile);
                formData.append('email', values.email ? values.email : '');
                // formData.append('cv', values.cv ? values.cv : '');

                this.props.onSubmit(formData, values.roles);
            });
    };

    handleWillUnmount = () => {
        // this.formRef.current.resetFields();
    };

    handleDidMount = (data = {}) => {
        const cloneData = cloneDeep(data);

        API.acl.rules()
            .then(res => {
                this.setState({
                    userRoles: res
                });
                return res
            })
            .then((res)=>{
                let arr=[];
                res.map(item=> arr.push(item.id));
                this.setState({
                    selectedRoles: arr
                });
            });

        this.formRef.current.setFieldsValue(cloneData);
        this.setState({editMode: !!cloneData.id});
    };


    render() {
        const {onCancel, submitting, visible} = this.props;
        const {addresses, editMode, phones, visibleMap, fileList} = this.state;

        const props = {
            onRemove: file => {
                this.setState({ fileList: [] });
            },
            beforeUpload: file => {
                this.setState({ fileList: [file] });
                return false;
            },
            showUploadList: {
                showRemoveIcon: editMode
            },
            fileList
        };


        const config = {
            readonly: false,
            height: 50,
            toolbarButtonSize: "xsmall",
            placeholder: "رزومه خود را ثبت کنید"
        }

        return (
            <Fragment>
                <ModalForm
                    title={Resources.details}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} layout="vertical">
                        <Row gutter={24}>
                            <Col xs={24} md={8}>
                                <Form.Item name="user" label={Resources.user}>
                                    <Input  type="text"  placeholder={Resources.user}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="user_mobile" label={Resources.mobile} >
                                    <Input  type="text" dir="ltr"
                                           />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={8}>
                                <Form.Item name="title" label={Resources.title} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="type_land" label={Resources.landType} >
                                    <Input  type="text" dir="ltr"
                                            />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="deal_type" label={Resources.dealType} >
                                    <Input  type="text" dir="ltr"
                                            />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="country" label={Resources.country} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="state" label={Resources.state} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="city" label={Resources.city} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="neighborhood" label={Resources.neighborhood} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="phone" label={Resources.phone} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="mobile" label={Resources.mobile} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="owner" label={Resources.owner} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="street" label={Resources.street} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="area" label={Resources.area} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="commercial_area" label={Resources.commercial_area} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="land_area" label={Resources.land_area} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="area_unit" label={Resources.area_unit} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="age" label={Resources.age} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="price" label={Resources.price} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="mortgage_price" label={Resources.mortgage_price} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="monthly_rent_price" label={Resources.monthly_rent_price} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="price_currency" label={Resources.price_currency} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="mortgage_price_convert" label={Resources.mortgage_price_convert} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="RequireExpert" label={Resources.RequireExpert} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="loan" label={Resources.loan} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="bed_rooms_count" label={Resources.bed_rooms_count} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="floors_count" label={Resources.floors_count} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="floor" label={Resources.floor} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="rooms_count" label={Resources.rooms_count} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={8}>
                                <Form.Item name="bed_rooms_count" label={Resources.bed_rooms_count} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={8}>
                                <Form.Item name="total_units" label={Resources.total_units} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="one_bed_rooms_count" label={Resources.one_bed_rooms_count} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="two_bed_rooms_count" label={Resources.two_bed_rooms_count} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={8}>
                                <Form.Item name="three_bed_rooms_count" label={Resources.three_bed_rooms_count} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={8}>
                                <Form.Item name="suite_count" label={Resources.suite_count} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={8}>
                                <Form.Item name="unit_type" label={Resources.unit_type} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={8}>
                                <Form.Item name="position" label={Resources.position} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="house_view" label={Resources.house_view} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={8}>
                                <Form.Item name="floor_cover" label={Resources.floor_cover} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="cupboard" label={Resources.cupboard} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="document" label={Resources.document} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="dang_document" label={Resources.dang_document} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="building_step" label={Resources.building_step} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="delivery_year" label={Resources.delivery_year} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="delivery_month" label={Resources.delivery_month} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item name="description" label={Resources.description} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item name="exchange" label={Resources.exchange} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="commercial_license" label={Resources.commercial_license} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="density" label={Resources.density} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="building_permit" label={Resources.building_permit} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="is_rent" label={Resources.is_rent} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="number_of_trees" label={Resources.number_of_trees} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="corrective_area" label={Resources.corrective_area} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="suitable_for" label={Resources.suitable_for} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="length" label={Resources.length} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="height" label={Resources.height} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="width_land" label={Resources.width_land} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="width_street" label={Resources.width_street} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="wall_cover" label={Resources.wall_cover} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item name="minDaysOpportunity" label={Resources.minDaysOpportunity} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={8}>
                                <Form.Item name="maxDaysOpportunity" label={Resources.maxDaysOpportunity} >
                                    <Input  type="text" dir="ltr"
                                    />
                                </Form.Item>
                            </Col>


                            <Col xs={24} md={24}>
                                <Form.Item name="delete_reason" label={Resources.delete_reason} >
                                    <Input.TextArea rows={6} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={24}>
                                <Form.Item name="reject_reason" label={Resources.reject_reason} >
                                    <Input.TextArea rows={6} />
                                </Form.Item>
                            </Col>


                        </Row>
                    </Form>
                </ModalForm>
            </Fragment>
        );
    }
};

export default AdvertiseHousing;
