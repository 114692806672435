import React, {Component, Fragment} from 'react';
import {
    Breadcrumb,
    Button,
    Col,
    Input,
    Row,
    Table,
    Tooltip,
    Checkbox,
    message,
    Dropdown,
    Menu,
    Switch,
    Modal,
    Pagination, Avatar,
} from "antd";
import { NavLink } from "react-router-dom";
import Resources from "../scripts/resources";
import {
    PlusOutlined, SearchOutlined, EyeOutlined, DingtalkOutlined, FormOutlined, FileSearchOutlined, DeleteOutlined
    , EditOutlined, DollarOutlined, LockOutlined, MenuOutlined, ReadOutlined
} from '@ant-design/icons'
import {Constants, Permissions} from "../scripts/settings";
import { debounce } from 'lodash';
import AddCity from "../forms/AddCity";
import API from "../scripts/api";
import {connect} from "react-redux";
import {CheckPermission, handleAclData, PriceFormat} from "../scripts/helpers";
import {SetRoute} from "../scripts/action";
import PermissionForm from "../forms/Permission";
import {Link} from "react-router-dom";

class CityManagement extends Component {
    constructor() {
        super();

        this.state = {
            state: '',
            Filter: '',
            data: [],
            aclData: '',
            filter: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: Constants.pageSize,
                size: 'small',
            },
            visibleDeleteForm: false,
            selected: {},
            userRoles: '',
            search:[]
        };

        this.formRef = React.createRef();
        this.mediaRef = React.createRef();
        this.creditFormRef = React.createRef();
        this.permissionFormRef = React.createRef();

        // this.handleFilter = debounce(this.handleFilter, 1000);
    }

    fetch = (params = {}) => {
        const { filterLevel, filterSearch } = this.state;

        params.q = filterSearch;
        params.level = filterLevel;
        params.state_id = this.props.history.location.state.id;

        this.setState({ loading: true });
        API.city.list(params)
            .then((result) => {
                if (result.data) {

                    this.setState({ loading: true });

                    setTimeout(() => {
                        //demoData
                        // const result = result.data;
                        const pagination = this.state.pagination;
                        pagination.total = result.data.length;
                        this.setState({
                            loading: false,
                            data: result.data,
                            search: result.data,
                            pagination,
                        });
                    }, 1500);
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    componentDidMount() {

        const { onSetRoute, routeName, parentName } = this.props;
        onSetRoute({ openKey: parentName, selectedKey: routeName });
        console.log("this.props.history", this.props.history.location.state)
        const state = this.props.history.location.state;
        this.setState({
            state: state
        })
        this.fetch({ page: 1 });

    }

    handlePermissions = (values) => {
        this.setState({ submitting: true });
        API.acl.save(values, this.state.selected.id)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hidePermission();
                this.fetch({ page: this.state.pagination.current });
            })
    };

    showPermission = (book = {}) => {
        this.setState({ visiblePermission: true, selected: book });

        // setTimeout(() => {
        //     this.permissionFormRef.current.handleDidMount(book);
        // }, 0);
    };

    hidePermission = () => {
        this.setState({ visiblePermission: false });

        // setTimeout(() => {
        //     this.permissionFormRef.current.handleWillUnmount();
        // }, 0);
    };

    showForm = (book = {}) => {
        this.setState({ visibleForm: true, selected: book });

        setTimeout(() => {
            this.formRef.current.handleDidMount(book);
        }, 0);
    };

    handleFilter = (value, search) => {
        const pagination = this.state.pagination
        pagination.current = 1
        if(value){
            let data = this.state.search.filter(item => {
                return (item.title.includes(value)  ) ;
            });

            pagination.total = data.length
            this.setState({
                data: data,
                loading: false,
                pagination,
            });
        }else{
            this.setState({data: this.state.search});
            pagination.total = this.state.search.length
            this.setState({
                data: this.state.search,
                loading: false,
                pagination,
            });
        }
    };

    hideForm = () => {
        this.setState({ visibleForm: false });

        setTimeout(() => {
            this.formRef.current.handleWillUnmount();
        }, 0);
    }

    handleSubmit = (values) => {
        values.append('state_id', this.state.state.id);
        this.setState({ submitting: true });
        const action = this.state.selected.id ? API.city.update : API.city.add;
        if (action === API.city.update) {
            values.id = this.state.selected.id;
        }

        action(values)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideForm();
                this.fetch({ page: this.state.pagination.current });
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };

    handleTableChange = (pagination, filters, sorter) => {

        console.log('tableChange', pagination, filters, sorter);
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });

    }

    showDeleteForm = (book = {}) => {
        this.setState({ visibleDeleteForm: true, selected: book });
        setTimeout(() => {
            this.mediaRef.current.handleDidMount(book);
        }, 0);
    };

    hideDeleteForm = () => {
        this.setState({ visibleDeleteForm: false });

        setTimeout(() => {
            this.formRef.current.handleWillUnmount();
        }, 0);
    };

    handleDelete = (record) => {
        Modal.confirm({
            title: Resources.confirmDelete,
            okText: Resources.yes,
            okType: 'danger',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({loading : true});
                API.city.delete(record.id)
                    .then(res => {
                        message.success(Resources.successful);
                        this.fetch()
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };
    handleDeleteSubmit = (id) => {
        this.setState({ submitting: true });

        API.gallery.delete(id)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideDeleteForm();
                this.fetch({ page: this.state.pagination.current });
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };

    handleStreets = (record) => {
        const {history} = this.props;
        // const {history} = this.props;
        history.push('/country/state/city/streets', record);
    }
    handleFilter = (value) => {
        this.setState({ Filter: value });
    }
    render() {
        const{ visibleForm, submitting, data, loading, pagination, visibleDeleteForm, Filter } = this.state;
        const { permissions } = this.props;

        const columns = [{
            title: Resources.title,
            dataIndex: 'name',
            className: 'col-center',
            width: '120px'
        },
            {
                title: Resources.latitude,
                dataIndex: 'lat',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.longitude,
                dataIndex: 'lng',
                className: 'col-center',
                width: '120px'
            }
        ];

        if (permissions.action) {
            columns.push({
                title: Resources.action,
                dataIndex: 'id',
                className: 'col-center',
                width: '80px',
                render: (val, record) => (
                    <Dropdown overlay={<Menu>
                        <Menu.Item>
                            <span onClick={() => this.showForm(record)}><EditOutlined /> {Resources.edit}</span>
                        </Menu.Item>
                        <Menu.Item>
                            <span onClick={() => this.handleDelete(record)}><DeleteOutlined /> {Resources.delete}</span>
                        </Menu.Item>
                        <Menu.Item>
                            <span onClick={() => this.handleStreets(record)}><ReadOutlined /> {Resources.streetManagement}</span>
                        </Menu.Item>
                    </Menu>}>
                        <Button type="link" icon={<MenuOutlined />} />
                    </Dropdown>
                )
            });
        }
        const SupportData = data.filter(o => {
            let query = false;

            if (Filter) {
                if (o.name && o.name.indexOf(Filter) >= 0)
                    query = true;

            } else {
                query = true;
            }
            return query;
        });
        return (
            <Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item>{Resources.cityManagement}</Breadcrumb.Item>
                </Breadcrumb>
                <div className='ant-content'>
                    <Row gutter={24}>
                        <Col xs={24} md={4}>
                            <Button type="primary" icon={<PlusOutlined />} onClick={() => this.showForm()} block>{Resources.add}</Button>
                        </Col>
                        <Col xs={24} md={14} style={{ paddingTop: 8 }}>
                            <Checkbox.Group onChange={value => console.log(value)} />
                        </Col>
                        <Col xs={24} md={6}>
                            <Tooltip title={Resources.steftaatSearch}>
                                <Input
                                    type="text"
                                    prefix={<SearchOutlined />}
                                    placeholder={Resources.search}
                                    onChange={({ target: { value } }) => this.handleFilter(value, 'filterSearch')}
                                    allowClear
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                </div>
                <div className="ant-content">
                    <Table
                        columns={columns}
                        dataSource={SupportData}
                        loading={loading}
                        onChange={this.handleTableChange}
                        pagination={pagination}
                        size="small"
                        rowKey="id"
                        // scroll={{ x: 900 }}
                    />
                    {/*{!!data.length && <Pagination onChange={this.handleChange1} current={this.state.current} pageSize={this.state.pagination.pageSize} total={data.length} />}*/}
                </div>
                {/*<PermissionForm selected={this.state.selected} permisionData={aclData} onCancel={this.hidePermission} onSubmit={this.handlePermissions} submitting={submitting} visible={visiblePermission} />*/}

                <AddCity ref={this.formRef} onCancel={this.hideForm} onSubmit={this.handleSubmit} submitting={submitting} visible={visibleForm} />
                {/*<DeleteImageBook ref={this.mediaRef} data={this.props.history.location.state} ifUpdate={this.state.selected.id} onCancel={this.hideDeleteForm} onSubmit={this.handleDeleteSubmit} onDelete={this.handleDeleteSubmit} visible={visibleDeleteForm} />*/}

            </Fragment>
        );
    }
}

export default connect(
    (state) => {
        return {
            permissions: {
                action: CheckPermission(state.app.user, Permissions.user_option),
                add: CheckPermission(state.app.user, Permissions.user_store),
            }
        };
    },
    (dispatch) => {
        return {
            onSetRoute: (data) => dispatch(SetRoute(data))
        };
    }
)(CityManagement);
