import React, {Component, Fragment} from 'react';
// import {Map, TileLayer, Marker, withLeaflet} from 'react-leaflet';
// import {ReactLeafletSearch} from "react-leaflet-search";
import {cloneDeep} from 'lodash';
import {Button, Col, Form, Input, Modal, Row, Select, List, Upload} from 'antd';
import API from "../scripts/api";
import {
    CalendarOutlined,
    MailOutlined,
    MobileOutlined,
    UserOutlined,
    InboxOutlined
} from '@ant-design/icons';
import Resources from '../scripts/resources';
import Rules from '../scripts/rules';
import JoditEditor from "jodit-react";

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileList: [],
            title: '',
            userRoles: '',
            selectedRoles: null,
            addresses: [{id: 0}],
            phones: [],
            selectedAddress: 0,
            visibleMap: false
        };

        this.formRef = React.createRef();
        this.mapRef = React.createRef();
    }

    handleChangeDate = (date, obj) => {
        const state = {};
        state[obj] = date;
        this.formRef.current.setFieldsValue(state);
    };

    handleSubmit = () => {
        this.formRef.current.validateFields()
            .then(values => {
                const formData = new FormData();
                console.log(values)
                formData.append('mobile', values.mobile);
                formData.append('name', values.name);
                formData.append('email', values.email);
                if (values.avatar && values.avatar.fileList && values.avatar.fileList.length)
                    formData.append('avatar', values.avatar.file, values.avatar.file.name);
                formData.append('cv', values.cv);

                this.props.onSubmit(formData);
            });
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();
    };

    handleDidMount = (data = {}) => {
        const cloneData = cloneDeep(data);

        API.acl.rules()
            .then(res => {
                this.setState({
                    userRoles: res
                });
                return res
            })
            .then((res)=>{
                let arr=[];
                res.map(item=> arr.push(item.id));
                this.setState({
                    selectedRoles: arr
                });
            });

        this.formRef.current.setFieldsValue(cloneData);
        this.setState({editMode: !!cloneData.id});
    };


    render() {
        const {onCancel, submitting, visible} = this.props;
        const {addresses, editMode, phones, visibleMap, fileList} = this.state;

        const config = {
            readonly: false,
            height: 50,
            toolbarButtonSize: "xsmall",
            placeholder: "رزومه خود را ثبت کنید"
        }

        const props = {
            onRemove: file => {
                this.setState({ fileList: [] });
            },
            beforeUpload: file => {
                this.setState({ fileList: [file] });
                return false;
            },
            showUploadList: {
                showRemoveIcon: editMode
            },
            fileList
        };

        return (
            <Fragment>
                <Modal
                    title={Resources.profileEdit}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                    width={800}
                >
                    <Form ref={this.formRef} layout="vertical">
                        <Row gutter={24}>
                            <Col xs={24} md={12}>
                                <Form.Item name="avatar" label={Resources.image} rules={editMode ? [] : Rules.required}>
                                    <Upload.Dragger {...props}>
                                        <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                        <p className="ant-upload-hint">{Resources.uploadText}</p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="name" label={Resources.firstName}>
                                    <Input type="text" prefix={<UserOutlined/>} placeholder={Resources.nameHint}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="mobile" label={Resources.mobile} rules={Rules.required}>
                                    <Input type="text" dir="ltr" prefix={<MobileOutlined/>}
                                           placeholder={Resources.mobileHint}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="email" label={Resources.email} rules={Rules.email}>
                                    <Input type="text" dir="ltr" prefix={<MailOutlined/>}
                                           placeholder={Resources.emailHint}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item name='cv' label={Resources.cv}>
                                    <JoditEditor
                                        config={config}
                                        tabIndex={3} // tabIndex of textarea
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default Profile;