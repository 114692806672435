import React, {Component, Fragment} from 'react';
import {cloneDeep} from "lodash";
import {Col, Modal, Row, Input, Form, Upload, Select, Radio, Button} from "antd";
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import {ProjectOutlined, InboxOutlined, DeploymentUnitOutlined, ExceptionOutlined} from '@ant-design/icons';
import API from "../scripts/api";
import { Circle, Map, TileLayer, Marker } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from "leaflet";
class AddAgent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileList: [],

            countries: [],
            cities: [],
            neighborhoods: [],
            subNeighborhoods: [],
            visibleMap: "",
            countrySelected: "",
            citySelected: "",
            neighborhoodSelected: "",
            subNeighborhoodSelected: "",
            neighborhoodInfo: "",
            id: '',
            infoCounterArr: [],
            dealTypesList: [],
            infoCounter: '',
            formValues: [], // STATE HATO INJA BAYAD BEZARI
            managers: [],
            managerSelected: '',

        };

        this.formRef = React.createRef();
        this.mapRef = React.createRef();

    }



    handleSubmit = () => {
        let {infoCounterArr} = this.state
        this.formRef.current.validateFields()
            .then(values => {
                const formData = new FormData;
                formData.append('lat', this.state.lat);
                formData.append('lng', this.state.lng);
                formData.append('name', values.name);
                formData.append('phone', values.phone);
                formData.append('address', values.address);
                formData.append('neighborhood_id', values.neighborhood_id);
                formData.append('sub_neighborhood_id', (values.sub_neighborhood_id) ? values.sub_neighborhood_id : "");
                formData.append('manager', (values.manager) ? values.manager : "");
                formData.append('city_id', values.city_id);
                formData.append('code', values.code);
                if (values.image && values.image.fileList && values.image.fileList.length)
                    formData.append('logo', values.image.file, values.image.file.name);

                infoCounterArr.map((item) => {
                    Object.entries(values).map(([key, value]) => (
                        (key == `phone-${item.counter}`) ? formData.append('phones[]', value) : "",
                        (key == `deal_type-${item.counter}`) ? formData.append('deal_type[]', value) : ""
                    ))
                })

                if (this.state.editMode){
                    formData.append("id", this.state.id);
                    this.props.onSubmit(formData, this.state.id);
                } else {
                    this.props.onSubmit(formData);
                }
            });
    };

    handleWillUnmount = () => {
        console.log("modal is dieeeeeeeeeeeeeeee")
        this.formRef.current.resetFields();
        this.map.remove()
   
    };

    handleDidMount = async (data = {}) => {
        await this.formRef.current.resetFields();

        const cloneData = await cloneDeep(data);
        console.log(!!cloneData.id);
        this.setState({infoCounter: 0, infoCounterArr:[]});
         this.setState({editMode: !!cloneData.id, id: cloneData.id});
        if(!!cloneData.id){
            this.formRef.current.setFieldsValue(cloneData);
            console.log("this is edit mode",  cloneData.country_id)
             await this.setState({id: cloneData.id});
             await this.setState({countrySelected: cloneData.country_id});
             await this.setState({citySelected: cloneData.city_id});
             await this.setState({neighborhoodSelected: cloneData.neighborhood_id});
             await this.setState({subNeighborhoodSelected: cloneData.sub_neighborhood_id, managerSelected: cloneData.manager});
            await this.getCountries()
            await this.getDealTypes()
            await this.getManagers()
            await this.getCities(cloneData.country_id)
            await this.getNeighborhoods(cloneData.city_id)
            await this.getSubNeighborhoods(cloneData.neighborhood_id)
            this.loadMap({lat:cloneData.lat, lng:cloneData.lng})
            cloneData.info.map((item) => {
                let { infoCounterArr} = this.state
                let counter = this.state.infoCounter + 1
                this.setState({infoCounter: counter });
                infoCounterArr.push({"phone": item.phone,  "counter": counter, "deal_type" : item.deal_type})
                this.setState({infoCounterArr: infoCounterArr });
            })
        }else {

            await this.loadMap({lat:36.36822190085111, lng:59.48693275451661})
            await this.getCountries()
            await this.getDealTypes()
            await this.getManagers()
        }
    }

    getCountries = (params = {}) => {
        this.setState({ loading: true });
        API.countries.list()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        countries: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getCities = (params = {}) => {
        this.setState({ loading: true });
        API.countryCities.list(params)

            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        cities: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getNeighborhoods = (params = {}) => {
        this.setState({ loading: true });
        API.street.list({city_id: params})

            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        neighborhoods: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getSubNeighborhoods = (params = {}) => {
        this.setState({ loading: true });
        API.subNeighborhood.list({neighborhood_id: params})
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        subNeighborhoods: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    getNeighborhoodInfo = (params = {}) => {
        this.setState({ loading: true });
        API.cityNeighbourhood.info(params)

            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        neighborhoodInfo: result.data[0],
                    });
                    this.map.flyTo([result.data[0].lat, result.data[0].lng], 15)
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    handleSubmit(event) {
        event.preventDefault();
        alert(JSON.stringify(this.state.formValues));
    }
    HandleSelect(param) {
        this.getNeighborhoodInfo(param)
    }

    loadMap(data){
        this.setState({ lat: data.lat, lng: data.lng });
        const firstmap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        })

         this.map = L.map('map', {
            center: [this.state.lat, this.state.lng],
            // tapTolerance: 25,
            tap: false,
            zoom: 13,
            maxZoom: 18,
            // zoomControl: false,
            layers: [firstmap],
        });
        var marker = L.marker([data.lat, data.lng]).addTo(this.map);
        const onMapClick = (e) => {
            // alert("You clicked the map at " + e.latlng);
            marker.setLatLng(e.latlng)
            this.setState({ lat: e.latlng.lat, lng: e.latlng.lng });
        }

        this.map.on('click', onMapClick);
    }


    addInfoItem = (param = {}) => {
        let {infoCounter, infoCounterArr} = this.state
        let counter = this.state.infoCounter + 1
        this.setState({infoCounter: counter });
        infoCounterArr.push({"phone": "", "counter": counter})
        this.setState({infoCounterArr: infoCounterArr });

    }
    deleteInfoItem = (param = {}) => {
        let {infoCounter, infoCounterArr} = this.state
        infoCounterArr.pop({"phone": param})
        this.setState({addressCounterArr:infoCounterArr });
    }

    getDealTypes = (params = {}) => {
        API.dealTypesListWithType.offerList()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        dealTypesList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    getManagers = (params = {}) => {
        API.user.list(params)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        managers: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    changeSelectHandler = (event) => {
        console.log(event)
        if(event){
            this.getManagers({mobile : event})
        }
   }

   HandleLandTypeChange = (e) => {
    console.log(e)
   }

    render() {
        const {onCancel, submitting, visible} = this.props;
        const { editMode,neighborhoods, neighborhoodSelected,
             countries, countrySelected, cities,fileList,infoCounterArr,
            citySelected, subNeighborhoods, subNeighborhoodSelected, dealTypesList, managerSelected, managers } = this.state; // INJA STATE HATO MIYARI TO RENDER INJA FARAKHONI MISHE AZ BALA

        const props = {
            onRemove: file => {
                this.setState({ fileList: [] });
            },
            beforeUpload: file => {
                this.setState({ fileList: [file] });
                return false;
            },
            showUploadList: {
                showRemoveIcon: editMode
            },
            fileList
        };
        const {TextArea} = Input;

        return (

            <Fragment>
                <Modal
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} layout="vertical" >
                        <Row gutter={24} >


                            <Col xs={24} md={12}>
                                <Form.Item name="country_id" label={Resources.country} initialValue= {countrySelected} >
                                    <Select onChange={(e) => this.getCities(e)}>
                                        {countries.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="city_id" label={Resources.city}  initialValue= {citySelected}>
                                    <Select  onChange={(e) => this.getNeighborhoods(e)}>
                                        {cities.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>


                            <Col xs={24} md={12}>
                                <Form.Item  name="neighborhood_id" label={Resources.neighborhood}  initialValue= {neighborhoodSelected}>
                                    <Select onChange={(e) => this.getSubNeighborhoods(e)} >
                                        {neighborhoods.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name} </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item  name="sub_neighborhood_id" label={Resources.subNeighborhood}  initialValue= {subNeighborhoodSelected}>
                                    <Select onChange={(e) => this.HandleSelect(e)} >
                                        {subNeighborhoods.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item  name="manager" label={Resources.managerSearch}  initialValue= {managerSelected}>
                                    <Select
                                        onSearch = {this.changeSelectHandler}
                                        showSearch
                                        filterOption={false}
                                        // allowCreate={true}
                                        // backspaceRemoves={true}
                                        // filterOption={(input, option) => {
                                        //     return (
                                        //         option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        //     );

                                        // }}
                                        // onChange={(e) => this.HandleLandTypeChange(e)}
                                    >
                                        {!!managers.length && managers.map(item => (
                                            <Select.Option title={item.name  +" "+ item.family}   style={{direction:"rtl"}} key={item.id}  value={item.id}>
                                                {item.name +" "+ item.family} ({item.showRole} )

                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="image" label={Resources.image} >
                                    <Upload.Dragger disabled={submitting} {...props} className="ant-upload-block">
                                        <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                        <p className="ant-upload-hint">{Resources.uploadText}</p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Col>
                            <Col xs={12} md={12}>
                                <Form.Item name="name" label={Resources.title} >
                                    <Input type="text"  />
                                </Form.Item>
                            </Col>

                            <Col xs={12} md={12}>
                                <Form.Item name="phone" label={Resources.phone} >
                                    <Input type="text"  />
                                </Form.Item>
                            </Col>

                            <Col xs={12} md={12}>
                                <Form.Item name="address" label={Resources.address} >
                                    <Input type="text"  />
                                </Form.Item>
                            </Col>
                            <Col xs={12} md={12}>
                                <Form.Item name="code" label={Resources.code} >
                                    <Input type="text"  />
                                </Form.Item>
                            </Col>

                        </Row>
                            {infoCounterArr &&
                            infoCounterArr.map(item =>
                                <Row gutter={24}>

                                    <Col xs={24} md={8}>
                                        <Form.Item initialValue={item.phone} name={`phone-${item.counter}`} label={Resources.phone} >
                                            <Input  type="text" dir="rtl"
                                                    placeholder={Resources.phone}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Form.Item initialValue={item.deal_type} name={`deal_type-${item.counter}`} label={Resources.dealType} >
                                            <Select mode="multiple">
                                                {dealTypesList.map(item => (
                                                    <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8} style={{display: 'flex',alignItems: 'center'}}>
                                        <Button style={{width: "100%"}} loading={submitting} type='danger' onClick={() => this.deleteInfoItem(item)} >
                                            {Resources.delete}
                                        </Button>
                                    </Col>
                                </Row>
                            )
                            }
                        <Row gutter={24}>
                            <Col xs={24} style={{display: 'flex',alignItems: 'center'}}>
                                <Button style={{width: "100%"}} loading={submitting} type='success' onClick={() => this.addInfoItem()} >
                                    {Resources.addInfo}
                                </Button>
                            </Col>

                            <Col xs={24} md={24}>
                                <div id="map" style={{width:"90%%", height:"400px"}}></div>
                            </Col>



                        </Row>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default AddAgent;
