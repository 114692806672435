import React, {Component, Fragment} from 'react';
import {cloneDeep} from "lodash";
import {Col, Modal, Row, Input, Form, Upload, Select, Radio} from "antd";
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import {ProjectOutlined, InboxOutlined, DeploymentUnitOutlined, ExceptionOutlined} from '@ant-design/icons';
import API from "../scripts/api";

class AddFormBuilderSearchAdverOffer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileList: [],
            dealTypesList: [],
            landTypeList: [],
            inputTypeList: [],
            sourceFieldList: [],
            existFieldsList: [],
            visibleMap: false,
            isOwner: false,
            search_type: false,
            dealTypeSelected: false,
            landTypeSelected: false,
            inputTypeSelected: false,
            fieldTypeSelected: false,
            existFieldSelected: false,
            is_owner: "",
            id: '',
            apiFormElements: [],
            formValues: [] // STATE HATO INJA BAYAD BEZARI
        };

        this.formRef = React.createRef();
        this.mapRef = React.createRef();

    }



    handleSubmit = () => {
        this.formRef.current.validateFields()
            .then(values => {
                this.props.onSubmit(values, this.state.id);
            });
        // this.formRef.current.resetFields();
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();
    };

    handleDidMount = (data = {}) => {
        const cloneData = cloneDeep(data);
        this.formRef.current.setFieldsValue(cloneData);
        console.log(!!cloneData.id);

        this.setState({editMode: !!cloneData.id, id: cloneData.id});
        if(this.state.editMode){
            console.log("cloneData", cloneData)
            this.setState({id: cloneData.id});
            this.setState({isOwner: cloneData.is_owner});
            this.setState({dealTypeSelected: cloneData.deal_type});
            this.setState({landTypeSelected: cloneData.land_type});
            this.setState({inputTypeSelected: cloneData.input_type});
            this.setState({fieldTypeSelected: cloneData.field_type});
            this.setState({search_type: cloneData.search_type});
        }
        this.getDealTypes()
        if(this.state.editMode){
            this.getLandType(this.state.dealTypeSelected)
            this.ReturnFieldsForm()
        }else{
            this.getExistFields()
        }
        this.getSourceFields()
    }

    getDealTypes = (params = {}) => {
        this.setState({ loading: true });
        API.dealTypesListWithType.offerList()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        dealTypesList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getLandType = (params = {}) => {
        this.setState({ loading: true });
        let paramsInput = {"deal_type_id" : params}
        if(this.state.editMode){
             paramsInput = {"deal_type_id" : this.state.dealTypeSelected}
        }

        API.landType.list(paramsInput)

            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        landTypeList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getExistFields = (params = {}) => {
        this.setState({ loading: true });
        API.formBuilderSearchAdverOffer.distinct_list()

            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        existFieldsList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getSourceFields = (params = {}) => {
        this.setState({ loading: true });
        API.formBuilderAdverOffer.distinct_list()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        sourceFieldList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    ReturnFieldsForm = (fieldType, existField=null) => {
        let InpuParams = {
            isOwner : this.state.is_owner,
            dealType : this.state.dealTypeSelected,
            landType : this.state.landTypeSelected,
            fieldType : fieldType,
        }
        API.ReturnFieldsFormSearch.list(InpuParams)
            .then((result) => {
                console.log(result)
                if (result) {

                    this.setState({
                        loading: false,
                        apiFormElements: result,
                    });
                    if (existField){
                        this.ReturnExistFieldsFormList(existField)
                    }
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    ReturnExistFieldsForm = (id) => {
        let InpuParams = {
            isOwner : this.state.is_owner,
            dealType : this.state.dealTypeSelected,
            landType : this.state.landTypeSelected,
            id : id,
        }
        API.ReturnExistFieldsSearch.list(InpuParams)
            .then((result) => {
                console.log(result)
                if (result.data) {
                    let cloneData = result.data[0]
                    this.setState({fieldTypeSelected: cloneData.field_type});
                    this.ReturnFieldsForm(cloneData.field_type, cloneData.id)
                    this.setState({
                        loading: false,
                        // apiFormElements: result.data,
                        });

                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    ReturnExistFieldsFormList = (id) => {
        let InpuParams = {
            isOwner : this.state.is_owner,
            dealType : this.state.dealTypeSelected,
            landType : this.state.landTypeSelected,
            id : id,
        }
        API.ReturnExistFieldsSearch.list(InpuParams)
            .then((result) => {
                console.log(result)
                if (result.data) {
                    // var index = result.data[0].indexOf("deal_type")
                    // if (index !== -1) {
                    //     result.data[0].splice(index, 1);
                    // }
                    // var index = result.data[0].indexOf("land_type")
                    // if (index !== -1) {
                    //     result.data[0].splice(index, 1);
                    // }
                    let cloneData = cloneDeep(result.data[0]);

                    this.formRef.current.setFieldsValue(cloneData);
                    this.setState({
                        loading: false,
                        // apiFormElements: result.data,
                        });
                    console.log("cloneData", cloneData)
                    // this.setState({id: cloneData.id});
                    // this.setState({isOwner: cloneData.is_owner});
                    // this.setState({dealTypeSelected: cloneData.deal_type});
                    // this.setState({landTypeSelected: cloneData.land_type});
                    // this.setState({inputTypeSelected: cloneData.input_type});
                    // this.setState({fieldTypeSelected: cloneData.field_type});

                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    handleSubmit(event) {
        event.preventDefault();
        alert(JSON.stringify(this.state.formValues));
    }


    HandleSelect = (e) => {

        this.ReturnFieldsForm(e)
    }

    HandleSelectExistField = (e) => {

        this.ReturnExistFieldsForm(e)
    }

    HandleDealTypeChange = (e) => {
        this.setState(
            {dealTypeSelected : e}
        )
        this.getLandType(e)
    }
    HandleLandTypeChange = (e) => {
        this.setState(
            {landTypeSelected : e}
        )
    }
    HandleIsOwnerChange = (e) => {
        this.setState(
            {is_owner : e.target.value}
        )

    }


    render() {
        const {onCancel, submitting, visible} = this.props;
        const { editMode, landTypeList, dealTypesList, isOwner, dealTypeSelected,
            landTypeSelected, apiFormElements
            , fieldTypeSelected, sourceFieldList,search_type, existFieldsList, existFieldSelected} = this.state; // INJA STATE HATO MIYARI TO RENDER INJA FARAKHONI MISHE AZ BALA

        const {TextArea} = Input;

        return (

            <Fragment>
                <Modal
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} layout="vertical" >
                        <Row gutter={24} >


                            <Col xs={24} md={12}>
                                <Form.Item name="deal_type" label={Resources.dealType} initialValue= {dealTypeSelected} >
                                    <Select onChange={(e) => this.HandleDealTypeChange(e)}>
                                        {dealTypesList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="land_type" label={Resources.landType}  initialValue= {landTypeSelected}>
                                    <Select onChange={(e) => this.HandleLandTypeChange(e)}>
                                        {landTypeList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>


                            <Col xs={24} md={12}>
                                <Form.Item  name="field" label={Resources.new_field}  initialValue= {fieldTypeSelected}>
                                    <Select onChange={(e) => this.HandleSelect(e)} >
                                        {sourceFieldList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.show_label}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>


                            {!editMode &&
                            <Col xs={24} md={12}>
                                <Form.Item name="exist_field" label={Resources.exist_field}
                                           initialValue={existFieldSelected}>
                                    <Select onChange={(e) => this.HandleSelectExistField(e)}>
                                        {existFieldsList.map(item => (
                                            <Select.Option key={item.id} value={item.id}>{item.show_label}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            }
                            <Col xs={24} md={12}>
                                <Form.Item name="search_type" label={Resources.search_type}  initialValue= {search_type}>
                                    <Select>
                                        <Select.Option  selected value="from">{Resources.from}</Select.Option>
                                        <Select.Option value="to">{Resources.to}</Select.Option>
                                        <Select.Option value="equal">{Resources.equal}</Select.Option>
                                        <Select.Option value="have">{Resources.have}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            {
                                apiFormElements.map(item =>


                                          <Col xs={24} md={12}>

                                              { ((item.type == "input") && (item.required)) ?
                                                          <Form.Item name={item.name} label={item.showName}  rules={Rules.required}>
                                                              <Input type={item.fieldType} placeholder={item.showName}  />
                                                          </Form.Item> :
                                                          ((item.type == "input") && (!item.required)) ?
                                                              <Form.Item name={item.name} label={item.showName}  >
                                                                  <Input type={item.fieldType} placeholder={item.showName}  />
                                                              </Form.Item>
                                                      :item.type == "select" ?
                                                      <Form.Item name={item.name} label={item.showName} >
                                                          <Select>
                                                              {item.data.map(item => (
                                                                  <Select.Option key={item._id}  value={item._id}>{item.name}</Select.Option>
                                                              ))}
                                                          </Select>
                                                      </Form.Item>

                                                      :item.type == "textarea" ?
                                                                  <Form.Item name={item.name} label={item.showName} >
                                                                      <TextArea  placeholder={item.showName}
                                                                               />
                                                                  </Form.Item>
                                                      :
                                                          <Form.Item name={item.name} label={item.showName} rules={Rules.required} >
                                                              <Radio.Group>
                                                                  <Radio value="yes">{Resources.yes}</Radio>
                                                                  <Radio value="no" >{Resources.no}</Radio>
                                                              </Radio.Group>
                                                          </Form.Item>
                                              }
                                              </Col>
                                )
                            }
                        </Row>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default AddFormBuilderSearchAdverOffer;
