import React, {Component, Fragment} from 'react';
import {
    Breadcrumb,
    Button,
    Col,
    Input,
    Row,
    Table,
    Tooltip,
    Checkbox,
    message,
    Dropdown,
    Menu,
    Switch,
    Modal, Form,
    Drawer, Select, Avatar
} from "antd";
import JoditEditor from "jodit-react";
import Resources from "../scripts/resources";
import {PlusOutlined, SearchOutlined
, EditOutlined, LockOutlined, MenuOutlined, EyeOutlined, DeleteOutlined, ControlFilled} from '@ant-design/icons'
import {Constants, Permissions} from "../scripts/settings";
import AdvertiseHousingForm from "../forms/AdvertiseHousing";
import API from "../scripts/api";
import {connect} from "react-redux";
import {CheckPermission} from "../scripts/helpers";
import {SetRoute, UpdateUser} from "../scripts/action";

import PermissionForm from "../forms/Permission";
// import {Form} from "antd/lib/form";
import ShowImage from "../forms/showImage";
import AdvertiseFacility from "../forms/AdvertiseFacility";
import AdvertiseRoomFacility from "../forms/AdvertiseRoomFacility";
import ManageAdvertiseAgent from "../forms/ManageAdvertiseAgent";
import ShowVide from "../forms/showVideo";
import RejectAdvertice from "../forms/RejectAdvertice";
import PaginationInfo from "../components/PaginationInfo";
import DatePicker from "react-modern-calendar-datepicker";
import moment from "moment-jalaali";

class OfferHousingManagement extends Component {
    constructor() {
        super();

        this.state = {
            data: [],
            checkData: [],
            cols: [],
            aclData: '',
            showDrawer: false,
            DrawerData: "",
            filter: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: Constants.pageSize,
                size: 'small',
                position: ['top', 'bottom']
            },
            visibleDeleteForm: false,
            visibleRejectForm: false,
            visibleFacilityForm: false,
            visibleRoomFacilityForm: false,
            visibleVideoForm: false,
            track_code: false,
            mobile: false,
            dealTypesList: [],
            landTypeList: [],
            countryList: [],
            neighborhoodList: [],
            cityList: [],
            selectedAgentsList: [],

            selected: {},
            userRoles: '',
        };
        this.mediaRef = React.createRef();
        this.facilityRef = React.createRef();
        this.roomFacilityRef = React.createRef();
        this.formRef = React.createRef();
        this.videoRef = React.createRef();
        this.rejectFormRef = React.createRef();
        this.searchListFormRef = React.createRef();
        this.agentFormRef = React.createRef();

        // this.handleFilter = debounce(this.handleFilter, 1000);
    }

    fetch = (params = {}) => {
        const { filterLevel, filterSearch } = this.state;

        params.q = filterSearch;
        params.level = filterLevel;

        this.setState({ loading: true });

        API.offerHousing.list(params)
            .then((result) => {
                if (result) {
                    this.setState({
                        loading: false,
                        data: result.data,
                        checkData: result.data,
                        cols: result.cols,
                        pagination: {
                            ...this.state.pagination, ...{
                                current: parseInt(result.current_page),
                                pageSize: parseInt(result.per_page),
                                total: result.total,
                            }
                        }
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    componentDidMount() {
        const { onSetRoute, routeName, parentName } = this.props;

        onSetRoute({ openKey: parentName, selectedKey: routeName });

        this.fetch({
            country_id: this.state.countrySearchSelected,
            city_id: this.state.citySearchSelected,
            neighborhood_id: this.state.neighborhoodSearchSelected,
            deal_type_id: this.state.dealTypeSelected,
            land_type_id: this.state.landTypeSelected,
            track_code: this.state.track_code,
            mobile: this.state.mobile,
            identifier: this.state.identifier,
            status: this.state.status,

               page: this.state.pagination.current,
               per_page: this.state.pagination.pageSize,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
        })

        API.acl.rules()
            .then(res => {
                this.setState({
                    userRoles: res
                });
            })

        API.acl.all()
            .then(res => {
                this.setState({aclData : res})})

        this.getSearchCountries()
        this.getDealTypes()

    }
    handlePermissions = (values) => {
        this.setState({ submitting: true });
        API.acl.save(values, this.state.selected.id)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hidePermission();
                this.fetch({
                    country_id: this.state.countrySearchSelected,
                    city_id: this.state.citySearchSelected,
                    neighborhood_id: this.state.neighborhoodSearchSelected,
                    deal_type_id: this.state.dealTypeSelected,
                    land_type_id: this.state.landTypeSelected,
                    track_code: this.state.track_code,
                    mobile: this.state.mobile,
                    identifier: this.state.identifier,
                    status: this.state.status,
                       page: this.state.pagination.current,
                       per_page: this.state.pagination.pageSize,
                    fromDate: this.state.fromDate,
                    toDate: this.state.toDate,
                })
            })
    };
    showPermission = (user = {}) => {
        this.setState({ visiblePermission: true, selected: user });

        // setTimeout(() => {
        //     this.permissionFormRef.current.handleDidMount(user);
        // }, 0);
    };
    hidePermission = () => {
        this.setState({ visiblePermission: false });

        // setTimeout(() => {
        //     this.permissionFormRef.current.handleWillUnmount();
        // }, 0);
    }
    showForm = (advertise = {}) => {
        this.setState({ visibleForm: true, selected: advertise });

        setTimeout(() => {
            this.formRef.current.handleDidMount(advertise);
        }, 0);
    }
    hideForm = () => {
        this.setState({ visibleForm: false });

        setTimeout(() => {
            this.formRef.current.handleWillUnmount();
        }, 0);
    }
    handleSubmit = (values, roles) => {
        const {userRoles} = this.state;
        this.setState({ submitting: true });
        const action = this.state.selected.id ? API.offerHousing.update : API.offerHousing.add;

        if (action === API.offerHousing.update) {
            values.append('id', this.state.selected.id);

        }

        action(values)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideForm();
                this.fetch({
                    country_id: this.state.countrySearchSelected,
                    city_id: this.state.citySearchSelected,
                    neighborhood_id: this.state.neighborhoodSearchSelected,
                    deal_type_id: this.state.dealTypeSelected,
                    land_type_id: this.state.landTypeSelected,
                    track_code: this.state.track_code,
                    mobile: this.state.mobile,
                    identifier: this.state.identifier,
                    status: this.state.status,
                       page: this.state.pagination.current,      
                       per_page: this.state.pagination.pageSize, 
                    fromDate: this.state.fromDate,
                    toDate: this.state.toDate,
                })
                if(action === API.user.update){
                    API.profile.info().then((result) => {
                        this.props.onUpdateUser(result);
                    })
                }
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };
    handleStatus = (record) => {

        if(record.status == "0"){
            return Resources.wait
        }

        if(record.status == "1"){
            return Resources.confirm
        }

        if(record.status == "-1"){
            return Resources.reject
        }
        if(record.status == "-2"){
            return Resources.deleted
        }

    };
    handleUserSearch = (value) => {
        this.setState({ filterUserSearch: value });
    };
    onCloseDrawer = () => {
        this.setState({
            showDrawer: false,
            DrawerData: '',
        })
    }
    handleDelete = (record) => {
        Modal.confirm({
            title: Resources.confirmDelete,
            okText: Resources.yes,
            okType: 'danger',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({loading : true});
                API.offerHousing.del(record.id)
                    .then(res => {
                        message.success(Resources.successful);
                        this.fetch({
                            country_id: this.state.countrySearchSelected,
                            city_id: this.state.citySearchSelected,
                            neighborhood_id: this.state.neighborhoodSearchSelected,
                            deal_type_id: this.state.dealTypeSelected,
                            land_type_id: this.state.landTypeSelected,
                            track_code: this.state.track_code,
                            mobile: this.state.mobile,
                            identifier: this.state.identifier,
                            status: this.state.status,

                               page: this.state.pagination.current,      
                               per_page: this.state.pagination.pageSize, 
                            fromDate: this.state.fromDate,
                            toDate: this.state.toDate,
                        })
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };
    handleActive = (record) => {
        Modal.confirm({
            title: Resources.confirmActivate,
            okText: Resources.yes,
            okType: 'success',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({loading : true});
                API.offerHousing.active(record.id)
                    .then(res => {
                        message.success(Resources.successful);
                        this.fetch({
                            country_id: this.state.countrySearchSelected,
                            city_id: this.state.citySearchSelected,
                            neighborhood_id: this.state.neighborhoodSearchSelected,
                            deal_type_id: this.state.dealTypeSelected,
                            land_type_id: this.state.landTypeSelected,
                            track_code: this.state.track_code,
                            mobile: this.state.mobile,
                            identifier: this.state.identifier,
                            status: this.state.status,

                               page: this.state.pagination.current,
                               per_page: this.state.pagination.pageSize,
                            fromDate: this.state.fromDate,
                            toDate: this.state.toDate,
                        })
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };
    handleDeActive = (record) => {
        Modal.confirm({
            title: Resources.confirmReject,
            okText: Resources.yes,
            okType: 'danger',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({loading : true});
                API.offerHousing.deActive(record.id)
                    .then(res => {
                        message.success(Resources.successful);
                        this.fetch({
                            country_id: this.state.countrySearchSelected,
                            city_id: this.state.citySearchSelected,
                            neighborhood_id: this.state.neighborhoodSearchSelected,
                            deal_type_id: this.state.dealTypeSelected,
                            land_type_id: this.state.landTypeSelected,
                            track_code: this.state.track_code,
                            mobile: this.state.mobile,
                            identifier: this.state.identifier,
                            status: this.state.status,

                               page: this.state.pagination.current,
                               per_page: this.state.pagination.pageSize,
                            fromDate: this.state.fromDate,
                            toDate: this.state.toDate,
                        })
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };
    handleWait = (record) => {
        Modal.confirm({
            title: Resources.confirmWait,
            okText: Resources.yes,
            okType: 'danger',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({loading : true});
                API.offerHousing.wait(record.id)
                    .then(res => {
                        message.success(Resources.successful);
                        this.fetch({
                            country_id: this.state.countrySearchSelected,
                            city_id: this.state.citySearchSelected,
                            neighborhood_id: this.state.neighborhoodSearchSelected,
                            deal_type_id: this.state.dealTypeSelected,
                            land_type_id: this.state.landTypeSelected,
                            track_code: this.state.track_code,
                            mobile: this.state.mobile,
                            identifier: this.state.identifier,
                            status: this.state.status,

                               page: this.state.pagination.current,
                               per_page: this.state.pagination.pageSize,
                            fromDate: this.state.fromDate,
                            toDate: this.state.toDate,
                        })
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };
    showImageForm = (article = {}) => {
        this.setState({ visibleDeleteForm: true, selected: article });
        setTimeout(() => {
            this.mediaRef.current.handleDidMount(article);
        }, 0);
    };
    showVideoForm = (article = {}) => {
        this.setState({ visibleVideoForm: true, selected: article });
        setTimeout(() => {
            this.videoRef.current.handleDidMount(article);
        }, 0);
    };
    showFacilityForm = (facility = {}) => {
        this.setState({ visibleFacilityForm: true, selected: facility });
        setTimeout(() => {
            this.facilityRef.current.handleDidMount(facility);
        }, 0);
    };
    showRejectForm = (item = {}) => {
        this.setState({ visibleRejectForm: true, selected: item });
        setTimeout(() => {
            this.rejectFormRef.current.handleDidMount(item);
        }, 0);
    }
    hideRejectForm = () => {
        this.setState({ visibleRejectForm: false });

        setTimeout(() => {
            this.rejectFormRef.current.handleWillUnmount();
        }, 0);
    };
    hideAgentForm = () => {
        this.setState({ visibleAgentForm: false });

        setTimeout(() => {
            this.agentFormRef.current.handleWillUnmount();
        }, 0);
    };
    handleRejectSubmit = (values) => {
        this.setState({ submitting: true });
        values.append("id", this.state.selected.id)
        API.offerHousing.deActive(values)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideRejectForm();
                this.fetch({
                    country_id: this.state.countrySearchSelected,
                    city_id: this.state.citySearchSelected,
                    neighborhood_id: this.state.neighborhoodSearchSelected,
                    deal_type_id: this.state.dealTypeSelected,
                    land_type_id: this.state.landTypeSelected,
                    track_code: this.state.track_code,
                    mobile: this.state.mobile,
                    identifier: this.state.identifier,
                    status: this.state.status,

                       page: this.state.pagination.current,
                       per_page: this.state.pagination.pageSize,
                    fromDate: this.state.fromDate,
                    toDate: this.state.toDate,
                })
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };


    handleAgentSubmit = (values) => {
        this.setState({ submitting: true });
        values.append("advertises", this.state.checkData)
        API.offerHousing.changeAgent(values)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideAgentForm();
                this.fetch({

                    country_id: this.state.countrySearchSelected,
                    city_id: this.state.citySearchSelected,
                    neighborhood_id: this.state.neighborhoodSearchSelected,
                    deal_type_id: this.state.dealTypeSelected,
                    land_type_id: this.state.landTypeSelected,
                    track_code: this.state.track_code,
                    mobile: this.state.mobile,
                    identifier: this.state.identifier,
                    status: this.state.status,

                    page: this.state.pagination.current,
                    per_page: this.state.pagination.pageSize,
                    fromDate: this.state.fromDate,
                    toDate: this.state.toDate,

                })
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };


    showRoomFacilityForm = (facility = {}) => {
        this.setState({ visibleRoomFacilityForm: true, selected: facility });
        setTimeout(() => {
            this.roomFacilityRef.current.handleDidMount(facility);
        }, 0);
    };
    manageAgent = (record = {}) => {
        this.setState({ visibleAgentForm: true, selected: record });
        setTimeout(() => {
            this.agentFormRef.current.handleDidMount(record);
        }, 0);
    };

    hideDeleteForm = () => {
        this.setState({ visibleDeleteForm: false });

        setTimeout(() => {
            this.formRef.current.handleWillUnmount();
        }, 0);
    };
    hideVideoForm = () => {
        this.setState({ visibleVideoForm: false });

        try {
            document.getElementById("playedVideo").pause()
        }catch (e) {
            
        }
        setTimeout(() => {
            this.videoRef.current.handleWillUnmount();
        }, 0);
    };

    hideFacilityForm = () => {
        this.setState({ visibleFacilityForm: false });

        setTimeout(() => {
            this.facilityRef.current.handleWillUnmount();
        }, 0);
    };

    hideRoomFacilityForm = () => {
        this.setState({ visibleRoomFacilityForm: false });

        setTimeout(() => {
            this.roomFacilityRef.current.handleWillUnmount();
        }, 0);
    };

    handleDeleteSubmit = (id) => {
        this.setState({ submitting: true });

        API.gallery.delete(id)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideDeleteForm();
                this.fetch({
                    country_id: this.state.countrySearchSelected,
                    city_id: this.state.citySearchSelected,
                    neighborhood_id: this.state.neighborhoodSearchSelected,
                    deal_type_id: this.state.dealTypeSelected,
                    land_type_id: this.state.landTypeSelected,
                    track_code: this.state.track_code,
                    mobile: this.state.mobile,
                    identifier: this.state.identifier,
                    status: this.state.status,

                       page: this.state.pagination.current,
                       per_page: this.state.pagination.pageSize,
                    fromDate: this.state.fromDate,
                    toDate: this.state.toDate,
                })
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };

    handleVideoDeleteSubmit = (id) => {
        this.setState({ submitting: true });

        API.gallery.delete(id)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideDeleteForm();
                this.fetch({
                    country_id: this.state.countrySearchSelected,
                    city_id: this.state.citySearchSelected,
                    neighborhood_id: this.state.neighborhoodSearchSelected,
                    deal_type_id: this.state.dealTypeSelected,
                    land_type_id: this.state.landTypeSelected,
                    track_code: this.state.track_code,
                    mobile: this.state.mobile,
                    identifier: this.state.identifier,
                    status: this.state.status,

                       page: this.state.pagination.current,
                       per_page: this.state.pagination.pageSize,
                    fromDate: this.state.fromDate,
                    toDate: this.state.toDate,
                })
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };
    handleVideoSubmit = (id) => {
        this.setState({ submitting: true });

        API.gallery.delete(id)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideDeleteForm();
                this.fetch({
                    country_id: this.state.countrySearchSelected,
                    city_id: this.state.citySearchSelected,
                    neighborhood_id: this.state.neighborhoodSearchSelected,
                    deal_type_id: this.state.dealTypeSelected,
                    land_type_id: this.state.landTypeSelected,
                    track_code: this.state.track_code,
                    mobile: this.state.mobile,
                    identifier: this.state.identifier,
                    status: this.state.status,

                       page: this.state.pagination.current,
                       per_page: this.state.pagination.pageSize,
                    fromDate: this.state.fromDate,
                    toDate: this.state.toDate,
                })
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };
    handleVip = (record) => {
        Modal.confirm({
            title: parseInt(record.vip == "بله") ? Resources.confirmDeactivate : Resources.confirmActivate,
            okText: Resources.yes,
            okType: record.status ? 'danger' : 'primary',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({ loading: true });
                API.offerHousing.vip(record.id)
                    .then((result) => {
                        message.success(result.message);
                        this.fetch({
                            country_id: this.state.countrySearchSelected,
                            city_id: this.state.citySearchSelected,
                            neighborhood_id: this.state.neighborhoodSearchSelected,
                            deal_type_id: this.state.dealTypeSelected,
                            land_type_id: this.state.landTypeSelected,
                            track_code: this.state.track_code,
                            mobile: this.state.mobile,
                            identifier: this.state.identifier,
                            status: this.state.status,

                               page: this.state.pagination.current,
                               per_page: this.state.pagination.pageSize,
                            fromDate: this.state.fromDate,
                            toDate: this.state.toDate,
                        })
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };
    handleSlider = (record) => {
        Modal.confirm({
            title: parseInt(record.is_slider == "بله") ? Resources.confirmDeactivate : Resources.confirmActivate,
            okText: Resources.yes,
            okType: record.status ? 'danger' : 'primary',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({ loading: true });
                API.offerHousing.slider(record.id)
                    .then((result) => {
                        message.success(result.message);
                        this.fetch({
                            country_id: this.state.countrySearchSelected,
                            city_id: this.state.citySearchSelected,
                            neighborhood_id: this.state.neighborhoodSearchSelected,
                            deal_type_id: this.state.dealTypeSelected,
                            land_type_id: this.state.landTypeSelected,
                            track_code: this.state.track_code,
                            mobile: this.state.mobile,
                            identifier: this.state.identifier,
                            status: this.state.status,

                               page: this.state.pagination.current,
                               per_page: this.state.pagination.pageSize,
                            fromDate: this.state.fromDate,
                            toDate: this.state.toDate,
                        })
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };

    handleSearchData = (value) => {
        this.setState({ filterSearchData: value });
    };

    HandleCountryChange = (e) => {
        this.setState(
            {countrySearchSelected : e}
        )
        this.getSearchCities(e)
    }
    HandleCityChange = (e) => {
        this.setState(
            {citySearchSelected : e}
        )
        this.getSearchNeighborhoods(e)
    }

    HandleNeighborhoodChange = (e) => {
        this.setState(
            {neighborhoodSearchSelected : e}
        )
    }

    getSearchCountries = (params = {}) => {
        API.countries.list()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        countryList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getSearchCities = (params = {}) => {
        this.setState({ loading: true });
        API.countryCities.list(params)

            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        cityList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    getSearchNeighborhoods = (params = {}) => {
        this.setState({ loading: true });
        API.cityNeighbourhood.list(params)

            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        neighborhoodList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    HandleDealTypeChange = (e) => {
        this.setState(
            {dealTypeSelected : e}
        )
        this.getLandType(e)
    }
    HandleLandTypeChange = (e) => {
        this.setState(
            {landTypeSelected : e}
        )
    }

    getDealTypes = (params = {}) => {
        API.dealTypesListWithType.offerList()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        dealTypesList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getLandType = (params = {}) => {
        let paramsInput = {"deal_type_id" : params}


        API.landType.list(paramsInput)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        landTypeList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    handleTableChange = async (pagination) => {


        await this.setState({
            pagination: {
                  ...this.state.pagination,
                  current: parseInt(pagination.current),
                  pageSize: parseInt(pagination.pageSize),
            }
        })
        await this.fetch({
            country_id: this.state.countrySearchSelected,
            city_id: this.state.citySearchSelected,
            neighborhood_id: this.state.neighborhoodSearchSelected,
            deal_type_id: this.state.dealTypeSelected,
            land_type_id: this.state.landTypeSelected,
            track_code: this.state.track_code,
            mobile: this.state.mobile,
            identifier: this.state.identifier,
            status: this.state.status,
            page: this.state.pagination.current,
            per_page: this.state.pagination.pageSize,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
        })
    }

    handleDate = (date, obj) => {
        const state = {};
        state[obj] = date;
        this.searchListFormRef.current.setFieldsValue(state);
    }

    //
    // onChangeCheckbox = (checkedValues)  => {
    //
    //     this.setState({selectedAgentsList: checkedValues})
    // }

    checkAllAdvertiseForAgent = (event) => {
        let data = this.state.data;
        let checkData = []
        if(event.target.checked){
            data.forEach(item => {
                checkData.push(item.id)
            });
        }
        console.log("chaek data is: ", checkData)
        this.setState({ checkData: checkData });
    };

    handleChoiceAgent = (value) => {

        return <Checkbox
             onChange = {this.handleCheckChieldElement}
             value={value.id}
             {...value}
             key={value.id}
         ></Checkbox>

    }

    handleCheckChieldElement = event => {
        let checkData = this.state.checkData;
        if(event.target.checked){
            checkData.push(event.target.value)
        }else{
            checkData = checkData.filter((item) => {return item != event.target.value });
        }
        this.setState({ checkData: checkData });
    };



    render() {
        const{ visibleForm, submitting, data,  visibleVideoForm, visibleRoomFacilityForm,
            visibleFacilityForm, loading, filterSearchData, visibleDeleteForm,pagination,
            cityList, neighborhoodList,countryList,dealTypesList,landTypeList,
            visibleRejectForm, cols, visibleAgentForm, checkData } = this.state;
        const { permissions, onUpdateUser } = this.props;
        let columns = []
        let dataSource = []


        columns.push(
            {
                title: Resources.action,
                dataIndex: 'id',
                className: 'col-center',
                width: '60px',
                render: (val, record) => (
                    <Dropdown overlay={<Menu>
                        {(record.status != "-2") ?
                        <Menu.Item>
                            <span onClick={() => this.handleDelete(record)}><DeleteOutlined /> {Resources.delete}</span>
                        </Menu.Item>
                        : ""}
                        {/*<Menu.Item>*/}
                        {/*    <span onClick={() => this.showImageForm(record)}><DeleteOutlined  />{Resources.manageImage}</span>*/}
                        {/*</Menu.Item>*/}

                        {(record.is_slider == "خیر") &&

                        <Menu.Item>
                            <span onClick={() => this.handleSlider(record)}><ControlFilled/> {Resources.showInSlider}</span>
                        </Menu.Item>
                        }
                        {(record.is_slider == "بله") &&

                        <Menu.Item>
                            <span onClick={() => this.handleSlider(record)}><ControlFilled/> {Resources.DeleteInSlider}</span>
                        </Menu.Item>
                        }

                        {(record.vip == "خیر") &&

                        <Menu.Item>
                            <span onClick={() => this.handleVip(record)}><ControlFilled/> {Resources.showInVip}</span>
                        </Menu.Item>
                        }
                        {(record.vip == "بله") &&

                        <Menu.Item>
                            <span onClick={() => this.handleVip(record)}><ControlFilled/> {Resources.DeleteInVip}</span>
                        </Menu.Item>

                        }
                        <Menu.Item>
                            <a target="_blank" href={`${Constants.frontUrl}homeDetail/detail?id=${record.id}&self=true`} ><EyeOutlined/> {Resources.view}</a>
                        </Menu.Item>
                        <Menu.Item>
                            <a target="_blank" href={`${Constants.frontUrl}registration/?editId=${record.id}`} ><EditOutlined/> {Resources.edit}</a>
                        </Menu.Item>
                    </Menu>}>
                        <Button type="link" icon={<MenuOutlined />} />
                    </Dropdown>
                )
            },
            {
                title: Resources.choice,
                dataIndex: 'id',
                className: 'col-center',
                width: '80px',
                render: (val, record) => <span>{this.handleChoiceAgent(record)}</span>

        } )


        if(data.length){
            // let keys = Object.keys(data[0])
            Object.keys(cols).map(function(key, index) {

                if(cols[key][0]["show_label"] != undefined){
                    console.log(cols[key][0]);
                    var statusColor = "";
                    // let itemWidth = [115,75,110,120,95,110,130,90,55,100,150,150,105,105,75,120,100,85,55,55,100,95,95,150,150,600,500];
                    let itemWidth = {"is_slider":115,"vip":75,"show_status":110,"username":120,"identifier":95,"mobile":110,"created_at":130,"agent":90,"country":55,"city":100,"neighborhood":150,"title":150,"deal_type":105,"land_type":105,"track_code":75,"moaref_name_malk":120,"isOwner":100,"meter_zamin":85,"zirbana":55,"gheimat_kol":105,"mablagh_rahn":100,"mablagh_ejare":95,"reject_reason":95,"delete_reason":150,"tozihat":600,"last_note":500};
                    columns.push(
                        {  
                            title: cols[key][0]["show_label"],
                            dataIndex: cols[key][0]["name"],
                            // key: cols[key][0]["name"],
                            // key: index,
                            className: 'col-center',
                            // width: itemWidth[index],
                            width: itemWidth[cols[key][0]["name"]],
                            render: (text) => (
                                <>
                                {(text == "تایید شده") ?
                                    <p style={{display: 'block', margin: 'auto', padding: '5px 0', backgroundColor: '#3bc63b'}}>{text}</p>
                                    : (text == "در انتظار تایید") ? <p style={{display: 'block', margin: 'auto', padding: '5px 0', backgroundColor: '#ffcb00'}}>{text}</p>
                                    : (text == "رد شده" || text == "حذف شده") ? <p style={{display: 'block', margin: 'auto', padding: '5px 0', backgroundColor: '#ff3c3c'}}>{text}</p>
                                    : <p style={{display: 'block', margin: 'auto', padding: '5px 0'}}>{text}</p>
                                }
                                </>
                            ),
                            ellipsis: true,
                        }
                    )
                }



            });
        }



        const filteredData = data.filter(o => {
            let query = false;
            if (filterSearchData) {
                if (o.title && o.title.indexOf(filterSearchData) >= 0)
                    query = true;
                if (o.country && o.country.indexOf(filterSearchData) >= 0)
                    query = true;
                if (o.city && o.city.indexOf(filterSearchData) >= 0)
                    query = true;
                if (o.neighborhood && o.neighborhood.indexOf(filterSearchData) >= 0)
                    query = true;
                if (o.deal_type && o.deal_type.indexOf(filterSearchData) >= 0)
                    query = true;
                if (o.land_type && o.land_type.indexOf(filterSearchData) >= 0)
                    query = true;
                if (o.track_code && o.track_code.indexOf(filterSearchData) >= 0)
                    query = true;
                if (o.username && o.username.indexOf(filterSearchData) >= 0)
                    query = true;
                if (o.mobile && o.mobile.indexOf(filterSearchData) >= 0)
                    query = true;
            } else {
                query = true;
            }
            return query;
        });

        const onFinish = (values) => {
            let { fromDate, toDate } = values;
            if (fromDate)
                fromDate = moment(`${fromDate.year}-${fromDate.month}-${fromDate.day}`, Constants.jDateFormat).format(Constants.dateFormat);

            if (toDate)
                toDate = moment(`${toDate.year}-${toDate.month}-${toDate.day}`, Constants.jDateFormat).format(Constants.dateFormat);

            this.setState({
                countrySearchSelected: values.country_search_id,
                citySearchSelected: values.city_search_id,
                neighborhoodSearchSelected: values.neighborhood_search_id,
                dealTypeSelected: values.deal_type_id,
                landTypeSelected: values.land_type_id,
                track_code: values.track_code,
                mobile: values.mobile,
                status: values.status,
                identifier: values.identifier,
                fromDate: fromDate,
                toDate: toDate,
            });
            this.fetch({
                country_id: this.state.countrySearchSelected,
                city_id: this.state.citySearchSelected,
                neighborhood_id: this.state.neighborhoodSearchSelected,
                deal_type_id: this.state.dealTypeSelected,
                land_type_id: this.state.landTypeSelected,
                track_code: this.state.track_code,
                mobile: this.state.mobile,
                identifier: this.state.identifier,
                status: this.state.status,
                page: this.state.pagination.current,
                per_page: this.state.pagination.pageSize,
                fromDate: this.state.fromDate,
                toDate: this.state.toDate,
            })
        };
        const DatePickerInput = ({ ref }, name) => {
            const date = this.searchListFormRef.current ? this.searchListFormRef.current.getFieldValue(name) : null;
            return <Input value={date ? `${date.year}-${date.month}-${date.day}` : date} placeholder={Resources.choose} ref={ref} readOnly />;
        };

        return (
            <Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item>{Resources.offerHousing}</Breadcrumb.Item>
                </Breadcrumb>
                <div className='ant-content'>
                    <Form ref={this.searchListFormRef} layout="vertical"   onFinish={onFinish} >
                        <Row gutter={24}>
                            <Col xs={24} md={4}>
                                <Button type="primary" icon={<PlusOutlined />} onClick={() => this.showAddForm()} block>{Resources.add}</Button>
                            </Col>
                        </Row>

                        <Row gutter={24} style={{marginTop: "3%"}}>
                            <Col xs={24} md={6}>
                                <Form.Item name="country_search_id" label={Resources.country}  >
                                    <Select onChange={(e) => this.HandleCountryChange(e)} allowClear={true}>
                                        {countryList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item name="city_search_id" label={Resources.city}  >
                                    <Select showSearch
                                        filterOption={(input, option) => {
                                            return (
                                                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );
                                        }}
                                        onChange={(e) => this.HandleCityChange(e)} allowClear={true}>
                                        {cityList.map(item => (
                                            <Select.Option key={item.id} title={item.name}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item  name="neighborhood_search_id" label={Resources.neighborhood}  >
                                    <Select showSearch mode={"multiple"} autoClearSearchValue={false}
                                        filterOption={(input, option) => {
                                            return (
                                                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );
                                        }}
                                            onChange={(e) => this.HandleNeighborhoodChange(e)} allowClear={true}>
                                        {neighborhoodList.map(item => (
                                            <Select.Option  title={item.name} key={item.id}   value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={6}>
                                <Form.Item name="deal_type_id" label={Resources.dealType}  >
                                    <Select showSearch
                                            filterOption={(input, option) => {
                                                return (
                                                    option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );
                                            }}
                                        onChange={(e) => this.HandleDealTypeChange(e)} allowClear={true}>
                                        {dealTypesList.map(item => (
                                            <Select.Option title={item.name}  key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item name="land_type_id" label={Resources.landType}  >
                                    <Select showSearch
                                            filterOption={(input, option) => {
                                                return (
                                                    option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );
                                            }}
                                        onChange={(e) => this.HandleLandTypeChange(e)} allowClear={true}>
                                        {landTypeList.map(item => (
                                            <Select.Option key={item.id} title={item.name}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={6}>
                                <Form.Item name="track_code"   label={Resources.houseCode}>
                                    <Input type="text"   />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={6}>
                                <Form.Item name="mobile"   label={Resources.mobile}>
                                    <Input type="number"   />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item name="identifier"   label={Resources.identifier}>
                                    <Input type="text"   />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item name="status" label={Resources.status}  >
                                    <Select allowClear={true}>
                                            <Select.Option  value="0">{Resources.wait}</Select.Option>
                                            <Select.Option  value="1">{Resources.confirm}</Select.Option>
                                            <Select.Option  value="-1">{Resources.reject}</Select.Option>
                                            <Select.Option  value="-2">{Resources.delete}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={6}>
                                <Form.Item name="fromDate" label={Resources.fromDate} >
                                    <DatePicker
                                        locale="fa"
                                        onChange={value => this.handleDate(value, 'fromDate')}
                                        renderInput={(ref) => DatePickerInput(ref, 'fromDate')}
                                        shouldHighlightWeekends
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item name="toDate" label={Resources.toDate} >
                                    <DatePicker
                                        locale="fa"
                                        onChange={value => this.handleDate(value, 'toDate')}
                                        renderInput={(ref) => DatePickerInput(ref, 'toDate')}
                                        shouldHighlightWeekends
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={4} style={{marginTop: "3%"}}>
                                <Button type="primary" htmlType="submit">{Resources.search}</Button>
                            </Col>
                            <Col xs={24} md={4} style={{marginTop: "3%"}}>
                                <Button  type="primary" onClick={() => this.manageAgent()}> {Resources.manageAgent}</Button>
                            </Col>
                            <Col xs={24} md={4} style={{marginTop: "3%"}}>
                                <input
                                    type="checkbox"
                                    onClick={this.checkAllAdvertiseForAgent}
                                    value="checkedall"
                                />{" "}
                                Check / Uncheck All
                                {/*<Button  type="primary" onClick={() => this.checkAllAdvertiseForAgent()}> {Resources.checkAll}</Button>*/}
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="ant-content">
                    <Checkbox.Group style={{ width: '100%' }} value={checkData} onChange={this.onChangeCheckbox}>
                        <Table
                            columns = {columns}
                            dataSource = {filteredData}
                            loading = {loading}
                            onChange = {this.handleTableChange}
                            pagination = {pagination}
                            size = "small"
                            rowKey = "id"
                            scroll={{ x: 1300, y: 600 }}
                        />
                        {!!pagination.total && <PaginationInfo current={pagination.current} defaultCurrent={pagination.current} pageSize={pagination.pageSize} total={pagination.total} />}
                    </Checkbox.Group>
                </div>
                {/*<PermissionForm selected={this.state.selected} permisionData={aclData} onCancel={this.hidePermission} onSubmit={this.handlePermissions} submitting={submitting} visible={visiblePermission} />*/}
                <AdvertiseHousingForm ref={this.formRef} onCancel={this.hideForm} onSubmit={this.handleSubmit} submitting={submitting} visible={visibleForm}  />
                <ShowImage ref={this.mediaRef} data={this.props.history.location.state} ifUpdate={this.state.selected.id} onCancel={this.hideDeleteForm} onSubmit={this.handleDeleteSubmit} onDelete={this.handleDeleteSubmit} visible={visibleDeleteForm} />
                <ShowVide ref={this.videoRef} data={this.props.history.location.state} ifUpdate={this.state.selected.id} onCancel={this.hideVideoForm} onSubmit={this.handleVideoSubmit} onDelete={this.handleVideoDeleteSubmit} visible={visibleVideoForm} />
                <AdvertiseFacility ref={this.facilityRef} data={this.props.history.location.state} ifUpdate={this.state.selected.id} onCancel={this.hideFacilityForm} visible={visibleFacilityForm} />
                <AdvertiseRoomFacility ref={this.roomFacilityRef} data={this.props.history.location.state} ifUpdate={this.state.selected.id} onCancel={this.hideRoomFacilityForm}  visible={visibleRoomFacilityForm} />
                <RejectAdvertice ref={this.rejectFormRef} onSubmit={this.handleRejectSubmit} data={this.props.history.location.state} ifUpdate={this.state.selected.id} onCancel={this.hideRejectForm}  visible={visibleRejectForm} />
                <ManageAdvertiseAgent ref={this.agentFormRef} onSubmit={this.handleAgentSubmit} data={this.props.history.location.state} ifUpdate={this.state.selected.id} onCancel={this.hideAgentForm}  visible={visibleAgentForm} />
            </Fragment>
        );
    }
}

export default connect(
    (state) => {
        return {
            permissions: {
                action: CheckPermission(state.app.user, Permissions.user_option),
                add: CheckPermission(state.app.user, Permissions.user_store)
            }
        };
    },
    (dispatch) => {
        return {
            onSetRoute: (data) => dispatch(SetRoute(data)),
            onUpdateUser: (data) => dispatch(UpdateUser(data)),
        };
    }
)(OfferHousingManagement);
