import React, {Component, Fragment} from 'react';
import {
    Breadcrumb,
    Button,
    Col,
    Input,
    Row,
    Table,
    Tooltip,
    Checkbox,
    message,
    Dropdown,
    Menu,
    Switch,
    Modal,Form,
    Drawer, Avatar, Select
} from "antd";
import JoditEditor from "jodit-react";
import Resources from "../scripts/resources";
import {PlusOutlined, SearchOutlined
, EditOutlined, LockOutlined, MenuOutlined, EyeOutlined} from '@ant-design/icons'
import {Constants, Permissions} from "../scripts/settings";
import UserForm from "../forms/User";
import API from "../scripts/api";
import {connect} from "react-redux";
import {CheckPermission} from "../scripts/helpers";
import {SetRoute, UpdateUser} from "../scripts/action";
import PermissionForm from "../forms/Permission";
import LegalDevices from "../forms/LegalDevices";
// import {Form} from "antd/lib/form";
import PaginationInfo from "../components/PaginationInfo";
import ShowVide from "../forms/showVideo";
class UserManagement extends Component {
    constructor() {
        super();

        this.state = {
            data: [],
            aclData: '',
            showDrawer: false,
            DrawerData: "",
            filter: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: Constants.pageSize,
                size: 'small'
            },
            selected: {},
            userRoles: '',
            mobile: '',
            searchName: '',
            identifier: '',
            visibleUserAgentForm: false,
        };

        this.formRef = React.createRef();
        this.creditFormRef = React.createRef();
        this.permissionFormRef = React.createRef();
        this.userAgentFormRef = React.createRef();

        // this.handleFilter = debounce(this.handleFilter, 1000);
    }

    fetch = (params = {}) => {
        const { filterLevel, filterSearch } = this.state;
        console.log("params: ", params)

        params.q = filterSearch;
        params.level = filterLevel;

        this.setState({ loading: true });

        API.user.list(params)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        data: result.data,
                        pagination: {
                            ...this.state.pagination, ...{
                                current: result.current_page,
                                pageSize: result.per_page,
                                total: result.total
                            }
                        }
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    componentDidMount() {
        const { onSetRoute, routeName, parentName } = this.props;

        onSetRoute({ openKey: parentName, selectedKey: routeName });

        this.fetch({
            page: this.state.pagination.current,
            name: this.state.name,
            family: this.state.family,
            email: this.state.email,
            mobile: this.state.mobile,
            identifier: this.state.identifier,
        });

        API.acl.rules()
            .then(res => {
                this.setState({
                    userRoles: res
                });
            })

        API.acl.all()
            .then(res => {
                this.setState({aclData : res})})
    }

    handlePermissions = (values) => {
        this.setState({ submitting: true });
        API.acl.save({"permissions":values}, this.state.selected.id)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hidePermission();
                this.fetch({ page: this.state.pagination.current });
            })
    };

    showPermission = (user = {}) => {
        this.setState({ visiblePermission: true, selected: user });

        // setTimeout(() => {
        //     this.permissionFormRef.current.handleDidMount(user);
        // }, 0);
    };

    hidePermission = () => {
        this.setState({ visiblePermission: false });

        // setTimeout(() => {
        //     this.permissionFormRef.current.handleWillUnmount();
        // }, 0);
    };

    showForm = (user = {}) => {
        this.setState({ visibleForm: true, selected: user });

        setTimeout(() => {
            this.formRef.current.handleDidMount(user);
        }, 0);
    };

    hideForm = () => {
        this.setState({ visibleForm: false });

        setTimeout(() => {
            this.formRef.current.handleWillUnmount();
        }, 0);
    }

    handleSubmit = (values) => {
        const {userRoles} = this.state;
        this.setState({ submitting: true });
        const action = this.state.selected.id ? API.user.update : API.user.add;

        if (action === API.user.update) {
            values.append('id', this.state.selected.id);

        }

        action(values)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideForm();
                this.fetch({
                    page: this.state.pagination.current,
                    name: this.state.name,
                    family: this.state.family,
                    email: this.state.email,
                    mobile: this.state.mobile,
                    identifier: this.state.identifier,
                });
                if(action === API.user.update){
                    API.profile.info().then((result) => {
                        this.props.onUpdateUser(result);
                    })
                }
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };

    handleStatus = (record) => {
        Modal.confirm({
            title: parseInt(record.status) ? Resources.confirmDeactivate : Resources.confirmActivate,
            okText: Resources.yes,
            okType: record.status ? 'danger' : 'primary',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({ loading: true });
                API.user.statusUpdate(record)
                    .then((result) => {
                        message.success(result.message);
                        this.fetch({
                            page: this.state.pagination.current,
                            name: this.state.name,
                            family: this.state.family,
                            email: this.state.email,
                            mobile: this.state.mobile,
                            identifier: this.state.identifier,
                        });
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };

    handleUserSearch = (value) => {
        this.setState({ filterUserSearch: value });
    };

    CvView = (record) => {
        this.setState({
            showDrawer: true,
            DrawerData : record.cv
        })
    };
    onCloseDrawer = () => {
        this.setState({
            showDrawer: false,
            DrawerData: '',
        })
    }
    handleTableChange = (pagination) => {
        // this.fetch({ page: pagination.current });
        this.fetch({
            name: this.state.name,
            family: this.state.family,
            email: this.state.email,
            mobile: this.state.mobile,
            identifier: this.state.identifier,
            page: pagination.current
        })
    }



    showUserAgentForm = (user = {}) => {
        console.log("visibleUserAgentForm")
        this.setState({ visibleUserAgentForm: true, selected: user });

        setTimeout(() => {
            this.userAgentFormRef.current.handleDidMount(user);
        }, 0);
    };

    hideUserAgentForm = () => {
        this.setState({ visibleUserAgentForm: false });

        setTimeout(() => {
            this.userAgentFormRef.current.handleWillUnmount();
        }, 0);
    }

    deleteAgent =(item) => {
        console.log("item is: ", item)
        this.setState({ loading: true });
        API.user.deleteAgent(item.id)
            .then((result) => {
                this.setState({ visibleUserAgentForm: false });
                this.fetch({ page: this.state.pagination.current });
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    loginWithUser = (record) => {
        this.setState({ submitting: true });

        let username = (record.mobile) ? record.mobile : record.email;
        // this.handleToken().then((token) => {
        const data = {
            "scope": "*",
            "grant_type": "password",
            // "client_id": "6156f0248c670000d9002ac4", //local
            "client_id": "6124a44036f4d16db97f6a12",///server
            // "client_secret": "3JvB8xf50jPubAzXwG9JIl0OYGo30wpvVPEyTTMU",  //local
            "client_secret": "Rm157m2uqahYuxSzv26kehItKLaX0Wc6Pw669i7f", ///server
            "username": `${username}`,
            "password": `1234`,
            "login_from": `admin`,
        };

        API.user.verify(data)
            .then((result) => {
                if (result.access_token) {
                    const date = new Date();
                    let redirectUrl = `${Constants.frontUrl}login-specific-user?authToken=${result.access_token}`
                    // console.log("url is: ", redirectUrl)
                    // date.setSeconds(date.getSeconds() + result.expires_in);
                    // this.cookies.set('AccessToken', result.access_token, { path: '/', expires: date, domain: window.location.hostname });
                    // window.location = `${Constants.frontUrl}login-specific-user?authToken=${result.access_token}`;
                    // window.open(redirectUrl, '_blank', 'noopener,noreferrer');
                        let tagA = document.createElement("a");
                        tagA.setAttribute("href", redirectUrl);
                        tagA.setAttribute("target", "_blank");
                        tagA.click();
                } else {
                    message.error(result.message);
                    this.setState({ submitting: false });
                }
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
        // });
    }

    render() {
        const{ visibleForm, submitting, data, aclData, visiblePermission, loading, filterUserSearch , pagination, visibleUserAgentForm} = this.state;
        const { permissions, onUpdateUser } = this.props;

        const columns = [
            {
                title: Resources.action,
                dataIndex: 'id',
                className: 'col-center',
                width: '80px',
                render: (val, record) => (
                    <Dropdown overlay={<Menu>
                        <Menu.Item>
                            <span onClick={() => this.showForm(record)}><EditOutlined /> {Resources.edit}</span>
                        </Menu.Item>
                        <Menu.Item>
                            <span onClick={() => this.showPermission(record)}>{Resources.permissions}</span>
                        </Menu.Item>

                        <Menu.Item>
                            <span onClick={() => this.showUserAgentForm(record)}> {Resources.legalDevices}</span>
                        </Menu.Item>

                        <Menu.Item>
                            <span onClick={() => this.loginWithUser(record)}>{Resources.loginWithSpecificUser}</span>
                        </Menu.Item>

                    </Menu>}>
                        <Button type="link" icon={<MenuOutlined />} />
                    </Dropdown>
                )
            },
            {
            title: Resources.firstName,
            dataIndex: 'name',
            className: 'col-center',
            width: '120px'
        },

            {
                title: Resources.family,
                dataIndex: 'family',
                className: 'col-center',
                width: '120px'
            },
            {
            title: Resources.mobile,
            dataIndex: 'mobile',
            className: 'col-center',
            width: '120px'
        },  {
            title: Resources.email,
            dataIndex: 'email',
            className: 'col-center',
            width: '120px'
        },
            {
                title: Resources.image,
                dataIndex: 'avatar',
                className: 'col-center',
                width: '80px',
                render: val => <Tooltip title={<Avatar src={`${Constants.imagePath}${val}`} shape="square" size={200} />}><Avatar src={`${Constants.imagePath}${val}`} shape="square" size="small" /></Tooltip>
            },
            {
                title: Resources.identifier,
                dataIndex: 'identifier',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.userType,
                dataIndex: 'type',
                className: 'col-center',
                width: '120px'
            },

            {
                title: Resources.roles,
                dataIndex: 'user_type',
                className: 'col-center',
                width: '120px'
            },
            {
            title: Resources.expert_house_name,
            dataIndex: 'expert_house_name',
            className: 'col-center',
            width: '120px',

        }, {
            title: Resources.real_state_role_name,
            dataIndex: 'real_state_role_name',
            className: 'col-center',
            width: '120px',

        },
            {
                title: Resources.country,
                dataIndex: 'country',
                className: 'col-center',
                width: '120px',

            },
            {
                title: Resources.city,
                dataIndex: 'city',
                className: 'col-center',
                width: '120px',

            },
            {
                title: Resources.neighborhood,
                dataIndex: 'neighborhood',
                className: 'col-center',
                width: '120px',

            },
            {
                title: Resources.nationalNumber,
                dataIndex: 'nationalNumber',
                className: 'col-center',
                width: '120px',

            },
            {
                title: Resources.address,
                dataIndex: 'address',
                className: 'col-center',
                width: '120px',

            },
            {
                title: Resources.aboutUs,
                dataIndex: 'about_us',
                className: 'col-center',
                width: '120px',

            },
            {
                title: Resources.phone,
                dataIndex: 'phone',
                className: 'col-center',
                width: '120px',

            },
            {
                title: Resources.website,
                dataIndex: 'website',
                className: 'col-center',
                width: '120px',

            },
            {
            title: Resources.status,
            dataIndex: 'status',
            className: 'col-center',
            width: '120px',
            render: (val, record) => <Switch size="small" checked={!!parseInt(val)} onClick={() => this.handleStatus(record)} disabled={!permissions.action} />
        },


        ];

        const filtredUsers = data.filter(o => {
            let query = false;

            if (filterUserSearch) {
                if (o.name && o.name.indexOf(filterUserSearch) >= 0)
                    query = true;
                if (o.mobile && o.mobile.indexOf(filterUserSearch) >= 0)
                    query = true;
                if (o.identifier.toString() && o.identifier.toString().indexOf(filterUserSearch) >= 0)
                    query = true;


            } else {
                query = true;
            }
            return query;
        });


        const onFinish = (values) => {
            this.setState({

                name: values.name,
                family: values.family,
                email: values.email,
                mobile: values.mobile,
                identifier: values.identifier,
            });
            this.fetch({
                name: this.state.name,
                family: this.state.family,
                email: this.state.email,
                mobile: this.state.mobile,
                identifier: this.state.identifier,
                page: this.state.pagination.current
            })
            console.log('Success:', values);
        };

        return (
            <Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item>{Resources.users}</Breadcrumb.Item>
                </Breadcrumb>
                <Col xs={24} md={4}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.showForm()} block>{Resources.add}</Button>
                </Col>
                <div className='ant-content'>

                        <Form ref={this.searchListFormRef} layout="vertical"   onFinish={onFinish} >


                            <Row gutter={24} style={{marginTop: "3%"}}>

                                <Col xs={24} md={6}>
                                    <Form.Item name="mobile"   label={Resources.mobile}>
                                        <Input type="number"   />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={6}>
                                    <Form.Item name="email"   label={Resources.email}>
                                        <Input type="text"   />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={6}>
                                    <Form.Item name="name"   label={Resources.firstName}>
                                        <Input type="text"   />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="family"   label={Resources.lastName}>
                                        <Input type="text"   />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="identifier"   label={Resources.identifier}>
                                        <Input type="text"   />
                                    </Form.Item>
                                </Col>


                                <Col xs={24} md={4} style={{marginTop: "3%"}}>

                                    <Button type="primary" htmlType="submit">{Resources.search}</Button>
                                </Col>

                            </Row>
                        </Form>
                </div>
                <div className="ant-content">
                    <Table
                        columns={columns}
                        dataSource={filtredUsers}
                        loading={loading}
                        onChange={this.handleTableChange}
                        pagination={pagination}
                        size="small"
                        rowKey="id"
                        // scroll={{ x: 900 }}
                    />
                    {!!pagination.total && <PaginationInfo current={pagination.current} defaultCurrent={pagination.current} pageSize={pagination.pageSize} total={pagination.total} />}
                </div>
                <PermissionForm selected={this.state.selected} permisionData={aclData} onCancel={this.hidePermission} onSubmit={this.handlePermissions} submitting={submitting} visible={visiblePermission} />
                <UserForm ref={this.formRef} onCancel={this.hideForm} onSubmit={this.handleSubmit} submitting={submitting} visible={visibleForm} />
                <LegalDevices ref={this.userAgentFormRef}   onCancel={this.hideUserAgentForm} onDelete={this.deleteAgent}  submitting={submitting} visible={visibleUserAgentForm} />
                {console.log(this.state.DrawerData)}

            </Fragment>
        );
    }
}

export default connect(
    (state) => {
        return {
            permissions: {
                action: CheckPermission(state.app.user, Permissions.user_option),
                add: CheckPermission(state.app.user, Permissions.user_store)
            }
        };
    },
    (dispatch) => {
        return {
            onSetRoute: (data) => dispatch(SetRoute(data)),
            onUpdateUser: (data) => dispatch(UpdateUser(data)),
        };
    }
)(UserManagement);
