import React, {Component, Fragment} from 'react';
import {cloneDeep} from "lodash";
import {Button, Col, Input, Modal, Row, Upload, Form, message, Select} from "antd";
import {ExceptionOutlined, MailOutlined, LayoutOutlined, LoadingOutlined, UploadOutlined} from '@ant-design/icons';
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import API from '../scripts/api'
import { EditorState, convertToRaw, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {stateToHTML} from "draft-js-export-html";
import {stateFromHTML} from 'draft-js-import-html';
import 'draft-js/dist/Draft.css';
import JoditEditor from "jodit-react";
class AddAboutUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            picList: [],
            Data: '',
            showDrawer: true,
            DrawerData: "",
            setContent: "",
            content: "",
            editorState: EditorState.createEmpty(),
        }
        this.formRef = React.createRef();
    }

    handleSubmit = (data = {}) => {
        const {picList} = this.state;
        const formData = new FormData();
        picList.map((item, i) => {
            formData.append(`images[]`, item)
        });
        formData.append('title', data.title);
        formData.append('description', this.state.DrawerData);

        // formData.append('description', stateToHTML(this.state.editorState.getCurrentContent()));


        this.props.onSubmit(formData);
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();

        this.setState({
            addresses: [{id: 0}],
            phones: []
        });
    };


    handleDidMount = (data = {}) => {
        this.setState({DrawerData:""})
        const cloneData = cloneDeep(data);
        this.formRef.current.setFieldsValue(cloneData);
        this.setState({editMode: !!cloneData.id});
        if(this.state.editMode){
            // this.setState({ editorState: EditorState.createWithContent(
            //         ContentState.createFromBlockArray(
            //             convertFromHTML(cloneData.description)
            //         )
            //     )});
            this.setState({DrawerData: cloneData.description})

            // this.setState({
            //     editorState:EditorState.createWithContent(
            //         stateFromHTML(cloneData.description)
            //     )
            // });
                // );
        }

    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    }
    changeDrawer = (data) => {
        console.log("data is: ", data)
        this.setState({DrawerData: data})

    }
    //
    // onEditorStateChangeShortDesc = (editorStateShortDesc) => {
    //     this.setState({
    //         editorStateShortDesc,
    //     });
    // }


    render() {
        const {submitting, visible} = this.props;
        const {picList, editMode, editorState, setContent, content} = this.state;
        const {TextArea} = Input;
        const picProps = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.picList.indexOf(file);
                    const newFileList = state.picList.slice();
                    newFileList.splice(index, 1);
                    return {picList: newFileList};
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    picList: [...state.picList, file],
                }));
                return false;
            },
            showUploadList: {
                showRemoveIcon: editMode
            },
            picList
        };
        const config = {
            readOnly: false,
            uploader: {
                insertImageAsBase64URI: true,
                video: true,
            }
        }

        return (
            <Fragment>
                <Modal
                    width='80%'
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onCancel={this.props.onCancel}
                    footer={null}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} onFinish={(value) => this.handleSubmit(value)} layout="vertical">
                        <Row gutter={24} style={{marginBottom: '15px'}}>
                            <Col xs={24} md={12}>
                                <Form.Item name="title" label={Resources.title} rules={Rules.required}>
                                    <Input disabled={this.props.submitting} type="text" prefix={<ExceptionOutlined/>}
                                           placeholder={Resources.title}/>
                                </Form.Item>
                            </Col>





                            {/*<Col xs={24} md={24}>*/}
                            {/*    <span> {Resources.description}</span>*/}
                            {/*    <Editor*/}
                            {/*        editorState={editorState}*/}
                            {/*        toolbarClassName="toolbarClassName"*/}
                            {/*        wrapperClassName="wrapperClassName"*/}
                            {/*        editorClassName="editorClassName"*/}
                            {/*        onEditorStateChange={this.onEditorStateChange}*/}
                            {/*    />;*/}
                            {/*</Col>*/}


                            <Col xs={24} md={24}>
                                <JoditEditor
                                    ref={this.formRef} // tabIndex of textarea
                                    tabIndex={3} // tabIndex of textarea
                                    value={this.state.DrawerData}
                                    config={config}
                                    // onChange={this.changeDrawer}
                                    onBlur={(newContent) => {
                                        this.setState({DrawerData: newContent})
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={12}>
                                <Upload  disabled={this.props.submitting} {...picProps} className="ant-upload-block">
                                    <Button block icon={
                                        <UploadOutlined/>}>{`${Resources.add} ${Resources.image}`}</Button>
                                </Upload>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button loading={this.props.submitting} style={{float: 'left'}} type="primary"
                                    htmlType="submit">
                                {Resources.confirm}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default AddAboutUs;
