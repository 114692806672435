import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom'
import queryString from 'query-string';
import {
    Breadcrumb,
    Button,
    Col,
    Input,
    Row,
    Form,
    Select, Radio, InputNumber, Table, Dropdown, Menu, Tooltip, Avatar, Switch
} from "antd";
import Resources from "../scripts/resources";
import {Constants, Permissions} from "../scripts/settings";
import API from "../scripts/api";
import {connect} from "react-redux";
import {CheckPermission} from "../scripts/helpers";
import {SetRoute, UpdateUser} from "../scripts/action";

import Rules from "../scripts/rules";
import {cloneDeep} from "lodash";

import { Circle, Map, TileLayer, Marker } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from "leaflet";


import { Upload, Modal } from 'antd';
import {EditOutlined, LockOutlined, MenuOutlined, PlusOutlined} from '@ant-design/icons';
import PaginationInfo from "../components/PaginationInfo";

class NewRequestHousingManagement extends Component {
    constructor() {
        super();

        this.state = {
            pagination: {
                total: 0,
                current: 1,
                pageSize: Constants.pageSize,
                size: 'small'
            },
            countryList: [],
            countrySearchSelected: "",
            cityList : [],
            citySearchSelected: "",
            data: [],
            dealTypesList: [],
            landTypeList: [],
            neighborhoodList: [],
            streetList: [],
            apiFormElements: [],
            dealTypeSelected:"",
            landTypeSelected:"",
            neighborhoodSelected:"",
            streetSelected:"",
            id:"",
            value:"",
            lat: Constants.defaultLat,
            lng: Constants.defaultLng,

            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            advertiseId: '',
            fileList: [],
            imageGallery: [],
            inputRef: React.createRef(),
            imageGalleryData: [],
            user: '',
            mobile: '',
            identifier: '',
            status: '',
            date_time: '',
            userNotesList: [],
        };
        this.formRef = React.createRef();
        this.statusFormRef = React.createRef();
    }


    async componentDidMount() {
        const { onSetRoute, routeName, parentName } = this.props;

        onSetRoute({ openKey: parentName, selectedKey: routeName });

        await this.getSearchCountries()
        await this.getDealTypes()

        let params = queryString.parse(this.props.location.search)
        if (params.id != undefined){
            this.setState({advertiseId: params.id,});
            await this.fetch(params.id)
        }else{
            this.loadMap({lat:36.17, lng:59.36})
        }

    }

    loadMap(data){
        console.log("fffffffffffffffff")
        this.setState({ lat: data.lat, lng: data.lng });
        const firstmap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        })

        let map = L.map('map', {
            center: [data.lat, data.lng],
            // tapTolerance: 25,
            tap: false,
            zoom: 9,
            maxZoom: 18,
            // zoomControl: false,
            layers: [firstmap],
        });
        var marker = L.marker([data.lat, data.lng]).addTo(map);
        const onMapClick = (e) => {
            // alert("You clicked the map at " + e.latlng);
            marker.setLatLng(e.latlng)
            console.log("e.lat", e.latlng)
            console.log("e", e)
            this.setState({ lat: e.latlng.lat, lng: e.latlng.lng });
        }

        map.on('click', onMapClick);
    }


    fetch =  (params = {}) => {
        this.setState({ loading: true });
         API.requestHousing.get(params)
            .then(async (result)  => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        data: result.data,
                    });
                    await this.setState({
                        mobile: result.mobile,
                        date_time: result.date_time,
                        user: result.user,
                        identifier: result.identifier,
                        status: result.status,

                    })
                    await this.statusFormRef.current.setFieldsValue({status: this.state.status, description: result.reject_reason});
                    console.log("result.data ", result.data)
                    let cloneData = cloneDeep(this.state.data);
                    await this.formRef.current.setFieldsValue(cloneData);
                    let {imageGallery} = this.state
                    await cloneData.image.map((img) => {
                        imageGallery.push({
                            uid: img._id,
                            name: img.path,
                            status: 'done',
                            url: `${Constants.imagePath}${img.path}`,
                        })
                    })

                    await this.setState({
                        id: cloneData.id,
                        dealTypeSelected : cloneData.deal_type_id,
                        landTypeSelected : cloneData.land_type_id,
                        countrySearchSelected : cloneData.country_id,
                        citySearchSelected : cloneData.city_id,
                        neighborhoodSelected : cloneData.neighborhood_id,
                        imageGallery : imageGallery,
                        userNotesList: result.notes
                    })
                    this.getSearchCountries()
                    this.getSearchCities(cloneData.country_id)
                    this.getSearchNeighborhoods(cloneData.city_id)
                    await this.getLandType(cloneData.deal_type_id)
                    await this.HandleLandTypeChange(cloneData.land_type_id)
                    await  this.loadMap({lat:cloneData.lat ?? 35.12, lng:cloneData.lng ?? 51.12})
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    handleUserSearch = (value) => {
        this.setState({ filterUserSearch: value });
    };
    handleSearchData = (value) => {
        console.log("ggggg", value)
        this.setState({ filterSearchData: value });
    };

    getSearchCountries = (params = {}) => {
        API.countries.list()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        countryList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    HandleCountryChange = (e) => {
        this.setState(
            {countrySearchSelected : e}
        )
        this.getSearchCities(e)
    }

    getSearchCities = (params = {}) => {
        this.setState({ loading: true });
        API.countryCities.list(params)

            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        cityList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    HandleCityChange = (e) => {
        this.setState(
            {citySearchSelected : e}
        )
        this.getSearchNeighborhoods(e)
    }

    getSearchNeighborhoods = (params = {}) => {
        this.setState({ loading: true });
        API.cityNeighbourhood.list(params)

            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        neighborhoodList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    HandleDealTypeChange = (e) => {
        this.setState(
            {dealTypeSelected : e}
        )
        this.getLandType(e)
    }
    HandleLandTypeChange = async (e) => {
        await this.setState({landTypeSelected : e})
        await this.ReturnFieldsForm()
    }
    getDealTypes = (params = {}) => {
        API.dealType.list()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        dealTypesList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getLandType = (params = {}) => {
        let paramsInput = {"deal_type_id" : params}


        API.landType.list(paramsInput)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        landTypeList: result.data,
                    });

                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    ReturnFieldsForm = () => {
        let InpuParams = {
            deal_type_id : this.state.dealTypeSelected,
            land_type_id : this.state.landTypeSelected,
        }
        API.requestHousing.formData(InpuParams)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        apiFormElements: result.data,
                    });

                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    handleCancel = () => this.setState({ previewVisible: false });
    handleChangeImage = (data) => {
        // this.setState({ fileList })

        if((data.file.status == "removed") && (data.file.originFileObj == undefined)){
            const params = {"advertiseId" : this.state.advertiseId, "imgId": data.file.uid}
            API.offerHousing.delImg(params)
                .then((result) => {
                    if (result) {
                        this.setState({
                            loading: false,

                        });
                    } else {
                        this.setState({ loading: false });
                    }
                })
                .catch(() => {
                    this.setState({ loading: false });
                });
        }

        this.setState({imageGallery: data.fileList, imageGalleryData: data.file})
    };

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };
     getBase64 = (file) =>{
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }


    /////////////save data

    render() {
        const{neighborhoodList,dealTypesList,landTypeList,streetList, apiFormElements, countryList, cityList,
        user, mobile, identifier, status, userNotesList, date_time} = this.state;
        const {submitting} = this.props;
        const { previewVisible, previewImage, imageGallery, previewTitle } = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        console.log("status is: ", status)
        const {TextArea} = Input;
        const onFinish = (values) => {

        }
        const changeStatus = (values) => {
            this.statusFormRef.current.validateFields()
                .then( async values =>  {
                    const formData = new FormData();
                   const id = (this.state.data) ? this.state.data._id : ""
                    console.log("values is: ", values)
                    formData.append("id", id)
                    formData.append("status", values.status)
                    formData.append("description", values.description)
                    formData.append("note", (values.note) ? values.note : "" )

                    API.requestHousing.manage(id,formData)
                        .then((result) => {
                            this.setState({
                                loading: false,
                            });
                            window.location.reload();
                        })
                        .catch(() => {
                            this.setState({ loading: false });
                        });
                });

        };

        function onChange(value) {
            console.log('changed', value);
        }
        const columns = [

            {
                title: Resources.user,
                dataIndex: 'user',
                className: 'col-center',
                width: '120px'
            },

            {
                title: Resources.note,
                dataIndex: 'note',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.status,
                dataIndex: 'status',
                className: 'col-center',
                width: '120px'
            },  {
                title: Resources.date,
                dataIndex: 'date',
                className: 'col-center',
                width: '120px'
            },


        ];
        return (
            <Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item>{Resources.newOfferHousing}</Breadcrumb.Item>
                </Breadcrumb>
                <div className='ant-content'>
                    <Form ref={this.formRef} layout="vertical"   onFinish={onFinish} >

                        <Row gutter={24} style={{marginTop: "3%"}}>
                            <Col xs={24} md={6}>
                                <Form.Item name="country_id" label={Resources.country}  >
                                    <Select onChange={(e) => this.HandleCountryChange(e)} allowClear={true}>
                                        {countryList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={6}>
                                <Form.Item name="city_id" label={Resources.city}  >
                                    <Select onChange={(e) => this.HandleCityChange(e)} allowClear={true}>
                                        {cityList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item name="neighborhood_id" label={Resources.neighborhood}  >
                                    <Select onChange={(e) => this.HandleNeighborhoodChange(e)} allowClear={true} mode="multiple">
                                        {neighborhoodList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>


                            <Col xs={24} md={6}>
                                <Form.Item name="deal_type_id" label={Resources.dealType}  >
                                    <Select onChange={(e) => this.HandleDealTypeChange(e)} allowClear={true}>
                                        {dealTypesList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item name="land_type_id" label={Resources.landType}  >
                                    <Select onChange={(e) => this.HandleLandTypeChange(e)} allowClear={true}>
                                        {landTypeList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col style={{background:"gray"}} xs={24} md={24}>
                                <h4>اطلاعات کاربر ثبت کننده</h4>
                            </Col>
                            <Col xs={24} md={6}>
                                <span>نام و نام خانوادگی: </span>
                                <span>{user}</span>
                            </Col>
                            <Col xs={24} md={6}>
                                <span>موبایل: </span>
                                <span>{mobile}</span>
                            </Col>
                            <Col xs={24} md={6}>
                                <span> شناسه کاربر: </span>
                                <span>{identifier}</span>
                            </Col>
                            <Col xs={24} md={6}>
                                <span>  زمان ثبت: </span>
                                <span style={{direction: "ltr", float: "left"}}>{date_time}</span>
                            </Col>
                            <Col style={{background:"gray"}} xs={24} md={24}>
                                <h4>اطلاعات کاربر</h4>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item name="user_name" label="نام " >
                                    <Input type="text"  />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item name="user_family" label="نام خانوادگی " >
                                    <Input type="text"  />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item name="user_mobile" label="موبایل " >
                                    <Input type="text"  />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={6}>
                                <Form.Item name="track_code" label="کد ملک" >
                                    <Input type="text"  />
                                </Form.Item>
                            </Col>
                            {
                                apiFormElements.map(item =>



                                    <Col xs={24} md={24}>

                                        { (item.field_type == "input_string")  ?

                                                <Form.Item name={item.name} label={item.show_label} rules={ !!item.required_field ? Rules.required : ""}>
                                                    <Input type="text" placeholder={item.placeholder}  />
                                                </Form.Item>

                                            :(item.field_type == "header")  ?
                                                <h4 className="id card-name" style={{
                                                    background: "#645a5a",
                                                    color: "white",
                                                    textAlign: "center",
                                                    fontSize: "large"}}>{item.show_label}</h4>

                                                :(item.field_type == "tab")  ?
                                                    <Form.Item name={item.name} label={item.show_label} rules={ !!item.required_field ? Rules.required : ""}>
                                                        <Input type="test" placeholder={item.placeholder}  />
                                                    </Form.Item>


                                            :((item.field_type == "input") && (item.input_type == "number"))  ?
                                                    // <Form.Item name={item.name} label={item.show_label} rules={ !!item.required_field ? Rules.required : ""}>
                                                    //     <Input type="number" placeholder={item.placeholder}  />
                                                    // </Form.Item>
                                                        <Form.Item name={item.name} label={item.show_label} style={{width:"400px"}} rules={ !!item.required_field ? Rules.required : ""}>
                                                            {/*<InputNumber type="number" placeholder={item.placeholder}  />*/}
                                                            <InputNumber
                                                                name={item.name}
                                                                style={{width: "300px"}}
                                                                placeholder={item.placeholder}
                                                                // defaultValue=""
                                                                formatter={value => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                onChange={onChange}
                                                            />

                                                        </Form.Item>

                                            :((item.field_type == "input") && (item.input_type == ""))  ?
                                                        <Form.Item name={item.name} label={item.show_label} rules={ !!item.required_field ? Rules.required : ""}>
                                                            <Input type="text" placeholder={item.placeholder}  />
                                                        </Form.Item>
                                            :((item.field_type == "input") && (item.input_type == "price"))  ?

                                                 <Form.Item name={item.name} label={item.show_label} style={{width:"400px"}} rules={ !!item.required_field ? Rules.required : ""}>
                                                        {/*<InputNumber type="number" placeholder={item.placeholder}  />*/}
                                                     <InputNumber
                                                         name={item.name}
                                                         style={{width: "300px"}}
                                                         placeholder={item.placeholder}
                                                         // defaultValue=""
                                                         formatter={value => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                         parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                         onChange={onChange}
                                                     />

                                                 </Form.Item>

                                                :item.field_type == "custom_select" ?
                                                <Form.Item name={item.name} label={item.show_label}  rules={ !!item.required_field ? Rules.required : ""}>
                                                    <Select>
                                                        {/*{item.selectValues.map(item => (*/}
                                                        {/*    <Select.Option key={item._id}  value={item._id}>{item.name}</Select.Option>*/}
                                                        {/*))}*/}
                                                    </Select>
                                                </Form.Item>
                                                :item.field_type == "custom_multiselect" ?
                                                    <Form.Item name={item.name} label={item.show_label}  rules={ !!item.required_field ? Rules.required : ""} >
                                                        <Select  mode="multiple" >
                                                            {/*{item.selectValues.map(item => (*/}
                                                            {/*    <Select.Option key={item._id}  value={item._id}>{item.name}</Select.Option>*/}
                                                            {/*))}*/}
                                                        </Select>
                                                    </Form.Item>

                                                :item.field_type == "textarea" ?
                                                    <Form.Item name={item.name} label={item.show_label} rules={ !!item.required_field ? Rules.required : ""} >
                                                        <TextArea  placeholder={item.placeholder}
                                                        />
                                                    </Form.Item>
                                                    : item.field_type == "radio" ?
                                                        <Form.Item name={item.name} label={item.show_label}  rules={ !!item.required_field ? Rules.required : ""}  >
                                                            <Radio.Group>
                                                                <Radio value="1">{Resources.yes}</Radio>
                                                                <Radio value="0" >{Resources.no}</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                        :

                                                            <Form.Item >
                                                            </Form.Item>
                                        }
                                    </Col>

                                )
                            }




                        </Row>


                        <h4 className="id card-name" style={{
                            background: "#645a5a",
                            color: "white",
                            textAlign: "center",
                            fontSize: "large"}}>موقعیت ملک</h4>

                        <div id="map" style={{width:"90%%", height:"400px"}}></div>

                        {apiFormElements && apiFormElements.length ?
                            <Col xs={24} md={24} style={{marginTop: "3%"}}>
                                <Button style={{width: "100%"}} type="primary" htmlType="submit">{Resources.registerInfo}</Button>
                            </Col>

                            :""
                        }
                    </Form>
                </div>

                <Form ref={this.statusFormRef} layout="vertical"   onFinish={changeStatus} >
                    <Row gutter={24} style={{marginTop: "3%"}}>

                        <Col xs={24} md={6}>
                            <Form.Item name="note"   label={Resources.note}>
                                <TextArea type="text"   />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={6}>
                            <Form.Item name="description"   label={Resources.reject_reason}>
                                <Input type="text"   />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="status" label={Resources.status}   >
                                <Select  >
                                    <Select.Option   value="0">{Resources.wait}</Select.Option>
                                    <Select.Option  value="1">{Resources.confirm}</Select.Option>
                                    <Select.Option  value="-1">{Resources.reject}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={4} style={{marginTop: "3%"}}>

                            <Button type="primary" htmlType="submit">{Resources.confirm}</Button>
                        </Col>

                    </Row>
                </Form>

                <div className="ant-content">
                    <Table
                        columns={columns}
                        dataSource={userNotesList}
                        // loading={loading}
                        // onChange={this.handleTableChange}
                        // pagination={pagination}
                        size="small"
                        rowKey="id"
                        // scroll={{ x: 900 }}
                    />
                    {/*{!!pagination.total && <PaginationInfo current={pagination.current} defaultCurrent={pagination.current} pageSize={pagination.pageSize} total={pagination.total} />}*/}
                </div>
            </Fragment>
        );
    }
}

export default withRouter( connect(
    (state) => {
        return {
            permissions: {
                action: CheckPermission(state.app.user, Permissions.user_option),
                add: CheckPermission(state.app.user, Permissions.user_store)
            }
        };
    },
    (dispatch) => {
        return {
            onSetRoute: (data) => dispatch(SetRoute(data)),
            onUpdateUser: (data) => dispatch(UpdateUser(data)),
        };
    }
)(NewRequestHousingManagement));
