import React, {Component, Fragment} from 'react';
import {Constants, Permissions} from "../scripts/settings";
import API from "../scripts/api";
import {
    Button,
    Col,
    Dropdown,
    Input,
    message,
    Modal,
    Row,
    Switch,
    Table,
    Tooltip,
    Checkbox,
    Breadcrumb,
    Menu,
    Avatar,
    Form, Select, Radio
} from "antd";
import {
    ReadOutlined,
    SearchOutlined,
    MenuOutlined,
    PlusOutlined,
    DeleteOutlined,
    ControlFilled
} from '@ant-design/icons'
import Resources from "../scripts/resources";
import {connect} from "react-redux";
import {CheckPermission} from "../scripts/helpers";
import {SetConsulting, SetRoute} from "../scripts/action";
import AddFormBuilderAdverOffer from "../forms/AddFormBuilderAdverOffer";
import EditSearchAdverOfferForm from "../forms/EditSearchAdverOfferForm";
import PaginationInfo from "../components/PaginationInfo";
import Rules from "../scripts/rules";

class FormBuilderAdverOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            Filter: '',
            dealTypesList: [],
            landTypeList: [],
            roles: [],
            priorities: [],
            priorities_detail_page: [],
            priorities_list_page: [],
            dealTypeSelected: false,
            landTypeSelected: false,
            show_list_type: "header",
            visibleAddForm: false,
            visibleAddSearchForm: false,
            show_name: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: Constants.pageSize,
                size: 'small'
            },
            selected: {}
        };

        this.formRef = React.createRef();
        this.showItemInSearchFormRef = React.createRef();
        this.searchListFormRef = React.createRef();
        this.showFieldForRoleFormRef = React.createRef();
    }

    fetch = (params = {}) => {
        const { filterLevel, filterSearch } = this.state;
        this.setState({ priorities: [], priorities_detail_page: [],  priorities_list_page: [], data: []})
        params.q = filterSearch;
        params.level = filterLevel;

        this.setState({ loading: true });
        console.log("my params", params)

        API.formBuilderAdverOffer.list(params)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        data: result.data,
                        pagination: {
                            ...this.state.pagination, ...{
                                current: result.current_page,
                                pageSize: result.per_page,
                                total: result.total
                            }
                        }
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    componentDidMount= async () => {
        const { onSetRoute, routeName, parentName } = this.props;
        onSetRoute({ openKey: parentName, selectedKey: routeName });

        await this.getDealTypes()
        await this.getRoles()
        await this.fetch({
            deal_type_id: this.state.dealTypeSelected,
            land_type_id: this.state.landTypeSelected,
            show_list_type: this.state.show_list_type,
            show_name:  this.state.show_name,
            name:  this.state.name,
            placeholder:  this.state.placeholder,
            page: this.state.pagination.current,
        })

    }

    HandleDealTypeChange = (e) => {
        this.setState(
            {dealTypeSelected : e}
        )
        this.getLandType(e)
    }

    HandleLandTypeChange = (e) => {
        this.setState(
            {landTypeSelected : e}
        )
    }

    HandleChangeListType = (e) => {
        this.setState(
            {show_list_type : e}
        )
    }

    getDealTypes = (params = {}) => {
        API.dealTypesListWithType.offerList()
            .then((result) => {
                if (result.data) {
                    console.log("first deal type id is: ", result.data[0].id)
                    this.setState({
                        loading: false,
                        dealTypeSelected: result.data[0].id,
                        dealTypesList: result.data,

                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getLandType = (params = {}) => {
        let paramsInput = {"deal_type_id" : params}


        API.landType.list(paramsInput)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        landTypeList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    showInSearchForm = (item = {}) => {
        this.setState({ visibleAddSearchForm: true, selected: item });

        setTimeout(() => {
            this.showItemInSearchFormRef.current.handleDidMount(item);
        }, 0);
    };

    hideInSearchForm = () => {
        this.setState({ visibleAddSearchForm: false });

        setTimeout(() => {
            this.showItemInSearchFormRef.current.handleWillUnmount();
        }, 0);
    };

    showAddForm = (user = {}) => {
        this.setState({ visibleAddForm: true, selected: user });

        setTimeout(() => {
            this.formRef.current.handleDidMount(user);
        }, 0);
    };

    hideAddForm = () => {
        this.setState({ visibleAddForm: false });

        setTimeout(() => {
            this.formRef.current.handleWillUnmount();
        }, 0);
    };

    handleChangeSearchItem = (values) => {
        this.setState({ submitting: true });
        console.log("values is:", values)
        API.formBuilderAdverOffer.show_in_search(values)
            .then((result) => {
                this.setState({ submitting: false });
                this.hideInSearchForm();
                message.success(result.message);
                this.fetch({
                    deal_type_id: this.state.dealTypeSelected,
                    land_type_id: this.state.landTypeSelected,
                    show_list_type: this.state.show_list_type,
                    show_name:  this.state.show_name,
                    name:  this.state.name,
                    placeholder:  this.state.placeholder,
                    page: this.state.pagination.current,
                })
            })
            .catch(() => {
                this.setState({ submitting: false });
            });

    };

    handleAdd = (values, selectOptions, i) => {
        console.log("values is: ", values)
        console.log("selectOptionsArr is: ", selectOptions)
        this.setState({ submitting: true });
        const action = i ? API.formBuilderAdverOffer.update : API.formBuilderAdverOffer.add;

        if(action === API.formBuilderAdverOffer.update){
            values.id = i
        }
        values.selectOptions = selectOptions
        action(values)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideAddForm();
                this.fetch({
                    deal_type_id: this.state.dealTypeSelected,
                    land_type_id: this.state.landTypeSelected,
                    show_list_type: this.state.show_list_type,
                    show_name:  this.state.show_name,
                    name:  this.state.name,
                    placeholder:  this.state.placeholder,
                    page: this.state.pagination.current,
                })
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };

    handleFilter = (value) => {
        this.setState({ Filter: value });
    }
    handleDelete = (record) => {
        Modal.confirm({
            title: Resources.confirmDelete,
            okText: Resources.yes,
            okType: 'danger',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({loading : true});
                API.formBuilderAdverOffer.delete(record.id)
                    .then(res => {
                        message.success(Resources.successful);
                        this.fetch({
                            deal_type_id: this.state.dealTypeSelected,
                            land_type_id: this.state.landTypeSelected,
                            show_list_type: this.state.show_list_type,
                            show_name:  this.state.show_name,
                            name:  this.state.name,
                            placeholder:  this.state.placeholder,
                            page: this.state.pagination.current,
                        })
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };

    handleShowInSearch_old = (record) => {
        Modal.confirm({
            title: (record.show_in_search == "yes") ? Resources.confirmDeactivate : Resources.confirmActivate,
            okText: Resources.yes,
            okType: (record.show_in_search == "yes") ? 'danger' : 'primary',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({ loading: true });
                API.formBuilderAdverOffer.show_in_search(record.id)
                    .then((result) => {
                        message.success(result.message);
                        this.fetch({
                            deal_type_id: this.state.dealTypeSelected,
                            land_type_id: this.state.landTypeSelected,
                            show_list_type: this.state.show_list_type,
                            show_name:  this.state.show_name,
                            name:  this.state.name,
                            placeholder:  this.state.placeholder,
                            page: this.state.pagination.current,
                        })
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };

    handleShowInSlider = (record) => {
        Modal.confirm({
            title: (record.show_in_slider == "yes") ? Resources.confirmDeactivate : Resources.confirmActivate,
            okText: Resources.yes,
            okType: (record.show_in_slider == "yes") ? 'danger' : 'primary',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({ loading: true });
                API.formBuilderAdverOffer.show_in_slider(record.id)
                    .then((result) => {
                        message.success(result.message);
                        this.fetch({
                            deal_type_id: this.state.dealTypeSelected,
                            land_type_id: this.state.landTypeSelected,
                            show_list_type: this.state.show_list_type,
                            show_name:  this.state.show_name,
                            name:  this.state.name,
                            placeholder:  this.state.placeholder,
                            page: this.state.pagination.current,
                        })
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };
    managePriority = (record, param) => {
        console.log(param.target.value)
        let {priorities} = this.state
        let prioritiesFilter = priorities.filter(function( obj ) {
            return obj.id !== record.id;
        });
        let newPriority = {id: record.id, value: param.target.value }
        prioritiesFilter.push(newPriority)
        this.setState({ priorities: prioritiesFilter })
    };
    managePriorityInDetailPage = (record, param) => {
        console.log(param.target.value)
        let {priorities_detail_page} = this.state
        let prioritiesFilter = priorities_detail_page.filter(function( obj ) {
            return obj.id !== record.id;
        });
        let newPriority = {id: record.id, value: param.target.value }
        prioritiesFilter.push(newPriority)
        this.setState({ priorities_detail_page: prioritiesFilter })
    };
    managePriorityInListPage = (record, param) => {
        console.log(param.target.value)
        let {priorities_list_page} = this.state
        let prioritiesFilter = priorities_list_page.filter(function( obj ) {
            return obj.id !== record.id;
        });
        let newPriority = {id: record.id, value: param.target.value }
        prioritiesFilter.push(newPriority)
        this.setState({ priorities_list_page: prioritiesFilter })
    };

    handleShowAsButtons = (record) => {
        Modal.confirm({
            title: (record.show_as_buttons == "yes") ? Resources.confirmDeactivate : Resources.confirmActivate,
            okText: Resources.yes,
            okType: (record.show_as_buttons == "yes") ? 'danger' : 'primary',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({ loading: true });
                API.formBuilderAdverOffer.show_as_buttons(record.id)
                    .then((result) => {
                        message.success(result.message);
                        this.fetch({
                            deal_type_id: this.state.dealTypeSelected,
                            land_type_id: this.state.landTypeSelected,
                            show_list_type: this.state.show_list_type,
                            show_name:  this.state.show_name,
                            name:  this.state.name,
                            placeholder:  this.state.placeholder,
                            page: this.state.pagination.current,
                        })
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };
    registerPriorities = () => {
        let params = {
            priorities: this.state.priorities,
            priorities_detail_page: this.state.priorities_detail_page,
            priorities_list_page: this.state.priorities_list_page
        }
        this.setState({ loading: true });
        API.formBuilderAdverOffer.setPriorities(params)
            .then((result) => {
                message.success(result.message);
                this.fetch({
                    deal_type_id: this.state.dealTypeSelected,
                    land_type_id: this.state.landTypeSelected,
                    show_list_type: this.state.show_list_type,
                    show_name:  this.state.show_name,
                    name:  this.state.name,
                    placeholder:  this.state.placeholder,
                    page: this.state.pagination.current,
                })
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    handleTableChange = (pagination) => {
        // this.fetch({ page: pagination.current });
        this.fetch({
            deal_type_id: this.state.dealTypeSelected,
            land_type_id: this.state.landTypeSelected,
            show_list_type: this.state.show_list_type,
            show_name:  this.state.show_name,
            name:  this.state.name,
            placeholder:  this.state.placeholder,
            page: pagination.current
        })
    }
    getRoles = (params = {}) => {
        this.setState({ loading: true });
        API.roles.list()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        roles: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    render() {
        const{ visibleAddForm, visibleAddSearchForm, submitting, data, landTypeList, Filter, loading, dealTypesList, pagination, roles} = this.state;
        const { permissions } = this.props;
        // const [form] = Form;
        const columns = [
            {
                title: Resources.action,
                dataIndex: 'id',
                className: 'col-center',
                width: '80px',
                render: (val, record) => (
                    <Dropdown overlay={<Menu>
                        <Menu.Item>
                            <span onClick={() => this.showAddForm(record)}><ReadOutlined /> {Resources.edit}</span>
                        </Menu.Item>
                        <Menu.Item>
                            <span onClick={() => this.handleDelete(record)}><DeleteOutlined /> {Resources.delete}</span>
                        </Menu.Item>



                    </Menu>}>
                        <Button type="link" icon={<MenuOutlined />} />
                    </Dropdown>
                )
            },
            {
                title: Resources.show_in_search,
                dataIndex: 'show_in_search',
                className: 'col-center',
                width: '120px',
                render: (val, record) => <Switch size="small" checked={!!(val == "yes")} onClick={() => this.showInSearchForm(record)}  />
            },
            {
                title: Resources.fieldType,
                dataIndex: 'item_type_show',
                className: 'col-center',
                width: '120px'
            },
            // {
            //     title: Resources.dealType,
            //     dataIndex: 'deal_type_show',
            //     className: 'col-center',
            //     width: '120px'
            // },
            // {
            //     title: Resources.landType,
            //     dataIndex: 'land_type_show',
            //     className: 'col-center',
            //     width: '120px'
            // },

            {
                title: Resources.show_title,
                dataIndex: 'show_label',
                className: 'col-center',
                width: '120px'
            },

            // {
            //     title: Resources.roles,
            //     dataIndex: 'show_for_roles',
            //     className: 'col-center',
            //     width: '120px'
            // },
            {
                title: Resources.placeholder,
                dataIndex: 'placeholder',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.priority,
                dataIndex: 'priority',
                className: 'col-center',
                width: '120px',
                render: (val, record) =>
                    <Form.Item initialValue={record.priority}
                               name={`priority-${record.id}`}
                               onChange={(e) => this.managePriority(record, e)}
                    >
                        <Input type="text" dir="rtl"  />
                    </Form.Item>
            },
            {
                title: Resources.show_in_detail_page,
                dataIndex: 'show_in_detail_page',
                className: 'col-center',
                width: '120px',
                render: (val, record) =>
                    <Form.Item initialValue={record.show_in_detail_page}
                               name={`show_in_detail_page-${record.id}`}
                               onChange={(e) => this.managePriorityInDetailPage(record, e)}
                    >
                        <Input type="text" dir="rtl"  />
                    </Form.Item>
            },
            {
                title: Resources.show_in_site_position,
                dataIndex: 'show_position_in_site',
                className: 'col-center',
                width: '120px',
                render: (val, record) =>
                    <Form.Item initialValue={record.show_position_in_site}
                               name={`show_position_in_site-${record.id}`}
                               onChange={(e) => this.managePriorityInListPage(record, e)}
                    >
                        <Input type="text" dir="rtl"  />
                    </Form.Item>
            },

            {
                title: Resources.required,
                dataIndex: 'required_field',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.show_in_slider,
                dataIndex: 'show_in_slider',
                className: 'col-center',
                width: '120px',
                render: (val, record) => <Switch size="small" checked={!!(val == "yes")} onClick={() => this.handleShowInSlider(record)}  />
            },
            {
                title: Resources.show_as_buttons,
                dataIndex: 'show_as_buttons',
                className: 'col-center',
                width: '120px',
                render: (val, record) => <Switch size="small" checked={!!(val == "yes")} onClick={() => this.handleShowAsButtons(record)}  />
            },

            {
                title: Resources.name,
                dataIndex: 'name',
                className: 'col-center',
                width: '120px'
            },


            // {
            //     title: Resources.inputType,
            //     dataIndex: 'input_type_show',
            //     className: 'col-center',
            //     width: '120px'
            // },
            {
                title: Resources.min_length,
                dataIndex: 'min_length',
                className: 'col-center',
                width: '120px'
            },

            {
                title: Resources.max_length,
                dataIndex: 'max_length',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.description,
                dataIndex: 'description',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.unit,
                dataIndex: 'unit',
                className: 'col-center',
                width: '120px'
            },
            // {
            //     title: Resources.api_data,
            //     dataIndex: 'api_data_show',
            //     className: 'col-center',
            //     width: '120px'
            // },
            //
            // {
            //     title: Resources.api_data_parent,
            //     dataIndex: 'api_data_parent_show',
            //     className: 'col-center',
            //     width: '120px'
            // },

            {
                title: Resources.col_count_in_row,
                dataIndex: 'col_count',
                className: 'col-center',
                width: '120px'
            },

            {
                title: Resources.isOwner,
                dataIndex: 'is_owner_show',
                className: 'col-center',
                width: '120px'
            },

        ];

        const NewsData = data.filter(o => {
            let query = false;

            if (Filter) {
                if (o.link && o.link.indexOf(Filter) >= 0)
                    query = true;

                if (o.section && o.section.indexOf(Filter) >= 0)
                    query = true;
            } else {
                query = true;
            }
            return query;
        });
        const onFinish = (values) => {
            this.setState({
                dealTypeSelected: values.deal_type_id,
                landTypeSelected: values.land_type_id,
                show_list_type: values.show_list_type,
                show_name: values.show_name,
                name: values.name,
                placeholder: values.placeholder,
            });
            this.fetch({deal_type_id: this.state.dealTypeSelected,
                land_type_id: this.state.landTypeSelected,
                show_list_type: this.state.show_list_type,
                show_name:  this.state.show_name,
                name:  this.state.name,
                placeholder:  this.state.placeholder,
            })
            console.log('Success:', values);
        };
        const setFieldForRole = (values) => {
            values.deal_type_id = this.state.dealTypeSelected;
            values.land_type_id = this.state.landTypeSelected;
            values.show_list_type = this.state.show_list_type;
            values.show_name =  this.state.show_name;
            values.name =  this.state.name;
            values.placeholder =  this.state.placeholder;

            this.setState({ loading: true });
            API.formBuilderAdverOffer.setFieldForRole(values)
                .then((result) => {
                    if (result.data) {
                        this.setState({
                            loading: false,
                        });

                    } else {
                        this.setState({ loading: false });
                    }
                    this.fetch({deal_type_id: this.state.dealTypeSelected,
                        land_type_id: this.state.landTypeSelected,
                        show_list_type: this.state.show_list_type,
                        show_name:  this.state.show_name,
                        name:  this.state.name,
                        placeholder:  this.state.placeholder,
                    })
                })
                .catch(() => {
                    this.setState({ loading: false });
                });
        };

        return (
            <Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item>{Resources.advertiseOfferFormBuilder}</Breadcrumb.Item>
                </Breadcrumb>
                <Fragment>
                    <Form ref={this.searchListFormRef} layout="vertical"   onFinish={onFinish} >
                        <Row gutter={24}>
                            <Col xs={24} md={4}>
                                <Button type="primary" icon={<PlusOutlined />} onClick={() => this.showAddForm()} block>{Resources.add}</Button>
                            </Col>
                        </Row>

                        <Row gutter={24} style={{marginTop: "3%"}}>
                            <Col xs={24} md={6}>
                                <Form.Item name="deal_type_id" label={Resources.dealType}   >
                                    <Select onChange={(e) => this.HandleDealTypeChange(e)}>
                                        {dealTypesList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item name="land_type_id" label={Resources.landType}  >
                                    <Select onChange={(e) => this.HandleLandTypeChange(e)}>
                                        {landTypeList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={6}>
                                <Form.Item name="show_name"   label={Resources.show_title}>
                                    <Input type="text" placeholder={Resources.show_title}  />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={6}>
                                <Form.Item name="name"   label={Resources.name}>
                                    <Input type="text" placeholder={Resources.name}  />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item name="show_list_type" label={Resources.show_list_type} initialValue="header"  >
                                    <Select onChange={(e) => this.HandleChangeListType(e)}>
                                            <Select.Option   value="header">header</Select.Option>
                                            <Select.Option   value="items">items</Select.Option>
                                    </Select>
                                </Form.Item>

                            </Col>

                            {/*<Col xs={24} md={6}>*/}
                            {/*    <Form.Item name="placeholder"   label={Resources.placeholder}>*/}
                            {/*        <Input type="text" placeholder={Resources.placeholder}  />*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}

                            <Col xs={24} md={4} style={{marginTop: "3%"}}>
                                <Button type="primary" htmlType="submit">{Resources.search}</Button>
                            </Col>

                        </Row>
                    </Form>
                    <Row>
                        <Col xs={24} md={4} style={{marginTop: "3%"}}>
                            <Button type="primary" onClick={() => this.registerPriorities()} >{Resources.RegisterPriorities}</Button>
                        </Col>
                    </Row>
                    {/*<Form ref={this.showFieldForRoleFormRef} layout="vertical"   onFinish={setFieldForRole} >*/}
                    {/*    <Row gutter={24} style={{marginTop: "3%"}}>*/}
                    {/*    <Col xs={24} md={6}>*/}
                    {/*        <Form.Item  required={true}  name="roles" label={Resources.roles} >*/}
                    {/*            <Select mode="multiple">*/}
                    {/*                {roles.map(item => (*/}
                    {/*                    <Select.Option title={item.name}   style={{direction:"rtl"}} key={item.id}  value={item.id}>*/}
                    {/*                        {item.name }*/}
                    {/*                    </Select.Option>*/}
                    {/*                ))}*/}
                    {/*            </Select>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Col>*/}
                    {/*    <Col xs={24} md={3}>*/}
                    {/*        <Form.Item required={true}  name="type" label={Resources.type} >*/}
                    {/*            <Select >*/}
                    {/*                <Select.Option   style={{direction:"rtl"}}  value="add">افزودن</Select.Option>*/}
                    {/*                <Select.Option   style={{direction:"rtl"}}  value="delete">حذف</Select.Option>*/}
                    {/*            </Select>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Col>*/}


                    {/*    <Col xs={24} md={3} style={{marginTop: "3%"}}>*/}
                    {/*        <Button type="primary" htmlType="submit">{Resources.save}</Button>*/}
                    {/*    </Col>*/}

                    {/*</Row>*/}
                    {/*</Form>*/}
                </Fragment>
                <Form >
                <div className="ant-content">
                    <Table
                        columns={columns}
                        dataSource={NewsData}
                        loading={loading}
                        onChange={this.handleTableChange}
                        pagination={pagination}
                        size="small"
                        rowKey="id"
                        scroll={{ x: 1300, y: 600 }}
                    />
                    {!!pagination.total && <PaginationInfo current={pagination.current} defaultCurrent={pagination.current} pageSize={pagination.pageSize} total={pagination.total} />}
                </div>
                </Form>
                <AddFormBuilderAdverOffer ref={this.formRef} onCancel={this.hideAddForm} onSubmit={this.handleAdd} submitting={submitting} visible={visibleAddForm} />
                <EditSearchAdverOfferForm ref={this.showItemInSearchFormRef} onCancel={this.hideInSearchForm} onSubmit={this.handleChangeSearchItem} submitting={submitting} visible={visibleAddSearchForm} />
            </Fragment>
        );
    }
}

export default connect(
    (state) => {
        return {
            permissions: {
                action: CheckPermission(state.app.user, Permissions.user_option),
                add: CheckPermission(state.app.user, Permissions.user_store)
            }
        };
    },
    (dispatch) => {
        return {
            onSetRoute: (data) => dispatch(SetRoute(data)),
            setConsulting: data => dispatch(SetConsulting(data))
        };
    }
)(FormBuilderAdverOffer);