import React, {Component, Fragment, useState} from 'react';
import {cloneDeep} from "lodash";
import {Col, Modal, Row, Input, Form, Upload, Select, Radio, Button} from "antd";
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import {ProjectOutlined, InboxOutlined, DeploymentUnitOutlined, ExceptionOutlined} from '@ant-design/icons';
import API from "../scripts/api";

class EditSearchAdverOfferForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldTypeList:[]
        };

        this.showItemInSearchFormRef = React.createRef();
        // this.mapRef = React.createRef();

    }



    handleSubmit = () => {
        let {selectOptionsArr} = this.state
        let names = []
        this.showItemInSearchFormRef.current.validateFields()
            .then(values => {
                const formData = new FormData;
                formData.append('search_col_count_in_row', values.search_col_count_in_row);
                if(values.search_item_type_id != undefined){
                    formData.append('search_item_type_id', values.search_item_type_id);
                }
                if(values.search_item_type_id != undefined){
                    formData.append('show_in_search', values.show_in_search);
                }
                if(values.search_priority != undefined){
                    formData.append('search_priority', values.search_priority);
                }


                formData.append('id', this.state.id);
                this.props.onSubmit(formData);
            });
    };

    handleWillUnmount = () => {
        this.showItemInSearchFormRef.current.resetFields();
    };

    handleDidMount = async (data = {}) => {

        const cloneData = cloneDeep(data);
        console.log(!!cloneData.id);


         this.setState({editMode: !!cloneData.id, id: cloneData.id});
        if( this.state.editMode){
            console.log("cloneData", cloneData)
        }
        this.showItemInSearchFormRef.current.setFieldsValue(cloneData);
        this.getFieldType()
    }
    getFieldType = (params = {}) => {
        this.setState({ loading: true });
        API.fieldType.list()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        fieldTypeList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };


    render() {
        const {onCancel, submitting, visible} = this.props;
        const {fieldTypeList } = this.state; // INJA STATE HATO MIYARI TO RENDER INJA FARAKHONI MISHE AZ BALA
        const {TextArea} = Input;

        return (

            <Fragment>
                <Modal
                    title={ Resources.show_in_search}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                    width={1000}
                >
                    <Form ref={this.showItemInSearchFormRef} layout="vertical"  >
                        <Row gutter={24} >
                            <Col xs={24} md={12}>

                                <Form.Item name="search_col_count_in_row" label={Resources.search_col_count_in_row}  >
                                    <Select
                                    showSearch
                                    >
                                        <Select.Option   value="4">4</Select.Option>
                                        <Select.Option   value="5">5</Select.Option>
                                        <Select.Option   value="6">6</Select.Option>
                                        <Select.Option   value="7">7</Select.Option>
                                        <Select.Option   value="8">8</Select.Option>
                                        <Select.Option   value="9">9</Select.Option>
                                        <Select.Option   value="10">10</Select.Option>
                                        <Select.Option   value="11">11</Select.Option>
                                        <Select.Option   value="12">12</Select.Option>
                                        <Select.Option   value="13">13</Select.Option>
                                        <Select.Option   value="14">14</Select.Option>
                                        <Select.Option   value="15">15</Select.Option>
                                        <Select.Option   value="16">16</Select.Option>
                                        <Select.Option   value="17">17</Select.Option>
                                        <Select.Option   value="18">18</Select.Option>
                                        <Select.Option   value="19">19</Select.Option>
                                        <Select.Option   value="20">20</Select.Option>
                                        <Select.Option   value="21">21</Select.Option>
                                        <Select.Option   value="22">22</Select.Option>
                                        <Select.Option   value="23">23</Select.Option>
                                        <Select.Option   value="24">24</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item  name="search_item_type_id" label={Resources.fieldType} >
                                    <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) => {
                                            return (
                                                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );

                                        }}
                                        // onChange={(e) => this.HandleChangeItemType(e)}
                                        >
                                        {fieldTypeList.map(item => (
                                            <Select.Option title={item.name} key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="search_priority" label={Resources.priority} >
                                    <Input type="number" prefix={<ProjectOutlined/>} placeholder={Resources.priority}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="show_in_search" label={Resources.show_in_search}  >
                                    <Radio.Group>
                                        <Radio value="yes">{Resources.yes}</Radio>
                                        <Radio value="no" >{Resources.no}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default EditSearchAdverOfferForm;
