import React, {Component, Fragment} from 'react';
import {Constants, Permissions} from "../scripts/settings";
import API from "../scripts/api";
import {
    Button,
    Col,
    Dropdown,
    Input,
    message,
    Modal,
    Row,
    Switch,
    Table,
    Tooltip,
    Checkbox,
    Breadcrumb,
    Menu,
    Avatar,
    Form, Select
} from "antd";
import {
    ReadOutlined,
    SearchOutlined,
    MenuOutlined,
    PlusOutlined,
    DeleteOutlined,
    ControlFilled
} from '@ant-design/icons'
import Resources from "../scripts/resources";
import {connect} from "react-redux";
import {CheckPermission} from "../scripts/helpers";
import {SetConsulting, SetRoute} from "../scripts/action";
import AddFormBuilderAdverOffer from "../forms/AddFormBuilderSearchAdverOffer";
import Rules from "../scripts/rules";

class FormBuilderSearchAdverOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            Filter: '',
            dealTypesList: [],
            landTypeList: [],
            dealTypeSelected: false,
            landTypeSelected: false,
            show_name: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: Constants.pageSize,
                size: 'small'
            },
            selected: {}
        };

        this.formRef = React.createRef();
        this.searchListFormRef = React.createRef();
    }

    fetch = (params = {}) => {
        const { filterLevel, filterSearch } = this.state;

        params.q = filterSearch;
        params.level = filterLevel;

        this.setState({ loading: true });
        console.log("my params", params)

        API.formBuilderSearchAdverOffer.list(params)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        data: result.data,
                        pagination: {
                            ...this.state.pagination, ...{
                                current: result.data.current_page,
                                pageSize: result.data.per_page,
                                total: result.data.total
                            }
                        }
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    componentDidMount() {
        const { onSetRoute, routeName, parentName } = this.props;
        onSetRoute({ openKey: parentName, selectedKey: routeName });
        this.fetch({deal_type_id: this.state.dealTypeSelected,
            land_type_id: this.state.landTypeSelected,
            show_name:  this.state.show_name,
        })
        this.getDealTypes()

    }

    HandleDealTypeChange = (e) => {
        this.setState(
            {dealTypeSelected : e}
        )
        this.getLandType(e)
    }
    HandleLandTypeChange = (e) => {
        this.setState(
            {landTypeSelected : e}
        )
    }

    getDealTypes = (params = {}) => {
        API.dealTypesListWithType.offerList()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        dealTypesList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getLandType = (params = {}) => {
        let paramsInput = {"deal_type_id" : params}


        API.landType.list(paramsInput)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        landTypeList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    showAddForm = (user = {}) => {
        this.setState({ visibleAddForm: true, selected: user });

        setTimeout(() => {
            this.formRef.current.handleDidMount(user);
        }, 0);
    };

    hideAddForm = () => {
        this.setState({ visibleAddForm: false });

        setTimeout(() => {
            this.formRef.current.handleWillUnmount();
        }, 0);
    };

    handleAdd = (values, i) => {
        console.log("id is: ", values, i)
        this.setState({ submitting: true });
        const action = i ? API.formBuilderSearchAdverOffer.update : API.formBuilderSearchAdverOffer.add;

        if(action === API.formBuilderSearchAdverOffer.update){
            values.id = i
        }

        action(values)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideAddForm();
                this.fetch({deal_type_id: this.state.dealTypeSelected,
                    land_type_id: this.state.landTypeSelected,
                    show_name:  this.state.show_name,
                })
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };

    handleFilter = (value) => {
        this.setState({ Filter: value });
    }
    handleDelete = (record) => {
        Modal.confirm({
            title: Resources.confirmDelete,
            okText: Resources.yes,
            okType: 'danger',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({loading : true});
                API.formBuilderSearchAdverOffer.delete(record.id)
                    .then(res => {
                        message.success(Resources.successful);
                        this.fetch({deal_type_id: this.state.dealTypeSelected,
                            land_type_id: this.state.landTypeSelected,
                            show_name:  this.state.show_name,
                        })
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };

    handleShowInFirstPage = (record) => {
        Modal.confirm({
            title: (record.show_in_first_page == "yes") ? Resources.confirmDeactivate : Resources.confirmActivate,
            okText: Resources.yes,
            okType: (record.show_in_first_page == "yes") ? 'danger' : 'primary',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({ loading: true });
                API.formBuilderSearchAdverOffer.show_in_first_page(record.id)
                    .then((result) => {
                        message.success(result.message);
                        this.fetch({deal_type_id: this.state.dealTypeSelected,
                            land_type_id: this.state.landTypeSelected,
                            show_name:  this.state.show_name,
                        })
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };

    handleShowInSecondPage = (record) => {
        Modal.confirm({
            title: (record.show_in_second_page == "yes") ? Resources.confirmDeactivate : Resources.confirmActivate,
            okText: Resources.yes,
            okType: (record.show_in_second_page == "yes") ? 'danger' : 'primary',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({ loading: true });
                API.formBuilderSearchAdverOffer.show_in_second_page(record.id)
                    .then((result) => {
                        message.success(result.message);
                        this.fetch({deal_type_id: this.state.dealTypeSelected,
                            land_type_id: this.state.landTypeSelected,
                            show_name:  this.state.show_name,
                        })
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };



    render() {
        const{ visibleAddForm, submitting, data, landTypeList, Filter, loading, dealTypesList } = this.state;
        const { permissions } = this.props;
        // const [form] = Form;
        const columns = [
            {
                title: Resources.action,
                dataIndex: 'id',
                className: 'col-center',
                width: '80px',
                render: (val, record) => (
                    <Dropdown overlay={<Menu>
                        <Menu.Item>
                            <span onClick={() => this.showAddForm(record)}><ReadOutlined /> {Resources.edit}</span>
                        </Menu.Item>
                        <Menu.Item>
                            <span onClick={() => this.handleDelete(record)}><DeleteOutlined /> {Resources.delete}</span>
                        </Menu.Item>



                    </Menu>}>
                        <Button type="link" icon={<MenuOutlined />} />
                    </Dropdown>
                )
            },
            {
                title: Resources.dealType,
                dataIndex: 'deal_type_show',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.landType,
                dataIndex: 'land_type_show',
                className: 'col-center',
                width: '120px'
            },

            {
                title: Resources.show_title,
                dataIndex: 'show_label',
                className: 'col-center',
                width: '120px'
            },

            {
                title: Resources.search_field,
                dataIndex: 'field_show',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.search_type,
                dataIndex: 'search_type',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.placeholder,
                dataIndex: 'placeholder',
                className: 'col-center',
                width: '120px'
            },

            {
                title: Resources.priority,
                dataIndex: 'priority',
                className: 'col-center',
                width: '120px'
            },

            {
                title: Resources.show_in_first_page,
                dataIndex: 'show_in_first_page',
                className: 'col-center',
                width: '120px',
                render: (val, record) => <Switch size="small" checked={!!(val == "yes")} onClick={() => this.handleShowInFirstPage(record)}  />
            },

            {
                title: Resources.show_in_second_page,
                dataIndex: 'show_in_second_page',
                className: 'col-center',
                width: '120px',
                render: (val, record) => <Switch size="small" checked={!!(val == "yes")} onClick={() => this.handleShowInSecondPage(record)}  />
            },

            {
                title: Resources.name,
                dataIndex: 'name',
                className: 'col-center',
                width: '120px'
            },

            {
                title: Resources.col_count_in_row,
                dataIndex: 'col_count_in_row',
                className: 'col-center',
                width: '120px'
            },

        ];

        const NewsData = data.filter(o => {
            let query = false;

            if (Filter) {
                if (o.link && o.link.indexOf(Filter) >= 0)
                    query = true;

                if (o.section && o.section.indexOf(Filter) >= 0)
                    query = true;
            } else {
                query = true;
            }
            return query;
        });
        const onFinish = (values) => {
            this.setState({
                dealTypeSelected: values.deal_type_id,
                landTypeSelected: values.land_type_id,
                show_name: values.show_name,
            });
            this.fetch({deal_type_id: this.state.dealTypeSelected,
            land_type_id: this.state.landTypeSelected,
                show_name:  this.state.show_name,
            })
            console.log('Success:', values);
        };

        return (
            <Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item>{Resources.advertiseOfferFormBuilder}</Breadcrumb.Item>
                </Breadcrumb>
                <Fragment>
                    <Form ref={this.searchListFormRef} layout="vertical"   onFinish={onFinish} >
                        <Row gutter={24}>
                            <Col xs={24} md={4}>
                                <Button type="primary" icon={<PlusOutlined />} onClick={() => this.showAddForm()} block>{Resources.add}</Button>
                            </Col>
                        </Row>

                        <Row gutter={24} style={{marginTop: "3%"}}>
                            {/*<Col xs={24} md={14} style={{ paddingTop: 8 }}>*/}
                            {/*    <Checkbox.Group onChange={value => console.log(value)} />*/}
                            {/*</Col>*/}


                            <Col xs={24} md={6}>
                                <Form.Item name="deal_type_id" label={Resources.dealType}  >
                                    <Select onChange={(e) => this.HandleDealTypeChange(e)}>
                                        {dealTypesList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item name="land_type_id" label={Resources.landType}  >
                                    <Select onChange={(e) => this.HandleLandTypeChange(e)}>
                                        {landTypeList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={6}>
                                <Form.Item name="show_name"   label={Resources.show_title}>
                                    <Input type="text" placeholder={Resources.show_title}  />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={4} style={{marginTop: "3%"}}>

                                <Button type="primary" htmlType="submit">{Resources.search}</Button>
                            </Col>

                        </Row>
                    </Form>
                </Fragment>
                <div className="ant-content">
                    <Table
                        columns={columns}
                        dataSource={NewsData}
                        loading={loading}
                        // onChange={this.handleTableChange}
                        // pagination={pagination}
                        size="small"
                        rowKey="id"
                        // scroll={{ x: 900 }}
                    />
                    {/*{!!pagination.total && <PaginationInfo current={pagination.current} pageSize={pagination.pageSize} total={pagination.total} />}*/}
                </div>
                <AddFormBuilderAdverOffer ref={this.formRef} onCancel={this.hideAddForm} onSubmit={this.handleAdd} submitting={submitting} visible={visibleAddForm} />
            </Fragment>
        );
    }
}

export default connect(
    (state) => {
        return {
            permissions: {
                action: CheckPermission(state.app.user, Permissions.user_option),
                add: CheckPermission(state.app.user, Permissions.user_store)
            }
        };
    },
    (dispatch) => {
        return {
            onSetRoute: (data) => dispatch(SetRoute(data)),
            setConsulting: data => dispatch(SetConsulting(data))
        };
    }
)(FormBuilderSearchAdverOffer);