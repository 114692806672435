import React, {Component, Fragment} from 'react';
// import {Map, TileLayer, Marker, withLeaflet} from 'react-leaflet';
// import {ReactLeafletSearch} from "react-leaflet-search";
import {cloneDeep} from 'lodash';
import {Button, Col, Form, Input, Modal, Row, Select, List, Upload, Radio} from 'antd';
import API from "../scripts/api";
import {
    CalendarOutlined,
    MailOutlined,
    MobileOutlined,
    UserOutlined,
    InboxOutlined, ExceptionOutlined
} from '@ant-design/icons';
import Resources from '../scripts/resources';
import Rules from '../scripts/rules';
import JoditEditor from "jodit-react";


class UserForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileList: [],
            title: '',
            userRoles: '',
            selectedRoles: null,
            addresses: [{id: 0}],
            phones: [],
            countryList: [],
            cityList: [],
            neighbourhoodList: [],
            expertHousingList: [],
            realStateList: [],
            countrySelected: "",
            citySelected: "",
            neighbourhoodSelected: "",
            expertHousingSelected: "",
            realStateSelected: "",
            TypeSelected: "user",
            selectedAddress: 0,
            visibleMap: false,
            user_type: "user",
            roles: [],
            rolesSelected: '',
        };

        this.formRef = React.createRef();
        this.mapRef = React.createRef();
    }

    handleChangeDate = (date, obj) => {
        const state = {};
        state[obj] = date;
        this.formRef.current.setFieldsValue(state);
    };

    handleSubmit = () => {
        this.formRef.current.validateFields()
            .then(values => {
                const formData = new FormData();

                if (values.avatar && values.avatar.fileList && values.avatar.fileList.length)
                    formData.append('avatar', values.avatar.file, values.avatar.file.name);
                formData.append('name', values.name);
                formData.append('family', values.family);
                formData.append('mobile', values.mobile);
                formData.append('email', values.email ? values.email : '');
                formData.append('country_id', values.country_id ? values.country_id : '');
                formData.append('city_id', values.city_id ? values.city_id : '');
                formData.append('neighborhood_id', values.neighborhood_id ? values.neighborhood_id : '');
                formData.append('expert_house_id', values.expert_house_id ? values.expert_house_id : '');
                formData.append('real_state_role_id', values.real_state_role_id ? values.real_state_role_id : '');
                // formData.append('street', values.street ? values.street : '');
                formData.append('address', values.address ? values.address : '');
                formData.append('nationalNumber', values.nationalNumber ? values.nationalNumber : '');
                formData.append('about_us', values.about_us ? values.about_us : '');
                formData.append('phone', values.phone ? values.phone : '');
                formData.append('website', values.website ? values.website : '');
                formData.append('type', values.type ? values.type : '');
                formData.append('user_type', values.user_type ? values.user_type : '');
                formData.append('roles', values.roles ? values.roles : '');

                // formData.append('cv', values.cv ? values.cv : '');

                this.props.onSubmit(formData);
            });
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();
    };

    handleDidMount = async (data = {}) => {
        const cloneData = cloneDeep(data);

        API.acl.rules()
            .then(res => {
                this.setState({
                    userRoles: res
                });
                return res
            })
            .then((res)=>{
                let arr=[];
                res.map(item=> arr.push(item.id));
                this.setState({
                    selectedRoles: arr
                });
            });

        this.formRef.current.setFieldsValue(cloneData);
        this.setState({editMode: !!cloneData.id});
        if(!!cloneData.id){
            await this.setState({user_type: cloneData.user_type});
            await this.setState({countrySelected: cloneData.country_id});
            await this.setState({citySelected: cloneData.city_id});
            await this.setState({neighbourhoodSelected: cloneData.neighborhood_id});
            await this.setState({expertHousingSelected: cloneData.expert_house_id});
            await this.setState({realStateSelected: cloneData.real_state_role_id});
            await this.setState({TypeSelected: cloneData.type});
            await this.getCountries()
            await this.getCities(cloneData.country_id)
            await this.getNeighbourhoods(cloneData.city_id)
            this.getExpertHousing()
            this.getRealState()
        }else{
            this.getCountries()
            this.getExpertHousing()
            this.getRealState()
        }
        await this.getRoles()

    };
    getCountries = (params = {}) => {
        this.setState({ loading: true });
        API.countries.list()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        countryList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getCities = (params = {}) => {
        this.setState({ loading: true });
        API.countryCities.list(params)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        cityList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getNeighbourhoods = (params = {}) => {
        this.setState({ loading: true });
        API.cityNeighbourhood.list(params)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        neighbourhoodList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getExpertHousing = (params = {}) => {
        this.setState({ loading: true });
        API.expertHousingList.list(params)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        expertHousingList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getRealState = (params = {}) => {
        this.setState({ loading: true });
        API.realStateRoleList.list(params)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        realStateList: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    getRoles = (params = {}) => {
        this.setState({ loading: true });
        API.roles.list()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        roles: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };


    HandleCountryChange = (e) => {
        this.setState(
            {countrySelected : e}
        )
        this.getCities(e)
    }

    HandleCityChange = (e) => {
        this.setState(
            {citySelected : e}
        )
        this.getNeighbourhoods(e)
    }




    render() {
        const {onCancel, submitting, visible} = this.props;
        const {addresses, editMode, user_type, countryList, fileList, countrySelected, citySelected,
            neighbourhoodSelected, cityList, neighbourhoodList, expertHousingList,
            roles, rolesSelected, realStateList, TypeSelected} = this.state;

        const props = {
            onRemove: file => {
                this.setState({ fileList: [] });
            },
            beforeUpload: file => {
                this.setState({ fileList: [file] });
                return false;
            },
            showUploadList: {
                showRemoveIcon: editMode
            },
            fileList
        };


    const {TextArea} = Input

        return (
            <Fragment>
                <Modal
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} layout="vertical">
                        <Row gutter={24}>
                            <Col xs={24}>
                                <Form.Item name="avatar" label={Resources.image}>
                                    <Upload.Dragger {...props}>
                                        <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                        <p className="ant-upload-hint">{Resources.uploadText}</p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="country_id" label={Resources.country} initialValue= {countrySelected} >
                                    <Select  onChange={(e) => this.HandleCountryChange(e)}>
                                        {countryList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="city_id" label={Resources.city} initialValue= {citySelected} >
                                    <Select onChange={(e) => this.HandleCityChange(e)}>
                                        {cityList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="neighborhood_id" label={Resources.neighborhood} initialValue= {neighbourhoodSelected} >
                                    <Select>
                                        {neighbourhoodList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="expert_house_id" label={Resources.expert_house_name} >
                                    <Select>
                                        {expertHousingList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="real_state_role_id" label={Resources.real_state_role_name}  >
                                    <Select>
                                        {realStateList.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="type" label={Resources.userType} initialValue= {TypeSelected} >
                                    <Select>
                                            <Select.Option   value="user">user</Select.Option>
                                            <Select.Option   value="admin">admin</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>


                            <Col xs={24} md={12}>
                                <Form.Item name="name" label={Resources.firstName}>
                                    <Input type="text" prefix={<UserOutlined/>} placeholder={Resources.nameHint}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="family" label={Resources.family}>
                                    <Input type="text" prefix={<UserOutlined/>} placeholder={Resources.nameHint}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="mobile" label={Resources.mobile} rules={Rules.required}>
                                    <Input type="text" dir="ltr" prefix={<MobileOutlined/>}
                                           placeholder={Resources.mobileHint}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="email" label={Resources.email} rules={Rules.email}>
                                    <Input type="text" dir="ltr" prefix={<MailOutlined/>}
                                           placeholder={Resources.emailHint}/>
                                </Form.Item>
                            </Col>
                            {/*<Col xs={24} md={12}>*/}
                            {/*    <Form.Item name="street" label={Resources.street}>*/}
                            {/*        <Input type="text" dir="ltr"*/}
                            {/*               placeholder={Resources.street}/>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                            <Col xs={24} md={12}>
                                <Form.Item name="address" label={Resources.address}>
                                    <Input type="text" dir="ltr"
                                           placeholder={Resources.address}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="phone" label={Resources.phone}>
                                    <Input type="text" dir="ltr"
                                           placeholder={Resources.phone}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="nationalNumber" label={Resources.nationalNumber}>
                                    <Input type="text" dir="ltr"
                                           placeholder={Resources.nationalNumber}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="website" label={Resources.website}>
                                    <Input type="text" dir="ltr"
                                           placeholder={Resources.website}/>
                                </Form.Item>
                            </Col>


                            <Col xs={24} md={24}>
                                <Form.Item name="about_us" label={Resources.aboutUs} >
                                    <TextArea disabled={this.props.submitting} type="text" />

                                </Form.Item>

                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="user_type" label={Resources.userType} rules={Rules.required} initialValue= {user_type}>
                                    <Radio.Group>
                                        <Radio value="user">user</Radio>
                                        <Radio value="real_state">real_state</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item  name="roles" label={Resources.manager} >
                                    <Select mode="multiple">
                                        {roles.map(item => (
                                            <Select.Option title={item.name}   style={{direction:"rtl"}} key={item.id}  value={item.id}>
                                                {item.name }
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
};

export default UserForm;
