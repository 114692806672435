import { Actions } from '../scripts/settings';

const OpenMenu = (data) => ({
    type: Actions.OPEN_MENU,
    payload: data
});

const SetRoute = (data) => ({
    type: Actions.SET_ROUTE,
    payload: data
});

const UpdateUser = (data) => ({
    type: Actions.UPDATE_USER,
    payload: {...data , level : "admin"}
});
const SetPermissionForUser = (arrData) => ({
    type: Actions.SET_RULES,
    payload: arrData
});
const SetTeacherLessonChapterData = data => ({
    type: Actions.SET_TEACHER_LESSON_CHAPTER_DATA,
    payload: data
});
const SetConsulting = data => ({
    type: Actions.SET_CONSOLTANT,
    payload: data
});

export { OpenMenu, SetRoute, UpdateUser , SetPermissionForUser, SetTeacherLessonChapterData, SetConsulting};