import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Cookies } from "react-cookie";
import { Switch } from 'react-router';
import { Redirect, Route, Link } from 'react-router-dom';

import {Drawer, Tooltip, Layout, Menu, Spin, notification, message, Avatar} from 'antd';
import { LogoutOutlined, MenuFoldOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import { OpenMenu, UpdateUser, SetPermissionForUser } from '../scripts/action';
import {CheckPermission, CheckRoles} from '../scripts/helpers';
import API from '../scripts/api';
import Resources from '../scripts/resources';
import Routes from '../scripts/routes';
import Footer from '../components/Footer';
import UserForm from "../forms/User";
import Profile from "../forms/Profile";
import {Constants} from "../scripts/settings";
import ReactNotifications from "react-browser-notifications";
import {WebSocketContext} from "../scripts/soketProvider";
const { Header, Content, Sider } = Layout;

const CustomRoute = ({ component: Component, routeName, parentName, ...rest }) => (
    <Route {...rest} render={props => (<Component {...props} routeName={routeName} parentName={parentName} />)} />
);

class BasicLayout extends Component {
    constructor() {
        super();

        this.state = {
            visibleForm: false,
            loaded: false,
            messageCount: 0,
            orderCount: 0,
            user: {},
            visible: false,
            me: ''
        };

        this.cookies = new Cookies();
        this.formRef = React.createRef();
    }
    static contextType = WebSocketContext;

    showDrawer = () => {
        this.setState({ visible: true });
    };

    hideDrawer = () => {
        this.setState({ visible: false });
    };

    handleLogout = () => {
        API.profile.logout()
            .then((result) => {
                if (result) {

                    this.setState({ loading: true });
                        // const result = result.data;
                        this.setState({
                            loading: false,
                            data: result.data,
                            search: result.data,
                        });


                    this.cookies.remove('AccessToken', { path: '/', domain: window.location.hostname });
                    window.location = '/account/login';
                    } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });


    };

    handleMenu = (key) => {
        const { onOpenMenu, openKey } = this.props;
        onOpenMenu(openKey === key ? [] : key);
    };


    async componentDidMount() {
        const accessToken = await this.cookies.get('AccessToken');
        const {setPermision} = await this.props;
        if (accessToken) {
           await API.profile.info().then(async (result) => {
                await this.props.onUpdateUser(result);
                await this.setState({ loaded: true, me: result });
                return result
            }).then(res => {
                // API.acl.user(res.id)
                //     .then(res => {
                //         setPermision(res)
                //     })
            })
        } else {
            window.location = '/account/login';
        }

        // let webSocket = await this.context;
        // let ws = await webSocket
        //
        // await ws.socket.on("notification", async (socketData) => {
        //     console.log("socketData ", socketData)
        //     await this.setState({notificationTitle: socketData.title, notificationBody: socketData.message})
        //     this.showNotifications()
        // })
    }

    handleSubmit = (values) => {
        this.setState({ submitting: true });

        API.profile.update(values)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideForm();
                this.fetch({ page: this.state.pagination.current });
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };

    showForm = (user = {}) => {
        this.setState({ visibleForm: true });

        setTimeout(() => {
            this.formRef.current.handleDidMount(user);
        }, 0);
    };
    hideForm = () => {
        this.setState({ visibleForm: false });

        setTimeout(() => {
            this.formRef.current.handleWillUnmount();
        }, 0);
    }

    showNotifications() {
        // If the Notifications API is supported by the browser
        // then show the notification
        console.log("SUPPROT: ",this.n.supported())
        console.log(this.n.show)
        if (this.n.supported()) this.n.show();
    }

    handleClick(event) {
        // Do something here such as
        // console.log("Notification Clicked") OR
        // window.focus() OR
        // window.open("http://www.google.com")

        // Lastly, Close the notification
        this.n.close(event.target.tag);
    }

    render() {
        const { loaded, messageCount, orderCount, visible,  visibleForm, submitting } = this.state;
        const { browser, openKey, selectedKey, user  } = this.props;
        const appRoutes = Routes.find(p => p.name === 'app').routes;


        const menu = (
            <div>


                <Fragment>
                    <div className="sidebar-logo">
                        <img src="/images/logo.png" alt="" />
                    </div>
                    {user.roles &&
                    <Menu mode="inline" selectedKeys={selectedKey} openKeys={openKey}>
                        {appRoutes.filter(o => !o.hidden && CheckRoles(user, o.authority)).map(route => {
                            if (route.childs) {
                                const childs = route.childs.filter(p => !p.hidden && (!p.authority || CheckPermission(user, p.authority)));
                                if (childs.length) {
                                    return (
                                        <Menu.SubMenu key={route.name}
                                                      title={<span>{route.icon}<span>{route.title}</span></span>}
                                                      onTitleClick={() => this.handleMenu([route.name])}>
                                            {childs.map(child => {
                                                if(child.childs){
                                                    const sub_childs = child.childs.filter(p1 => !p1.hidden && (!p1.authority || CheckPermission(user, p1.authority)));
                                                    if (sub_childs.length) {
                                                        return ( <Menu.SubMenu key={child.name}
                                                                               title={<span>{child.icon}<span>{child.title}</span></span>}
                                                                               onTitleClick={() => this.handleMenu([route.name, child.name])}
                                                        >

                                                            {sub_childs.map(child1 => {
                                                                return (
                                                                    <Menu.Item key={child1.name} onClick={this.hideDrawer}>
                                                                        <Link to={child1.path}>
                                                                            {child1.icon}
                                                                            <span>{child1.title}</span>
                                                                        </Link>
                                                                    </Menu.Item>
                                                                )
                                                            })}
                                                        </Menu.SubMenu>)

                                                    }
                                                    else {
                                                        return null;
                                                        // return (
                                                        //     <Menu.Item key={child.name} onClick={this.hideDrawer}>
                                                        //         <Link to={child.path}>
                                                        //             {child.icon}
                                                        //             <span>{child.title}</span>
                                                        //         </Link>
                                                        //     </Menu.Item>
                                                        // )
                                                    }
                                                } else{
                                                    return (
                                                        <Menu.Item key={child.name} onClick={this.hideDrawer}>
                                                            <Link to={child.path}>
                                                                {child.icon}
                                                                <span>{child.title}</span>
                                                            </Link>
                                                        </Menu.Item>
                                                    )
                                                }

                                            })}
                                        </Menu.SubMenu>
                                    )
                                } else {
                                    return null;
                                }
                            } else {
                                return (
                                    <Menu.Item key={route.name} onClick={this.hideDrawer}>
                                        <Link to={route.path}>
                                            {route.icon}
                                            <span>{route.title}</span>
                                        </Link>
                                    </Menu.Item>
                                )
                            }
                        })}
                    </Menu>
                    }

                </Fragment>




                <div>

                    <ReactNotifications
                        onRef={ref => (this.n = ref)} // Required
                        title={this.state.notificationTitle} // Required
                        body={this.state.notificationBody}
                        // icon="logo192.png"
                        tag="shenasamelk"
                        timeout="10000"
                        // onClick={event => this.handleClick(event)}
                    />


                </div>
            </div>
        );

        if (loaded) {
            return (
                <Layout>
                    {browser.greaterThan.large && <Sider width={250}>{menu}</Sider>}
                    <Layout>
                        <Header>
                            {browser.lessThan.infinity && <MenuFoldOutlined className="trigger" onClick={this.showDrawer} />}
                            <span onClick={this.handleLogout} className="order"><PoweroffOutlined /></span>
                            <div className="logout" onClick={() => this.showForm(this.state.me)}>
                                <span > {user.name} </span>
                                <Tooltip placement="bottomLeft" title={<Avatar src={`${Constants.imagePath}${user.avatar}`} shape="square" size={200} />}><Avatar shape="square" src={`${Constants.imagePath}${user.avatar}`} size="large" icon={<UserOutlined />} /></Tooltip>
                            </div>
                        </Header>
                        <Content>
                            <Switch>
                                {appRoutes.filter(o => !o.authority || CheckRoles(user, o.authority)).map(route => {
                                    if (route.redirect) {
                                        return (<Redirect key={route.name} from={route.path} to={route.redirect} exact />)
                                    } else if (route.childs) {
                                          return route.childs.filter(p => !p.authority || CheckRoles(user, p.authority)).map(child => {
                                                if (child.childs) {
                                                   return child.childs.filter(p1 => !p1.authority || CheckRoles(user, p1.authority)).map(child1 => <CustomRoute key={child1.name} path={child1.path} component={child1.component} routeName={child1.name} parentName={[child.name, route.name]} exact />)
                                                }else{
                                                   return <CustomRoute key={child.name} path={child.path} component={child.component} routeName={child.name} parentName={[route.name]} exact />
                                                }
                                            })
                                    } else {
                                        return <CustomRoute key={route.name} path={route.path} component={route.component} routeName={route.name} parentName={[route.name]} exact />
                                    }
                                })}
                                <Redirect path="*" to="/404" />
                            </Switch>
                        </Content>
                        {Footer}
                        {browser.lessThan.infinity && <Drawer visible={visible} closable={false} onClose={this.hideDrawer} placement="right" width="250" className="drawer-basic-layout">{menu}</Drawer>}
                    </Layout>
                    <Profile ref={this.formRef} onCancel={this.hideForm} onSubmit={this.handleSubmit} submitting={submitting} visible={visibleForm} />
                </Layout>
            )
        } else {
            return (
                <Spin className="ant-spin-center" />
            )
        }



    }
}

export default connect(
    (state) => {
        return {
            browser: state.browser,
            openKey: state.app.openKey,
            selectedKey: state.app.selectedKey,
            user: state.app.user
        };
    },
    (dispatch) => {
        return {
            onOpenMenu: (data) => dispatch(OpenMenu(data)),
            onUpdateUser: (data) => dispatch(UpdateUser(data)),
            setPermision: (arrData) => dispatch(SetPermissionForUser(arrData))
        };
    }
)(BasicLayout);
