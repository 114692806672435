import React, { Component, Fragment } from 'react';

class DashboardPage extends Component {
    render() {
        return (
            <div>
                <h1 style={{padding: '100px', fontSize:'28px'}}>داشبورد</h1>
            </div>
        );
    }
}

export default DashboardPage;