import React, {Component, Fragment} from 'react';
import {cloneDeep} from "lodash";
import {Col, Input, Modal, Row, Form} from "antd";
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import {
    MailOutlined,
    UserOutlined,
} from '@ant-design/icons';

class AddNews extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileList: [],
            title: '',
            userRoles: '',
            selectedRoles: null,
            addresses: [{id: 0}],
            phones: [],
            selectedAddress: 0,
            visibleMap: false,
            id: ''
        };

        this.formRef = React.createRef();
        this.mapRef = React.createRef();
    }


    handleSubmit = () => {
        this.formRef.current.validateFields()
            .then(values => {
                const data = {
                    title: values.title,
                    url: values.url,
                    description: values.description,
                };
                if (this.state.editMode){
                    this.props.onSubmit(data, this.state.id);
                } else {
                    this.props.onSubmit(data);
                }
            });
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();

        this.setState({
            addresses: [{id: 0}],
            phones: []
        });
    };

    handleDidMount = (data = {}) => {
        const cloneData = cloneDeep(data);

        this.formRef.current.setFieldsValue(cloneData);
        console.log(!!cloneData.id);
        this.setState({editMode: !!cloneData.id, id: cloneData.id});
    }


    render() {
        const {onCancel, submitting, visible} = this.props;
        const {addresses, editMode, phones, visibleMap, fileList} = this.state;

        return (
            <Fragment>
                <Modal
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} layout="vertical">
                        <Row gutter={24}>
                            <Col xs={24} md={12}>
                                <Form.Item name="title" label={Resources.title} rules={Rules.required}>
                                    <Input type="text" prefix={<UserOutlined/>} placeholder={Resources.nameHint}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="url" label={Resources.url}>
                                    <Input type="text" prefix={<UserOutlined/>} placeholder={Resources.url}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item name="description" label={Resources.description}>
                                    <Input.TextArea />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default AddNews;