import React, {Component, Fragment} from 'react';
import {Button, Col, Input, Modal, Row, Upload, Form} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import Resources from "../scripts/resources";
import {Constants} from "../scripts/settings";

class showVideo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vidList: [],
            fileList:[],
            picList: [],
            Data: '',
            vidId:'',
        }
        this.formRef = React.createRef();
    }

    handleWillUnmount = () => {
        // this.formRef.current.resetFields();
    };

    handleDidMount = (data = {}) => {
        this.setState({
            Data: data.videos
        })
    }

    HandleDelete = (vid) => {
        this.props.onDelete(vid.id);
    };

    render() {
        const {onCancel, submitting, visible, uploading, bookChapter} = this.props;
        const {Data, vidId} = this.state;

        const props = {
            beforeUpload: file => {
                this.props.onEdit(file,vidId);
                return false;
            },
        };


        return (
            <Fragment>
                <Modal
                    title={`${Resources.add} ${Resources.section}`}
                    visible={visible} onOk={this.handleSubmit}
                    onCancel={this.props.onCancel}
                    footer={null}
                    width={600}
                >
                    {
                        Data ?
                        <div>
                            {
                                Data.map(vid =>
                                    <Row key={vid._id} gutter={12} style={{marginBottom: '15px'}}>
                                        < Col xs={12}>
                                            <video id="playedVideo" controls poster={`${Constants.imagePath}${vid.img_path}`} className='deleteChapterVideoForm'>
                                                <source src={`${Constants.imagePath}${vid.video_path}`} type="video/mp4" />
                                            </video>
                                        </Col>
                                    </Row>
                                    )
                            }
                        </div> : <div>
                                <h5>{Resources.noVideoData}</h5>
                            </div>
                    }
                </Modal>
            </Fragment>
        );
    }
}

export default showVideo;