import React, {Component, Fragment} from 'react';
import {cloneDeep} from "lodash";
import {Col, Modal, Row, Input, Form, Upload} from "antd";
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import {ProjectOutlined, InboxOutlined, DeploymentUnitOutlined} from '@ant-design/icons';
import { Constants } from '../scripts/settings';
// import PinMarker from '../assets/icons/mylocation.png';

import { Circle, Map, TileLayer, Marker } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from "leaflet";
class AddContactUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            lat: Constants.defaultLat,
            lng: Constants.defaultLng,
            freeShippingRadius: 1,
            costShippingRadius: 1,


            fileList: [],
            title: '',
            userRoles: '',
            selectedRoles: null,
            addresses: [{id: 0}],
            phones: [],
            selectedAddress: 0,
            visibleMap: false,
            id: ''
        };

        this.formRef = React.createRef();
        this.mapRef = React.createRef();
    }


    handleSubmit = () => {
        this.formRef.current.validateFields()
            .then(values => {
                const formData = new FormData;
                formData.append('key', values.key);
                formData.append('value', values.value);
                formData.append('lat', this.state.lat);
                formData.append('lng', this.state.lng);
                if (this.state.editMode){
                    formData.append("id", this.state.id);
                    this.props.onSubmit(formData, this.state.id);
                } else {
                    this.props.onSubmit(formData);
                }
            });
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();
    };

    handleDidMount = (data = {}) => {
        const cloneData = cloneDeep(data);
        this.formRef.current.setFieldsValue(cloneData);
        console.log(!!cloneData.id);
        this.setState({editMode: !!cloneData.id, id: cloneData.id});

        console.log("cloneData",cloneData)


        const firstmap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        })

        let map = L.map('map', {
            center: [!!cloneData.lat, !!cloneData.lng],
            // tapTolerance: 25,
            tap: false,
            zoom: 5,
            maxZoom: 18,
            // zoomControl: false,
            layers: [firstmap],
        });
        var marker = L.marker([51.5, -0.09]).addTo(map);
        const onMapClick = (e) => {
            // alert("You clicked the map at " + e.latlng);
            marker.setLatLng(e.latlng)
            console.log("e.lat", e.latlng)
            console.log("e", e)
            this.setState({ lat: e.latlng.lat, lng: e.latlng.lng });

        }

        map.on('click', onMapClick);
    }


    render() {
        const {lat, lng, data, editMode, onCancel, submitting, visible, freeShippingRadius, costShippingRadius} = this.props;

        const CustomMarker = ({ data }) => {
            const divIcon = new L.DivIcon({
                iconSize: [100, 41],
                iconAnchor: [88, 41],
                popupAnchor: [1, -34],
                className: 'leaflet-marker-custom-icon',
                html: `<img src="/images/leaflet/marker-icon-red.png"/><span>${data.postman}</span>`
            });

            return <Marker position={{ lat: data.lat, lng: data.lng }} icon={divIcon} />
        }
        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return { fileList: newFileList };
                });
            },
            beforeUpload: file => {
                this.setState({ fileList: [file], fileData: file });
                return false;
            },
            // fileList
        };

        return (
            <Fragment>
                <Modal
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} layout="vertical">
                        <Row gutter={24}>
                            {/*<Col xs={24} md={12}>*/}
                            {/*    <Form.Item name="logo" label={Resources.image} rules={editMode ? [] : Rules.required}>*/}
                            {/*        <Upload.Dragger disabled={submitting} {...props} className="ant-upload-block">*/}
                            {/*            <p className="ant-upload-drag-icon"><InboxOutlined /></p>*/}
                            {/*            <p className="ant-upload-hint">{Resources.uploadText}</p>*/}
                            {/*        </Upload.Dragger>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                            <Col xs={24} md={24}>
                                <Form.Item name="key" label={Resources.key} rules={Rules.required}>
                                    <Input type="text" prefix={<ProjectOutlined/>} placeholder={Resources.nameHint}/>
                                </Form.Item>
                                <Form.Item name="value" label={Resources.value}>
                                    <Input placeholder={Resources.value} prefix={<DeploymentUnitOutlined />}/>
                                </Form.Item>
                            </Col>
                            {/*<Col xs={24} md={24}>*/}
                                <div id="map" style={{width:"100%", height:"300px"}}></div>
                            {/*</Col>*/}

                        </Row>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default AddContactUs;
