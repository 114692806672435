import React, {Component, Fragment} from 'react';
import {Button, Col, Input, Modal, Row, Upload, Form, Avatar} from "antd";
import Resources from "../scripts/resources";
import {Constants} from "../scripts/settings";
import {cloneDeep} from "lodash";

class DeleteImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vidList: [],
            picList: [],
            Data: '',
        }
        // this.formRef = this.MediaRef;
    }

    handleWillUnmount = (data = {}) => {
        // this.formRef.current.resetFields();

    };

    handleDidMount = (data = {}) => {
        console.log("imagesss", data.images);
        this.setState({
            Data: data.images
        })
    }

    HandleDelete = (img) => {
        this.props.onDelete(img._id);
    }


    render() {
        const { submitting, visible} = this.props;
        const {Data} = this.state;

        return (
            <Fragment>
                <Modal
                    title={`${Resources.bankInfo}`}
                    visible={visible} onOk={this.handleSubmit}
                    onCancel={this.props.onCancel}
                    footer={null}
                >
                    {
                        Data ?
                        <div>
                            {
                                Data.map(img =>
                                    <Row key={img.id} gutter={24} style={{marginBottom: '15px'}}>
                                        < Col xs={18}>
                                           <Avatar src={`${Constants.imagePath}${img.path}`} shape="square" size="small" />
                                        </Col>
                                        <Col xs={6} style={{display: 'flex',alignItems: 'center'}}>
                                            <Button loading={submitting} type='danger' onClick={()=>this.HandleDelete(img)}>
                                                {Resources.delete}
                                            </Button>
                                        </Col>
                                    </Row>
                                    )
                            }
                        </div> : <div>
                                <h5>{Resources.notfound}</h5>
                            </div>
                    }
                </Modal>
            </Fragment>
        );
    }
}

export default DeleteImage;