import React, { createContext, useEffect } from "react";
import io from "socket.io-client";
import { Cookies } from "react-cookie";
import axios from "axios";
const WebSocketContext = createContext(null);

export { WebSocketContext };

export default ({ children }) =>  {
  let socket;
  let ws

  if (!socket) ws = initConnection();

  async function initConnection() {
    // if( ( localStorage.getItem("socketApiToken") == null) || (!localStorage.getItem("socketApiToken")) || (localStorage.getItem("socketApiToken") == undefined)){
    //      await getSocketToken()
    // }
    //   console.log("socketApiToken ", localStorage.getItem("socketApiToken"))
    //   socket = io("https://api.socketapi.ir", {
    //     transports: [ "polling"],
    //     query: { auth: localStorage.getItem("socketApiToken") },
    //   });
    //   socket.on("connect", () => {
    //     console.log('connnect')
    //   })
    //
    //   socket.on("connect_error", (err) => {
    //     console.log({...err}, 'eeee')
    //   }); //todo
    //     return {
    //         socket: socket,
    //     };



  }

    async function getSocketToken() {
    const article = { fullName: 'my name', "company" : "626e241735f109e6b7524422" };
    axios.post('https://api.socketapi.ir/api/v1/user/register', article)
        .then(async response => {

          console.log("response is: ", response.data.data.token)
          await localStorage.setItem('socketApiToken',response.data.data.token)

        })
        .catch(error => {
          this.setState({ errorMessage: error.message });
          console.error('There was an error!', error);
        });
  }


  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>



  );
};
