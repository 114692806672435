
export default {
    slug: "اسلاگ",
    FBItems: " آیتم های فرم ساز",
    manageImage: " مدیریت تصاویر",
    read_time: " زمان مطالعه",
    loginWithSpecificUser: " ورود با حساب کاربری خاص",
    AdverOfferFieldLogo: " لوگو فیلدهای فرم ساز ملک",
    show_in_site_position: " مکان نمایش در سایت",
    showInSlider: " نمایش در اسلایدر",
    addAddress: " افزودن آدرس",
    addInfo: " افزودن اطلاعات",
    optionsSelect: " مقادیر select",
    addOptionSelect: " افزودن مقدار select",
    nationalNumber: " کد ملی",
    agent: " نمایندگی",
    save: " ذخیره",
    family: " نام خانوادگی",
    path: "مسیر",
    choice: "انتخاب",
    note: "یادداشت",
    managerSearch: "مدیر (جستجو با شماره موبایل)",
    manager: "مدیر",
    checkAll: "انتخاب همه",
    manageAgent: "مدیریت نمایندگی",
    addAgentToAdvertise: " انتساب نمایندگی به اگهی ها",
    showInVip: " نمایش در vip",
    website: "وب سایت",
    unit: " واحد",
    legalDevices: " دستگاه های مجاز",
    video: " ویدیو",
    identifier: " کد کاربر",
    DeleteInSlider: " حذف از اسلایدر",
    expert_house_name: " تخصص در ملک",
    real_state_role_name: "سمت",
    DeleteInVip: " حذف از vip",
    userType: "نوع کاربر",
    formBuilder: "فرم ساز",
    show_in_search: "نمایش در جستجو",
    show_in_slider: "نمایش در اسلایدر",
    show_as_buttons: "نمایش به صورت دکمه ای",
    country: "کشور",
    new_field: "فیلد جدید",
    exist_field: "فیلد  موجود",
    show_in_first_page: "نمایش در صفحه اول",
    show_in_second_page: "نمایش در صفحه دوم",
    show_title: "عنوان نمایشی",
    col_count: "تعداد ستون",
    showName: "نام نمایشی",
    facilityFieldsList: "فیلدهای لیست امکانات",
    search_field: "فیلد جستجو",
    priority: "اولویت نمایش",
    propertyManagement: "مدیریت املاک",
    articlesManagement: "مدیریت مقالات",
    listManagement: "مدیریت لیست ها",
    show_in_detail_page: "نمایش در صفحه جزییات",
    advertiseOfferFormBuilder: "فرم ساز آگهی ملک",
    formBuilderItemDataWebService: "وب سرویس آیتم های فرم ساز",
    formBuilderItemData: " داده های آیتم های فرم ساز",
    webServiceData: " داده های وب سرویس ",
    panelManagement: "مدیریت پنل",
    searchFormBuilder: "فرم ساز جستجو",
    advertiseRequestFormBuilder: "فرم ساز تقاضای ملک",
    isOwnerFormBuilder: "فرم ساز مالک بودن",
    article: "مقالات",
    col_count_in_row: "تعداد ستون های المنت در هر سطر",
    search_col_count_in_row: "تعداد ستون های المنت در هر سط در فرم ساز جستجو",
    inputType: "نوع محتوای فیلد",
    fieldType: "نوع فیلد",
    name: "نام (ستون دیتابیس)",
    isOwner: "مالک هستم",
    max_length: "حداکثر طول",
    min_length: "حداقل طول",
    placeholder: "placeholder",
    api_data: "وب سرویس دیتا",
    api_data_parent: "وب سرویس دیتای فیلد پدر",
    jobSearch: "جستجوی مشاغل",
    Profile: "پروفایل ",
    jobProfile: "پروفایل مشاغل",
    houseSearch: "جستجوی اماکن",
    state: "استان",
    reject_reason: "دلیل رد",
    loan: "وام",
    editor: "ادیتور",
    raw: "خام",
    raw_description: "توضیحات خام",
    aboutUs: "درباره ما",
    jobCat: "دسته شغلی",
    jobRank: "رتبه شغلی",
    jobType: "نوع شغلی",
    employer: "کارمندان",
    jobOffer: "آگهی شغل",
    jobRequest: "تقاضای شغل",
    positionEmployer: "سمت",
    jobManagement: "مشاغل",
    facility: "امکانات",
    roomFacility: "امکانات اتاق",
    rejectAdd: "رد کردن",
    manageVideo: "ویدیو",
    corrective_area: "متراژ اصلاحی",
    building_permit: "مجوز ساخت",
    exchange: "معاوضه",
    length: "متراژ زمین",
    height: "طول بر",
    width_land: "عرض زمین",
    width_street: "عرض خیابان",
    wall_cover: "پوشش دیوار",
    minDaysOpportunity: "حداقل زمان",
    maxDaysOpportunity: "حداکثر زمان",
    suitable_for: "مناسب برای",
    is_rent: "در رهن",
    number_of_trees: "تعداد درختان",
    commercial_license: "مجوز تجاری",
    delivery_month: "ماه تحویل",
    density: "تراکم",
    delivery_year: "سال تحویل",
    position: "موقعیت ملک",
    house_view: "نمای ساختمان",
    floor_cover: "کفپوش",
    document: "سند",
    dang_document: "نوع سند",
    building_step: "مرحله ساخت",
    cupboard: "کابینت",
    unit_type: "نوع واحد",
    suite_count: "تعداد سوییت",
    beds_count: "تعداد تخت",
    one_bed_rooms_count: "تعداد اتاق یک تخته",
    two_bed_rooms_count: "تعداد اتاق دو تخته",
    three_bed_rooms_count: "تعداد اتاق سه تخته",
    floors_count: "تعداد طبقات",
    floor: "طبقه",
    total_units: "تعداد کل واحد ها",
    rooms_count: "تعداد اتاق",
    bed_rooms_count: "تعداد تخت اتاق",
    RequireExpert: "نیاز به کارشناس",
    area: "زیربنا",
    units_per_floor: "تعداد واحد هر طبقه",
    price_currency: "واحد پولی",
    mortgage_price_convert: "قابلیت تبدیل رهن و اجاره",
    mortgage_price: "مبلغ رهن",
    monthly_rent_price: "اجاره ماهیانه",
    commercial_area: "متراژ تجاری",
    land_area: "متراژ زمین",
    show_list_type: "نوع لیست نمایشی",
    area_unit: "واحد اندازه گیری",
    age: "سن بنا",
    city: "شهر",
    owner: "مالک",
    street: "خیابان",
    landType: "نوع ملک",
    dealType: "نوع معامله",
    wait: "در انتظار تایید",
    reject: "رد شده",
    deleted: "حذف شده",
    delete_reason: "دلیل حذف",
    neighborhood: "محله",
    writer: "  نویسنده",
    offerHousingSearchFieldsFormBuilder: "فرم ساز  جستجوی آگهی ملک",
    landManagement: "مدیریت ملک",
    offerHousing: "آگهی ملک",
    requestHousing: "تقاضای ملک",
    connectUs: "  ارتباط با ما",
    short_desc: "  توضیحات کوتاه",
    editPoster: "ویرایش پوستر",
    posterEdit: "ویرایش پوستر",
    uploadType: "نحوه افزودن فایل",
    cvView: "مشاهده رزومه",
    stateManagement: "مدیریت استان ها",
    dataManagement: "مدیریت داده ها",
    socialNetworkManagement: "مدیریت شبکه های اجتماعی",
    documentsManagement: "مدیریت سندها",
    heatCoolManagement: "سرمایشی و گرمایشی ",
    facilityManagement: "مدیریت امکانات",
    floorCoverManagement: "مدیریت کفپوش",
    landTypeManagement: "نوع ملک",
    dealTypeManagement: "نوع معامله",
    roomFacilityManagement: "امکانات اتاق",
    realStateRoleManagement: "نقش اعضای مشاورین املاک",
    expertHousingManagement: "تخصص ملک",
    priceCurrencyManagement: "واحد پولی",
    areaUnitManagement: "واحد اندازه گیری",
    articleCatManagement: "دسته مقالات",
    articleManagement: "مقالات",
    buildingStepManagement: "مراحل ساخت",
    houseViewManagement: "نمای ساختمان",
    positionLandManagement: "موقعیت ملک",
    cupboardManagement: "کابینت",
    jobCatManagement: "دسته شغلی",
    jobTypeManagement: "نوع شغل",
    jobRankManagement: "رتبه کاری",
    countryManagement: "کشورها",
    statesManagement: "استان",
    cityManagement: "شهرها",
    streetManagement: "محله ها",
    subNeighborhood: "زیرمحله ها",
    documentTypeManagement: "مدیریت نوع سند",
    searchByUserTitleDescriptionTeacherInfo: 'جستجو بر اساس کاربر/عنوان/توضیحات/معلم/اطلاعات',
    searchByTitleDescription: 'جستجو بر اساس عنوان/توضیحات',
    searchbyTitleTeacherDescription: 'جستجو بر اساس عنوان/استاد/توضیحات',
    filterByPodcastCat: 'فیلتر براساس دسته بندی پادکست ها',
    block: "قفل",
    pdfFile: 'فایل pdf',
    isEfficient: 'بصرفه',
    user_id: 'شناسه کاربر',
    parentComment: "نظر مربوطه",
    rate: 'امتیاز',
    ManageComment: 'مدیریت نظرات',
    ManagePodcastComment: 'مدیریت نظرات پادکست',
    profileEdit: 'ویرایش پروفایل',
    durationHint: 'مدت زمان به ساعت',
    ManagebookPackage: 'مدیریت بسته های آموزشی',
    bookPackage:'بسته های آموزشی',
    paymentConsultingList: 'لیست پرداخت مشاوره',
    discountPrice: 'میزان تخفیف',
    trackCode: 'کد پیگیری',
    houseCode: 'کد ملک',
    packageLessonList: 'لیست پرداخت بسته های درسی',
    chargeList: 'لیست پرداخت شارژ ها',
    reportManagment: 'مدیریت گزارشات',
    banner: 'بنر',
    value: 'مقدار',
    help: 'کمک آموزشی',
    exam: 'آزمون',
    dashboardManagement: 'مدیریت داشبورد',
    url: 'آدرس اینترنتی',
    urlHint: 'http://exam.ir',
    advertiseManagement: 'مدیریت تبلیغات',
    advertise: 'تبلیغات',
    valueHint: 'ارزش به درصد',
    expireDate: 'تاریخ انقضا',
    discountCode: 'کد تخفیف',
    manageDiscountCode: 'مدیریت کد تخفیف',
    cv: 'رزومه',
    Cooperation: 'مشارکت',
    cristicSugg: 'انتقادات و پیشنهادات',
    capacity: 'حجم رسانه',
    manageSupporter: 'مدیریت حامیان',
    supporter: 'حامی',
    supporters: 'حامیان',
    loginedUser: 'کاربران آنلاین',
    news: 'اخبار',
    minute: 'دقیقه',
    duration: 'مدت زمان',
    manageConsultantDuration : 'مدیریت زمان‌بندی مشاوره',
    manageConsultantComment : 'مدیریت نظرات مشاوره',
    sound:'صوت',
    showAndDeleteMedia: 'نمایش و حذف رسانه',
    VideoOrVoice: 'ویدئو یا صوت',
    consulting: 'مشاوره',
    consult: 'مشاور',
    consultingManagement: 'مدیریت مشاوره',
    mp3: '(فرمت mp3)',
    id: 'شناسه',
    accessAll: 'تمام دسترسی ها',
    noVideoData: 'ویدئویی برای این بخش وجود ندارد',
    Video: 'ویدئو',
    Videos: 'ویدئو ها',
    HeadOfTheSeason: 'سرفصل',
    chapter: 'فصل',
    ManageHeadOfTheSeasons: 'مدیریت سرفصل ها',
    ManageHeadOfTheSection: 'مدیریت بخش ها',
    podcastsManager: 'مدیریت پادکست ها',
    podcast: 'پادکست',
    selectPodcaster: 'انتخاب پادکستر',
    podcastCats: 'دسته‌بندی های پادکست',
    podcastsCats: 'دسته‌بندی های پادکست ها',
    podcastsCat: 'دسته‌بندی پادکست ها',
    podcastEdit: 'ویرایش پادکست',
    managePodcastSection: 'مدیریت بخش های پادکست',
    LimitAccess: 'محدودیت دسترسی',
    teacherLessonManeges:'مدیریت دروس اساتید',
    lessonShow: 'نمایش درس ها',
    lessonManeges: 'مدیریت درس ها',
    ProfessorsManeges: 'مدیریت اساتید',
    ProfessorsBookManeges: 'مدیریت دروس اساتید',
    Professors: 'اساتید',
    Professor: 'استاد',
    roles: 'نقش',
    showForRoles: 'نمایش برای نقش های',
    level: 'پایه',
    book: 'کتاب',
    books: 'کتاب ها',
    fields: 'گرایش ها',
    field: 'فیلد',
    search_type: 'نوع جستجو',
    educationOrientations: "گرایش های تحصیلی",
    userManagement: "مدیریت کاربران",
    educationBasic: "پایه های تحصیلی",
    educationLessons: "دروس",
    educationDegrees: 'مقاطع تحصیلی',
    educationDeg: 'مقطع',
    educationDegree: 'مقطع تحصیلی',
    baseData: 'اطلاعات پایه',
    aboutUsManagement: 'مدیریت درباره ما',
    enterPass: 'کلمه عبور خود را وارد کنید',
    teacher: 'معلم',
    teachers: 'معلمین',
    accounting: 'حسابدار',
    action: 'عملیات',
    active: 'فعال',
    activity: 'فعالیت',
    add: 'افزودن',
    addUser: 'ثبت کاربر',
    address: 'آدرس',
    addresses: 'آدرس‌ها',
    addressHint: 'ابتدا محدوده را روی نقشه مشخص و سپس آدرس را به صورت کامل وارد کنید',
    admin: 'مدیر',
    all: 'همه',
    amount: 'مبلغ',
    android: 'اندروید',
    answer: 'پاسخ',
    app: 'اپلیکیشن',
    wastage: 'ضایعات',
    backHome: 'بازگشت به صفحه اصلی',
    bankInfo: 'اطلاعات دریافتی از بانک',
    barcode: 'بارکد',
    basedOnMeters: 'بر اساس متر',
    birthdate: 'تاریخ تولد',
    bottom: 'پایین',
    brand: 'برند',
    bronze: 'برنزی',
    buy: 'خرید',
    calcPerMonth: 'مبلغ خرید بر حسب ماه',
    cancel: 'لغو',
    canceled: 'لغو شده',
    cash: 'نقدی',
    cardReader: 'کارت خوان',
    carrier: 'حمل کننده',
    category: 'دسته‌بندی',
    center: 'وسط',
    choose: 'انتخاب کنید',
    chooseImage: 'انتخاب تصویر',
    chooseLocation: 'انتخاب مکان',
    client: 'سفارش دهنده',
    close: 'بستن',
    code: 'کد',
    confirm: 'تایید',
    confirmAddVIP: 'از VIP کردن این کاربر اطمینان دارید؟',
    confirmCancelOrder: 'از لغو این سفارش اطمینان دارید؟',
    confirmCatActivate: 'از فعال کردن این دسته‌بندی اطمینان دارید؟',
    confirmCatDeactivate: 'از غیرفعال کردن این دسته‌بندی اطمینان دارید؟',
    confirmDelete: 'از حذف این آیتم اطمینان دارید؟',
    confirmDeleteCat: 'از حذف این دسته‌بندی اطمینان دارید؟',
    confirmDeleteImage: 'از حذف این تصویر اطمینان دارید؟',
    confirmDeleteAdvertise: 'از حذف این تبلیغ اطمینان دارید؟',
    confirmDeleteMessage: 'از حذف این پیام اطمینان دارید؟',
    confirmDeleteOffer: 'از حذف این پیشنهاد اطمینان دارید؟',
    confirmDeleteOrder: 'از حذف این سفارش اطمینان دارید؟',
    confirmDeleteProduct: 'از حذف این محصول اطمینان دارید؟',
    confirmDeleteUser: 'از حذف این کاربر اطمینان دارید؟',
    confirmDelVIP: 'از غیر VIP کردن این کاربر اطمینان دارید؟',
    confirmActivate: 'از فعال کردن این آیتم اطمینان دارید؟',
    confirmReject: 'از رد کردن این آیتم اطمینان دارید؟',
    confirmWait: 'از در انتظار کردن این آیتم اطمینان دارید؟',
    confirmDeactivate: 'از غیرفعال کردن این آیتم اطمینان دارید؟',
    confirmOfferActivate: 'از فعال کردن این پیشنهاد اطمینان دارید؟',
    confirmOfferDeactivate: 'از غیرفعال کردن این پیشنهاد اطمینان دارید؟',
    confirmProductActivate: 'از فعال کردن این محصول اطمینان دارید؟',
    confirmProductDeactivate: 'از غیرفعال کردن این محصول اطمینان دارید؟',
    confirmSuspendOrder: 'از تعلیق این سفارش اطمینان دارید؟',
    confirmText: 'آیا اطمینان دارید؟',
    confirmUserActivate: 'از فعال کردن این کاربر اطمینان دارید؟',
    confirmUserDeactivate: 'از غیرفعال کردن این کاربر اطمینان دارید؟',
    contactInfo: 'اطلاعات تماس',
    continuation: 'ادامه',
    copyright: 'تمامی حقوق این وب سایت متعلق به شناسا ملک می‌باشد.',
    costService: 'هزینه خدمات دهی',
    costShippingRadius: 'شعاع خدمات دهی',
    count: 'تعداد',
    countPredictedDistance: 'تعداد ارسال محصولات',
    courierList: 'لیست پیک‌ها',
    credit: 'اعتبار',
    credential: 'اعتباری',
    dashboard: 'داشبورد',
    dataEntries: 'ورودی‌ها',
    date: 'تاریخ ثبت',
    dateAndTime: 'ساعت و تاریخ',
    dailyPosition: 'تعداد فروش امروز',
    decrease: 'کاهش',
    dedicated: 'اختصاصی',
    delete: 'حذف',
    delivered: 'تحویل',
    delivery: 'پیک',
    description: 'توضیحات',
    details: 'جزئیات',
    device: 'دستگاه',
    drafts: 'پیش‌نویس‌ها',
    discount: 'تخفیف',
    edit: 'ویرایش',
    email: 'ایمیل',
    emailHint: 'ایمیل خود را وارد کنید',
    endDate: 'تاریخ پایان',
    enter: 'ورود',
    enterCode: 'کد پیامک را وارد کنید',
    enterMobile: 'شماره همراه را وارد کنید',
    errorJpg: 'شما فقط می‌توانید تصویر jpg بارگذاری کنید',
    errorPng: 'شما فقط می‌توانید تصویر png بارگذاری کنید',
    factor: 'فاکتور',
    faq: 'سوالات متداول',
    filterByMainCat: 'فیلتر بر اساس دسته‌بندی اصلی',
    filterByGrade: 'فیلتر بر اساس مقطع',
    filterByfield: 'فیلتر بر اساس گرایش تحصیلی',
    filterByLevel: 'فیلتر بر اساس پایه تحصیلی',
    filterByPermision: 'فیلتر بر اساس سطح دسترسی',
    filterByBook: 'فیلتر بر اساس کتاب',
    filterBySubCat: 'فیلتر بر اساس دسته‌بندی فرعی',
    firstName: 'نام',
    finalPrice: 'قابل پرداخت',
    four: 'چهار',
    freeShippingRadius: 'شعاع حمل و نقل رایگان',
    from: 'از',
    fromDate: 'از تاریخ',
    fullName: 'نام و نام خانوادگی',
    gold: 'طلایی',
    grouping: 'گروهی',
    image: 'تصویر',
    images: 'تصاویر',
    inactive: 'غیرفعال',
    increase: 'افزایش',
    information: 'اطلاعات',
    inside: 'محدوده',
    instagram: 'اینستاگرام',
    inventory: 'موجودی انبار',
    storage: 'انبارگردانی',
    store: 'انبار',
    ios: 'آی او اس',
    key: 'کلید',
    lastName: 'نام‌خانوادگی',
    latitude: 'طول جغرافیایی',
    link: 'لینک',
    list: 'لیست',
    loading: 'در حال بارگذاری...',
    location: 'موقعیت',
    login: 'پنل مدیریت شناسا ملک',
    logout: 'خروج از پنل',
    longitude: 'عرض جغرافیایی',
    main: 'اصلی',
    maximumShop: 'کاربران با بیشترین خرید',
    maximumShopProduct: 'محصولات با بیشترین خرید',
    message: 'پیام',
    messages: 'پیام‌ها',
    mobile: 'موبایل',
    mobileHint: '09152222222',
    mobilePhone: 'تلفن همراه / ثابت',
    mobilePhoneHint: '09152222222 / 0513222222',
    month: 'ماه',
    move: 'حرکت',
    nameHint: 'ترجیحا به صورت فارسی وارد کنید',
    new: 'جدید',
    no: 'خیر',
    normal: 'معمولی',
    notfound: 'متأسفیم، چنین صفحه‌ای وجود ندارد.',
    notfoundCode: '۴۰۴',
    offBrands: 'برندهای تخفیف‌دار',
    one: 'یک',
    operator: 'اپراتور',
    order: 'سفارش',
    orders: 'سفارشات',
    other: 'سایر',
    otherPhones: 'سایر تلفن‌ها',
    outside: 'خارج',
    patterns: 'اطلاعات وارد شده صحیح نمی‌باشد',
    payment: 'پرداخت',
    people: 'اشخاص',
    permission: 'دسترسی',
    permissions: 'دسترسی‌ها',
    phone: 'تلفن',
    postman: 'پیک',
    port: 'درگاه بانک',
    phoneOrders: 'سفارشات تلفنی',
    pos: 'کارت خوان',
    pointRank: 'امتیاز و رتبه',
    product: 'محصول',
    products: 'محصولات',
    profile: 'پروفایل',
    profileManagement: 'مدیریت پروفایل',
    price: 'قیمت',
    public: 'عمومی',
    process: 'پردازش',
    question: 'سوال',
    questions: 'سوالات',
    file: 'فایل',
    rank: 'رتبه',
    record: 'ثبت',
    recordAndPrint: 'ثبت و پرینت',
    receive: 'دریافت',
    receivedMessage: 'پیام‌های دریافتی',
    receiver: 'تحویل گیرنده',
    registrar: 'ثبت کننده',
    report: 'گزارش',
    required: 'این فیلد الزامی می‌باشد',
    show_header_title: 'نمایش عنوان هدر',
    returned: 'مرجوعی',
    rial: 'ريال',
    safeFloor: 'تعداد کف مطمئنه',
    shareFriends: 'معرفی به دوستان',
    search: 'جستجو...',
    RegisterPriorities: 'ثبت اولویت ها',
    searchHintCategory: 'عنوان',
    searchHintMessageUser: 'نام و نام خانوادگی، تلفن همراه',
    searchHintDiscountCode: 'عنوان کد تخفیف',
    searchHintOrder: 'کاربر، تلفن، محصول، بارکرد و حمل کننده',
    searchHintProduct: 'عنوان، دسته‌بندی، بارکد و قیمت',
    searchHintUser: 'نام / تلفن همراه',
    searchByTitle: 'جستجو بر اساس عنوان',
    searchHintTeacherLesson: 'فیلتر بر اساس نام استاد',
    searchHintEducationLesson: 'نام درس مورد نظر',
    section: 'بخش',
    sectionsManagement: 'مدیریت بخش ها',
    select: 'انتخاب',
    sellingPrices: 'مقدار فروش امروز',
    sendCode: 'ارسال کد',
    sendNotif: 'ارسال نوتیفیکیشن',
    sendToAll: 'ارسال به همه کاربران',
    sendToTeacher: 'ارسال به اساتید',
    sendToAdmin: 'ارسال به ادمین ها',
    sendToUser: 'ارسال به کاربران',
    sendToConsulting: 'ارسال به مشاورین',
    sendToPodcaster: 'ارسال به پادکستر ها',
    serverError: 'مشکلی در انجام عملیات به وجود آمده است',
    serverSuccess: 'عملیات با موفقیت انجام شد',
    settings: 'تنظیمات',
    shopPostman: 'پیک فروشگاه',
    show: 'نمایش',
    silver: 'نقره‌ای',
    site: 'site',
    panel: 'panel',
    slider: 'اسلایدر',
    chatManagement: 'مدیریت چت',
    counterItem: 'شمارنده',
    spec: 'مشخصات',
    specialOffers: 'پیشنهادات ویژه',
    specialOffersWarning: 'حداقل 9 محصول باید انتخاب شود',
    startDate: 'تاریخ شروع',
    status: 'وضعیت',
    stop: 'توقف',
    storeCourier: 'پیک فروشگاه',
    sub: 'فرعی',
    subCats: 'زیر دسته‌ها',
    submit: 'ارسال',
    successful: 'موفق',
    sum: 'جمع',
    sumDelivery: 'هزینه ارسال محصولات',
    support: 'پشتیبانی',
    suspend: 'معلق',
    suspension: 'تعلیق',
    suspensionFactors: 'فاکتورهای تعلیقی',
    tag: 'تگ',
    tags: 'تگ‌ها',
    telegram: 'تلگرام',
    terms: 'قوانین و مقررات',
    text: 'متن',
    title: 'عنوان',
    inquirySlug: 'نام نمایشی در استعلام',
    three: 'سه',
    to: 'تا',
    toDate: 'تا تاریخ',
    top: 'بالا',
    total: 'کل',
    have: 'داشته باشد',
    trackingCode: 'کد پیگیری',
    transferCost: 'هزینه حمل',
    transferCostHelp: 'عدم ورود مبلغ = محاسبه از روی نقشه',
    transportation: 'حمل و نقل',
    two: 'دو',
    equal: 'مساوی',
    type: 'نوع',
    unauthorized: 'متأسفیم، شما به این صفحه دسترسی ندارید.',
    unauthorizedCode: '۴۰۳',
    uploader: 'آپلودر',
    unknown: 'ناشناس',
    unsuccessful: 'ناموفق',
    uploadError: 'مشکلی در ارسال فایل به وجود آمده است',
    uploadErrorNotEqual: 'باید حداقل یک ویدئو به همراه پوستر آپلود شود.',
    uploadImage: 'بارگذاری تصویر',
    uploadSuccess: 'فایل با موفقیت بارگذاری شد',
    uploadText: 'برای بارگیری عکس، روی این منطقه کلیک کنید یا عکس را اینجا رها کنید',
    user: 'کاربر',
    userName: 'نام کاربری',
    usersNow: 'کاربران جدید این هفته',
    users: 'کاربران',
    view: 'نمایش',
    vip: 'ویژه',
    warehousing: 'انبارداری',
    web: 'وب',
    withoutBarcode: 'فاقد بارکد',
    withoutCategory: 'فاقد دسته‌بندی',
    withoutImage: 'فاقد تصویر',
    withPhone: 'تلفنی',
    yes: 'بله'
};
