import React, {Component, Fragment} from 'react';
import {cloneDeep} from "lodash";
import {Col, Modal, Row, Input, Form, Upload, Select, Radio, Button, Tree} from "antd";
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import {ProjectOutlined, InboxOutlined, DeploymentUnitOutlined, ExceptionOutlined} from '@ant-design/icons';
import API from "../scripts/api";


class AddAgentToAdvertise extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // themes : [ 'classic', 'metro', 'moonlight', 'purple', 'green', 'orange', 'red', 'silver' ],
            themes : [ 'moonlight'],
            singleMode:false,
            checkChildren:false,
            checkParents:false,
            checked:[1],
            check:[],
            selectedKeys:[],
            items: [],
            fileList: [],
            agents: [],
            phones: [],
            id: '',
            formValues: [] // STATE HATO INJA BAYAD BEZARI
        };
        this.formRef = React.createRef();
        this.mapRef = React.createRef();
    }

    handleSubmit = () => {
        let {infoCounterArr} = this.state
        this.formRef.current.validateFields()
            .then(values => {
                const formData = new FormData;
                formData.append('agent_id', values.agent_id);
                formData.append('phone_id', values.phone_id);
                formData.append('selectedKeys', this.state.selectedKeys);


                if (this.state.editMode){
                    formData.append("id", this.state.id);
                    this.props.onSubmit(formData, this.state.id);
                } else {
                    this.props.onSubmit(formData);
                }
            });
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();
    };

    handleDidMount = async (data = {}) => {

        const cloneData = cloneDeep(data);
        await this.formRef.current.setFieldsValue(cloneData);
        await this.setState({editMode: !!cloneData.id, id: cloneData.id});
        this.setState({check: []});
        this.setState({items: []});
        this.setState({selectedKeys: []});
        console.log("this is edit selected ")
        if(!!cloneData.id){
            console.log("this is edit selected ",  cloneData.selected)
             await this.setState({id: cloneData.id});
             await this.setState({check: cloneData.selected});
        }else {
            this.formRef.current.resetFields();
        }
        await this.getRawList()
        await this.getAgentList()
        if(!!cloneData.id){
            await this.getPhones(cloneData.agent_id)
        }
        console.log("this is edit selected ", this.state.check)
    }

    getRawList = (params = {}) => {
        this.setState({ loading: true });
        API.agentToAdvertise.rawList()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        items: result.data,
                        // check: result.selectedIds,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    getAgentList = (params = {}) => {
        this.setState({ loading: true });
        API.agent.list()
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        agents: result.data,
                        // check: result.selectedIds,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    handleSubmit(event) {
        event.preventDefault();
        alert(JSON.stringify(this.state.formValues));
    }


     getPhones = (event) => {
        this.setState({ loading: true });
        API.agent.info(event)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        phones: result.data,
                        // check: result.selectedIds,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        const {onCancel, submitting, visible} = this.props;
        const { editMode, items, check, agents, phones} = this.state; // INJA STATE HATO MIYARI TO RENDER INJA FARAKHONI MISHE AZ BAL

        const onSelect = (selectedKeys, info) => {
            console.log('selected', selectedKeys, info);
        };

        const onCheck = (checkedKeys, info) => {
            console.log('onCheck', checkedKeys, info);
            this.setState({selectedKeys: checkedKeys});
        };
        return (

            <Fragment>
                <Modal
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} layout="vertical" >


                        <Row gutter={24}>


                            <Col xs={24} md={12}>
                                <Form.Item name="agent_id" label={Resources.agent}  >
                                    <Select
                                        onChange={(e) => this.getPhones(e)}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {agents.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="phone_id" label={Resources.phone} >
                                    <Select >
                                        {phones.map(item => (
                                            <Select.Option key={item.id}  value={item.id}>{item.phone}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>


                            {(!!items.length)  && <Tree
                                checkable
                                defaultExpandedKeys={[]}
                                defaultSelectedKeys={check}
                                defaultCheckedKeys={check}
                                onSelect={onSelect}
                                onCheck={onCheck}
                                treeData={items}
                            />}
                        </Row>

                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default AddAgentToAdvertise;
