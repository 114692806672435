import React, {Component, Fragment} from 'react';
import {cloneDeep} from "lodash";
import {Col, Modal, Row, Input, Form, Upload, Select, Radio, Button} from "antd";
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import {ProjectOutlined, InboxOutlined, DeploymentUnitOutlined, ExceptionOutlined} from '@ant-design/icons';
import API from "../scripts/api";
import { Circle, Map, TileLayer, Marker } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from "leaflet";
class ManageAdvertiseAgent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileList: [],

            countries: [],
            cities: [],
            visibleMap: "",
            id: '',
            infoCounterArr: [],
            dealTypesList: [],
            infoCounter: '',
            formValues: [], // STATE HATO INJA BAYAD BEZARI
            agents: [],
            managerSelected: '',

        };

        this.formRef = React.createRef();
        this.mapRef = React.createRef();

    }



    handleSubmit = () => {
        let {infoCounterArr} = this.state
        this.formRef.current.validateFields()
            .then(values => {
                const formData = new FormData;
                formData.append('agent', values.agent);

                if (this.state.editMode){
                    formData.append("id", this.state.id);
                    this.props.onSubmit(formData, this.state.id);
                } else {
                    this.props.onSubmit(formData);
                }
            });
    };

    handleWillUnmount = () => {
        console.log("modal is dieeeeeeeeeeeeeeee")
        this.formRef.current.resetFields();

    };

    handleDidMount = async (data = {}) => {
        await this.formRef.current.resetFields();

        const cloneData = await cloneDeep(data);
        console.log(!!cloneData.id);
        this.setState({infoCounter: 0, infoCounterArr:[]});
         this.setState({editMode: !!cloneData.id, id: cloneData.id});
        if(!!cloneData.id){}
        await this.getAgents()
    }


    getAgents = (params = {}) => {
        this.setState({ loading: true });
        API.agent.list(params)

            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        agents: result.data,
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };


    render() {
        const { onCancel, submitting, visible } = this.props;
        const { editMode, managers, agents } = this.state; // INJA STATE HATO MIYARI TO RENDER INJA FARAKHONI MISHE AZ BALA

        return (

            <Fragment>
                <Modal
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} layout="vertical" >
                        <Row gutter={24} >

                            <Col xs={24} md={12}>
                                <Form.Item  name="agent" label={Resources.agent}  >
                                    <Select
                                        showSearch
                                        filterOption={(input, option) => {
                                            return (
                                                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );
                                        }}
                                    >
                                        {agents.map(item => (
                                            <Select.Option title= {item.name}   style={{direction:"rtl"}} key={item.id}  value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default ManageAdvertiseAgent;
