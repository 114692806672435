import React, {Component, Fragment} from 'react';
import {
    Breadcrumb,
    Button,
    Col,
    Input,
    Row,
    Table,
    Tooltip,
    Checkbox,
    message,
    Dropdown,
    Menu,
    Switch,
    Modal,
    Drawer
} from "antd";
import JoditEditor from "jodit-react";
import Resources from "../scripts/resources";
import {PlusOutlined, SearchOutlined
, EditOutlined, LockOutlined, MenuOutlined, EyeOutlined, DeleteOutlined, ControlFilled} from '@ant-design/icons'
import {Constants, Permissions} from "../scripts/settings";
import AdvertiseHousingForm from "../forms/AdvertiseHousing";
import API from "../scripts/api";
import {connect} from "react-redux";
import {CheckPermission} from "../scripts/helpers";
import {SetRoute, UpdateUser} from "../scripts/action";
import PermissionForm from "../forms/Permission";
import {Form} from "antd/lib/form";
import ShowImage from "../forms/showImage";
import RejectAdvertice from "../forms/RejectAdvertice";
class JobRequestManagement extends Component {
    constructor() {
        super();

        this.state = {
            data: [],
            aclData: '',
            showDrawer: false,
            DrawerData: "",
            filter: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: Constants.pageSize,
                size: 'small'
            },
            visibleDeleteForm: false,
            visibleRejectForm: false,
            visibleFacilityForm: false,
            visibleRoomFacilityForm: false,
            selected: {},
            userRoles: '',
        };
        this.mediaRef = React.createRef();
        this.facilityRef = React.createRef();
        this.roomFacilityRef = React.createRef();
        this.formRef = React.createRef();
        this.rejectFormRef = React.createRef();
        // this.creditFormRef = React.createRef();
        // this.permissionFormRef = React.createRef();

        // this.handleFilter = debounce(this.handleFilter, 1000);
    }

    fetch = (params = {}) => {
        const { filterLevel, filterSearch } = this.state;

        params.q = filterSearch;
        params.level = filterLevel;

        this.setState({ loading: true });

        API.jobRequest.list(params)
            .then((result) => {
                if (result.data) {
                    this.setState({
                        loading: false,
                        data: result.data,
                        pagination: {
                            ...this.state.pagination, ...{
                                current: result.data.current_page,
                                pageSize: result.data.per_page,
                                total: result.data.total
                            }
                        }
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    componentDidMount() {
        const { onSetRoute, routeName, parentName } = this.props;

        onSetRoute({ openKey: parentName, selectedKey: routeName });

        this.fetch({ page: 1 });

        API.acl.rules()
            .then(res => {
                this.setState({
                    userRoles: res
                });
            })

        API.acl.all()
            .then(res => {
                this.setState({aclData : res})})
    }

    handlePermissions = (values) => {
        this.setState({ submitting: true });
        API.acl.save(values, this.state.selected.id)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hidePermission();
                this.fetch({ page: this.state.pagination.current });
            })
    };

    showPermission = (user = {}) => {
        this.setState({ visiblePermission: true, selected: user });

        // setTimeout(() => {
        //     this.permissionFormRef.current.handleDidMount(user);
        // }, 0);
    };

    hidePermission = () => {
        this.setState({ visiblePermission: false });

        // setTimeout(() => {
        //     this.permissionFormRef.current.handleWillUnmount();
        // }, 0);
    };

    showForm = (advertise = {}) => {
        this.setState({ visibleForm: true, selected: advertise });

        setTimeout(() => {
            this.formRef.current.handleDidMount(advertise);
        }, 0);
    };

    hideForm = () => {
        this.setState({ visibleForm: false });

        setTimeout(() => {
            this.formRef.current.handleWillUnmount();
        }, 0);
    }

    handleSubmit = (values, roles) => {
        const {userRoles} = this.state;
        this.setState({ submitting: true });
        const action = this.state.selected.id ? API.jobRequest.update : API.jobRequest.add;

        if (action === API.jobRequest.update) {
            values.append('id', this.state.selected.id);

        }

        action(values)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideForm();
                this.fetch({ page: this.state.pagination.current });
                if(action === API.user.update){
                    API.profile.info().then((result) => {
                        this.props.onUpdateUser(result);
                    })
                }
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };


    handleStatus = (record) => {

        if(record.status == "0"){
            return Resources.wait
        }

        if(record.status == "1"){
            return Resources.confirm
        }

        if(record.status == "-1"){
            return Resources.reject
        }
        if(record.status == "-2"){
            return Resources.deleted
        }

    };

    handleUserSearch = (value) => {
        this.setState({ filterUserSearch: value });
    };

    CvView = (record) => {
        this.setState({
            showDrawer: true,
            DrawerData : record.cv
        })
    };
    onCloseDrawer = () => {
        this.setState({
            showDrawer: false,
            DrawerData: '',
        })
    }

    handleDelete = (record) => {
        Modal.confirm({
            title: Resources.confirmDelete,
            okText: Resources.yes,
            okType: 'danger',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({loading : true});
                API.jobRequest.del(record.id)
                    .then(res => {
                        message.success(Resources.successful);
                        this.fetch()
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };
    handleActive = (record) => {
        Modal.confirm({
            title: Resources.confirmActivate,
            okText: Resources.yes,
            okType: 'success',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({loading : true});
                API.jobRequest.active(record.id)
                    .then(res => {
                        message.success(Resources.successful);
                        this.fetch()
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };

    handleDeActive = (record) => {
        Modal.confirm({
            title: Resources.confirmReject,
            okText: Resources.yes,
            okType: 'danger',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({loading : true});
                API.jobRequest.deActive(record.id)
                    .then(res => {
                        message.success(Resources.successful);
                        this.fetch()
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };
    handleWait = (record) => {
        Modal.confirm({
            title: Resources.confirmWait,
            okText: Resources.yes,
            okType: 'danger',
            cancelText: Resources.no,
            onOk: () => {
                this.setState({loading : true});
                API.jobRequest.wait(record.id)
                    .then(res => {
                        message.success(Resources.successful);
                        this.fetch()
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };

    showDeleteForm = (article = {}) => {
        this.setState({ visibleDeleteForm: true, selected: article });
        setTimeout(() => {
            this.mediaRef.current.handleDidMount(article);
        }, 0);
    };



    hideDeleteForm = () => {
        this.setState({ visibleDeleteForm: false });

        setTimeout(() => {
            this.formRef.current.handleWillUnmount();
        }, 0);
    };



    handleDeleteSubmit = (id) => {
        this.setState({ submitting: true });

        API.gallery.delete(id)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideDeleteForm();
                this.fetch({ page: this.state.pagination.current });
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };

    showRejectForm = (item = {}) => {
        this.setState({ visibleRejectForm: true, selected: item });
        setTimeout(() => {
            this.rejectFormRef.current.handleDidMount(item);
        }, 0);
    };

    hideRejectForm = () => {
        this.setState({ visibleRejectForm: false });

        setTimeout(() => {
            this.rejectFormRef.current.handleWillUnmount();
        }, 0);
    };

    handleRejectSubmit = (values) => {
        this.setState({ submitting: true });

        API.jobRequest.deActive(values)
            .then((result) => {
                message.success(result.message);
                this.setState({ submitting: false });
                this.hideRejectForm();
                this.fetch({ page: this.state.pagination.current });
            })
            .catch(() => {
                this.setState({ submitting: false });
            });
    };


    render() {
        const{ visibleForm, submitting, data, aclData, visiblePermission, visibleRoomFacilityForm, visibleFacilityForm, loading, visibleRejectForm, filterUserSearch, visibleDeleteForm } = this.state;
        const { permissions, onUpdateUser } = this.props;

        const columns = [{
            title: Resources.user,
            dataIndex: 'user',
            className: 'col-center',
            width: '120px'
        }, {
            title: Resources.mobile,
            dataIndex: 'user_mobile',
            className: 'col-center',
            width: '120px'
        }, {
            title: Resources.title,
            dataIndex: 'title',
            className: 'col-center',
            width: '120px'
        },  {
                title: Resources.phone,
                dataIndex: 'phone',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.jobCat,
                dataIndex: 'job_cat',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.jobType,
                dataIndex: 'job_type',
                className: 'col-center',
                width: '120px'
            },

            {
            title: Resources.country,
            dataIndex: 'country',
            className: 'col-center',
            width: '120px'
        }, {
            title: Resources.state,
            dataIndex: 'state',
            className: 'col-center',
            width: '120px'
        },{
                title: Resources.city,
                dataIndex: 'city',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.neighborhood,
                dataIndex: 'neighborhood',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.delete_reason,
                dataIndex: 'delete_reason',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.reject_reason,
                dataIndex: 'reject_reason',
                className: 'col-center',
                width: '120px'
            },
            {
            title: Resources.status,
            dataIndex: 'status',
            className: 'col-center',
            width: '120px',
            render: (val, record) => <span > {this.handleStatus(record)}</span>
        },
            {
                title: Resources.description,
                dataIndex: 'description',
                className: 'col-center',
                width: '120px'
            },
            {
                title: Resources.date,
                dataIndex: 'date',
                className: 'col-center',
                width: '120px'
            },



        ,{
            title: Resources.action,
            dataIndex: 'id',
            className: 'col-center',
            width: '80px',
            render: (val, record) => (
                <Dropdown overlay={<Menu>
                    {/*<Menu.Item>*/}
                    {/*    <span onClick={() => this.showForm(record)}><EyeOutlined /> {Resources.details}</span>*/}
                    {/*</Menu.Item>*/}
                    <Menu.Item>
                        <span onClick={() => this.handleDelete(record)}><DeleteOutlined /> {Resources.delete}</span>
                    </Menu.Item>
                    {/*<Menu.Item>*/}
                    {/*    <span onClick={() => this.showDeleteForm(record)}><DeleteOutlined  />{Resources.manageImage}</span>*/}
                    {/*</Menu.Item>*/}

                    {(record.status == "0") &&

                    <Menu.Item>
                        <span onClick={() => this.handleActive(record)}><ControlFilled/> {Resources.confirm}</span>
                    </Menu.Item>
                    }
                    {(record.status == "0")   &&
                        <Menu.Item>
                        <span onClick={() => this.showRejectForm(record)}><ControlFilled /> {Resources.rejectAdd}</span>
                        </Menu.Item>
                    }

                    {(record.status == "1") &&

                    <Menu.Item>
                        <span onClick={() => this.handleWait(record)}><ControlFilled/> {Resources.wait}</span>
                    </Menu.Item>
                    }
                    {(record.status == "1") &&
                    <Menu.Item>
                        <span onClick={() => this.showRejectForm(record)}><ControlFilled /> {Resources.rejectAdd}</span>
                    </Menu.Item>
                    }

                    {(record.status == "-1") &&

                    <Menu.Item>
                        <span onClick={() => this.handleWait(record)}><ControlFilled/> {Resources.wait}</span>
                    </Menu.Item>
                    }
                    {(record.status == "-1")   &&
                    <Menu.Item>
                        <span onClick={() => this.handleActive(record)}><ControlFilled /> {Resources.confirm}</span>
                    </Menu.Item>
                    }
                    {(record.status == "-2")   &&
                    <Menu.Item>
                        <span onClick={() => this.handleActive(record)}><ControlFilled /> {Resources.confirm}</span>
                    </Menu.Item>
                    }

                </Menu>}>
                    <Button type="link" icon={<MenuOutlined />} />
                </Dropdown>
            )
        }];

        const filtredUsers = data.filter(o => {
            let query = false;

            if (filterUserSearch) {
                if (o.title && o.title.indexOf(filterUserSearch) >= 0)
                    query = true;

                if (o.user && o.user.indexOf(filterUserSearch) >= 0)
                    query = true;
                if (o.user_mobile && o.user_mobile.indexOf(filterUserSearch) >= 0)
                    query = true;
                if (o.country && o.country.indexOf(filterUserSearch) >= 0)
                    query = true;
                if (o.state && o.state.indexOf(filterUserSearch) >= 0)
                    query = true;
                if (o.city && o.city.indexOf(filterUserSearch) >= 0)
                    query = true;
                if (o.neighborhood && o.neighborhood.indexOf(filterUserSearch) >= 0)
                    query = true;
                if (o.job_type && o.job_type.indexOf(filterUserSearch) >= 0)
                    query = true;
                if (o.job_cat && o.job_cat.indexOf(filterUserSearch) >= 0)
                    query = true;
            } else {
                query = true;
            }
            return query;
        });

        const config = {
            readonly: true, // all options from https://xdsoft.net/jodit/doc/
            toolbar: false,
            toolbarButtonSize: "xsmall",
            placeholder: "رزومه خود را ثبت کنید"
        }

        return (
            <Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item>{Resources.jobRequest}</Breadcrumb.Item>
                </Breadcrumb>
                <div className='ant-content'>
                    <Row gutter={24}>
                        {/*<Col xs={24} md={4}>*/}
                        {/*    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.showForm()} block>{Resources.add}</Button>*/}
                        {/*</Col>*/}
                        <Col xs={24} md={14} style={{ paddingTop: 8 }}>
                            <Checkbox.Group onChange={value => console.log(value)} />
                        </Col>
                        <Col xs={24} md={6}>
                            <Tooltip title={Resources.search}>
                                <Input
                                    type="text"
                                    prefix={<SearchOutlined />}
                                    placeholder={Resources.search}
                                    onChange={({ target: { value } }) => this.handleUserSearch(value)}
                                    allowClear
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                </div>
                <div className="ant-content">
                    <Table
                        columns={columns}
                        dataSource={filtredUsers}
                        loading={loading}
                        // onChange={this.handleTableChange}
                        // pagination={pagination}
                        size="small"
                        rowKey="id"
                        // scroll={{ x: 900 }}
                    />
                    {/*{!!pagination.total && <PaginationInfo current={pagination.current} pageSize={pagination.pageSize} total={pagination.total} />}*/}
                </div>
                {/*<PermissionForm selected={this.state.selected} permisionData={aclData} onCancel={this.hidePermission} onSubmit={this.handlePermissions} submitting={submitting} visible={visiblePermission} />*/}
                <AdvertiseHousingForm ref={this.formRef} onCancel={this.hideForm} onSubmit={this.handleSubmit} submitting={submitting} visible={visibleForm}  />
                {console.log(this.state.DrawerData)}
                <ShowImage ref={this.mediaRef} data={this.props.history.location.state} ifUpdate={this.state.selected.id} onCancel={this.hideDeleteForm} onSubmit={this.handleDeleteSubmit} onDelete={this.handleDeleteSubmit} visible={visibleDeleteForm} />
                <RejectAdvertice ref={this.rejectFormRef} onSubmit={this.handleRejectSubmit} data={this.props.history.location.state} ifUpdate={this.state.selected.id} onCancel={this.hideRejectForm}  visible={visibleRejectForm} />

                <Drawer
                    title={`رزومه استاد`}
                    placement="left"
                    closable={false}
                    onClose={this.onCloseDrawer}
                    visible={this.state.showDrawer}
                    width={500}
                >
                    <JoditEditor
                        tabIndex={3} // tabIndex of textarea
                        value={this.state.DrawerData? this.state.DrawerData : "روزمه ای ثبت نشده است."}
                        config={config}
                    />
                </Drawer>
            </Fragment>
        );
    }
}

export default connect(
    (state) => {
        return {
            permissions: {
                action: CheckPermission(state.app.user, Permissions.user_option),
                add: CheckPermission(state.app.user, Permissions.user_store)
            }
        };
    },
    (dispatch) => {
        return {
            onSetRoute: (data) => dispatch(SetRoute(data)),
            onUpdateUser: (data) => dispatch(UpdateUser(data)),
        };
    }
)(JobRequestManagement);
