import {message} from 'antd'


const CheckPermission = (user, permission) => {
    return user.level === 'admin' || ((user.level === 'accounting' || user.level === 'operator') && user.rules && user.rules.indexOf(permission) >= 0);
};

const CheckRoles = (user, permission) => {
    //["ad",""]
    if(user.roles.includes("admin")) {
        return true
    } else if(user.roles.includes("teacher")) {
        return true
    } else if(user.roles.includes("consultant")){
        return true
    } else if(user.roles.includes("podcast")){
        return true
    } else if(user.roles.includes("user")){
        return true
    } else {
        return false
    }
    if(user.roles.includes("admin")) {
        return true;
    } else {
        return user.roles.some(r=> permission.indexOf(r) >= 0)
    }
}

const PriceFormat = (value) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const SearchFormat = (value) => {
    return value.split('/').join('').split('ك').join('ک').split('ي').join('ی');
    //return value.split('/').join('').split('ك').join('ک').split('ی').join('ي');
}

const handleAclData = data => {
    console.log("data is: ", data)
    let arryOfKeys = Object.keys(data);
    let PersianKeys = [];
    console.log("arryOfKeys is: ", arryOfKeys)
    arryOfKeys.map(item => {
        PersianKeys.push(item);
        // switch (item) {
        //
        //     case 'document':
        //         PersianKeys.push('سند');
        //         break;
        //     case 'facility':
        //         PersianKeys.push('امکانات');
        //         break;
        //     case 'heatCool':
        //         PersianKeys.push('گرمایشی و سرمایشی');
        //         break;
        //     case 'floorCover':
        //         PersianKeys.push('کفپوش');
        //         break;
        //     case 'houseView':
        //         PersianKeys.push('نمای ساختمان');
        //         break;
        //     case 'cupboard':
        //         PersianKeys.push('کابینت');
        //         break;
        //     case 'user':
        //         PersianKeys.push('کاربر');
        //         break;
        //     case 'acl':
        //         PersianKeys.push('دسترسی ها');
        //         break;
        //     case 'documentType':
        //         PersianKeys.push('نوع سند');
        //         break;
        //     case 'positionLand':
        //         PersianKeys.push('موقعیت ملک');
        //         break;
        //     case 'jobCat':
        //         PersianKeys.push('دسته شغلی');
        //         break;
        //     case 'jobType':
        //         PersianKeys.push('نوع شغل');
        //         break;
        //     case 'jobRank':
        //         PersianKeys.push('رتبه کاری');
        //         break;
        //     case 'country':
        //         PersianKeys.push('کشورها');
        //         break;
        //     case 'state':
        //         PersianKeys.push('استان ها');
        //         break;
        //     case 'city':
        //         PersianKeys.push('شهرها');
        //         break;
        //     case 'street':
        //         PersianKeys.push('محله ها');
        //         break;
        //     case 'landType':
        //         PersianKeys.push('نوع ملک');
        //         break;
        //     case 'dealType':
        //         PersianKeys.push('نوع معامله');
        //         break;
        //     case 'roomFacility':
        //         PersianKeys.push('امکانات اتاق');
        //         break;
        //     case 'realStateRole':
        //         PersianKeys.push('نقش اعضای مشاورین املاک');
        //         break;
        //     case 'expertHousing':
        //         PersianKeys.push('تخصص در ملک');
        //         break;
        //     case 'priceCurrency':
        //         PersianKeys.push('واحد پولی');
        //         break;
        //     case 'areaUnit':
        //         PersianKeys.push('واحد اندازه گیری');
        //         break;
        //     case 'articleCat':
        //         PersianKeys.push('دسته مقالات');
        //         break;
        //     case 'article':
        //         PersianKeys.push('مقالات');
        //         break;
        //     case 'buildingStep':
        //         PersianKeys.push('مراحل ساخت');
        //         break;
        //     case 'connectUs':
        //         PersianKeys.push('ارتباط با ما');
        //         break;
        //     case 'cristSugg':
        //         PersianKeys.push('انتقادات و پیشنهادات');
        //         break;
        //     case 'cooperation':
        //         PersianKeys.push('مشارکت');
        //         break;
        //     case 'contactUs':
        //         PersianKeys.push('تماس با ما');
        //         break;
        //     case 'advertiseHousingOffer':
        //         PersianKeys.push('آگهی ملک');
        //         break;
        //     case 'advertiseHousingRequest':
        //         PersianKeys.push('تقاضای ملک');
        //         break;
        //     case 'socialNetwork':
        //         PersianKeys.push('شبکه های اجتماعی');
        //         break;
        //     case 'jobOffer':
        //         PersianKeys.push('آگهی شغل');
        //         break;
        //     case 'jobRequest':
        //         PersianKeys.push('تقاضای شغل');
        //         break;
        //     case 'news':
        //         PersianKeys.push('اخبار');
        //         break;
        //     case 'slider':
        //         PersianKeys.push('اسلایدر');
        //         break;
        //     case 'counterItem':
        //         PersianKeys.push('شمارنده');
        //         break;
        //     case 'aboutUs':
        //         PersianKeys.push('درباره ما');
        //         break;
        //     case 'terms':
        //         PersianKeys.push('قوانین و مقررات');
        //         break;
        //     case 'employer':
        //         PersianKeys.push('کارمندان');
        //         break;
        //     case 'banner':
        //         PersianKeys.push('بنر');
        //         break;
        //     case 'formBuilderAdverOffer':
        //         PersianKeys.push('فرم ساز آگهی ملک');
        //         break;
        //     case 'formBuilderAdverOfferIsOwner':
        //         PersianKeys.push('فرم ساز مالک بودن آگهی ملک');
        //         break;
        //     case 'formBuilderAdverRequest':
        //         PersianKeys.push('فرم ساز تقاضای ملک');
        //         break;
        //     case 'agentToAdvertise':
        //         PersianKeys.push('اتتصاب تمایندگی به آگهی');
        //         break;
        //     case 'agent':
        //         PersianKeys.push('تمایندگی');
        //         break;
        //     case 'uploader':
        //         PersianKeys.push('آبلودر');
        //         break;
        //     default:
        //         PersianKeys.push('تعریف نشده');
        //         break;
        // }
    });
    console.log("PersianKeys", PersianKeys)
    return PersianKeys;
};

const HadleAclCheckbox = (item, aclData) => {
    // return item
    console.log("item", item)
    console.log("aclData", aclData)
    return aclData[item]
    // switch (item) {
    //     case 'سند':
    //         return aclData.document;
    //     case 'امکانات':
    //         return aclData.facility;
    //     case 'گرمایشی و سرمایشی':
    //         return aclData.heatCool;
    //     case 'کاربر':
    //         return aclData.user;
    //     case 'کفپوش':
    //         return aclData.floorCover;
    //     case 'نمای ساختمان':
    //         return aclData.houseView;
    //     case 'کابینت':
    //         return aclData.cupboard;
    //     case 'نوع سند':
    //         return aclData.documentType;
    //     case 'دسترسی ها':
    //         return aclData.acl;
    //     case 'موقعیت ملک':
    //         return aclData.positionLand;
    //     case 'دسته شغلی':
    //         return aclData.jobCat;
    //     case 'نوع شغل':
    //         return aclData.jobType;
    //     case 'رتبه کاری':
    //         return aclData.jobRank;
    //     case 'کشورها':
    //         return aclData.country;
    //     case 'استان ها':
    //         return aclData.state;
    //     case 'شهرها':
    //         return aclData.city;
    //     case 'محله ها':
    //         return aclData.street;
    //     case 'نوع ملک':
    //         return aclData.landType;
    //     case 'نوع معامله':
    //         return aclData.dealType;
    //     case 'امکانات اتاق':
    //         return aclData.roomFacility;
    //     case 'نقش اعضای مشاورین املاک':
    //         return aclData.realStateRole;
    //     case 'تخصص در ملک':
    //         return aclData.expertHousing;
    //     case 'واحد پولی':
    //         return aclData.priceCurrency;
    //     case 'واحد اندازه گیری':
    //         return aclData.areaUnit;
    //     case 'دسته مقالات':
    //         return aclData.articleCat;
    //     case 'مقالات':
    //         return aclData.article;
    //     case 'مراحل ساخت':
    //         return aclData.buildingStep;
    //     case 'ارتباط با ما':
    //         return aclData.connectUs;
    //     case 'انتقادات و پیشنهادات':
    //         return aclData.cristSugg;
    //     case 'مشارکت':
    //         return aclData.cooperation;
    //     case 'تماس با ما':
    //         return aclData.contactUs;
    //     case 'آگهی ملک':
    //         return aclData.advertiseHousingOffer;
    //     case 'تقاضای ملک':
    //         return aclData.advertiseHousingRequest;
    //     case 'شبکه های اجتماعی':
    //         return aclData.socialNetwork;
    //     case 'آگهی شغل':
    //         return aclData.jobOffer;
    //     case 'تقاضای شغل':
    //         return aclData.jobRequest;
    //     case 'اخبار':
    //         return aclData.news;
    //     case 'اسلایدر':
    //         return aclData.slider;
    //     case 'شمارنده':
    //         return aclData.counterItem;
    //     case 'درباره ما':
    //         return aclData.aboutUs;
    //     case 'قوانین و مقررات':
    //         return aclData.terms;
    //     case 'کارمندان':
    //         return aclData.employer;
    //     case 'بنر':
    //         return aclData.banner;
    //     case 'فرم ساز آگهی ملک':
    //         return aclData.formBuilderAdverOffer;
    //     case 'فرم ساز مالک بودن آگهی ملک':
    //         return aclData.formBuilderAdverOfferIsOwner;
    //     case 'فرم ساز تقاضای ملک':
    //         return aclData.formBuilderAdverRequest;
    //     case 'اتتصاب تمایندگی به آگهی':
    //         return aclData.agentToAdvertise;
    //     case 'تمایندگی':
    //         return aclData.agent;
    //     case 'آبلودر':
    //         return aclData.uploader;
    //
    //     default:
    //         return []
    // }
};

const HandleErorr = (resFromCatch) => {
    let data = resFromCatch['response']['data'];
    let status = resFromCatch['response']['status'];
    if(status === 422) {
        return Object.values(data.errors).forEach(item => message.error(`${item}`, 5000));
    }
};

const ToStandartDate = (date) => {
    let Date = [];
    for (var key in date) {
        if (date.hasOwnProperty(key)) {
            Date.push(date[key])
        }
    }
    return Date.reverse().join('-')
}

export { CheckPermission, PriceFormat, SearchFormat, handleAclData, HadleAclCheckbox, CheckRoles, ToStandartDate};
