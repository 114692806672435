import React, {Component, Fragment} from 'react';
import {cloneDeep} from "lodash";
import {Col, Input, Modal, Row, Form} from "antd";
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import { EditorState, convertToRaw, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import JoditEditor from "jodit-react";
import {
    MailOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {stateToHTML} from "draft-js-export-html";

class AddTerms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileList: [],
            title: '',
            userRoles: '',
            selectedRoles: null,
            addresses: [{id: 0}],
            phones: [],
            showDrawer: true,
            DrawerData: "",
            selectedAddress: 0,
            visibleMap: false,
            editorState: EditorState.createEmpty(),
            id: ''
        };

        this.formRef = React.createRef();
        this.mapRef = React.createRef();
    }


    handleSubmit = () => {
        this.formRef.current.validateFields()
            .then(values => {
                const data = {
                    title: values.title,
                    // description: stateToHTML(this.state.editorState.getCurrentContent()),
                    description : this.state.DrawerData


                };
                if (this.state.editMode){
                    this.props.onSubmit(data, this.state.id);
                } else {
                    this.props.onSubmit(data);
                }
            });
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();

        this.setState({
            addresses: [{id: 0}],
            phones: []
        });
    };

    handleDidMount = (data = {}) => {
        const cloneData = cloneDeep(data);
        this.setState({editorState : "", DrawerData:""})

        this.formRef.current.setFieldsValue(cloneData);
        console.log(!!cloneData.id);
        this.setState({editMode: !!cloneData.id, id: cloneData.id});
        if(this.state.editMode){
            this.setState({DrawerData: cloneData.description})
            // this.setState({ editorState: EditorState.createWithContent(
            //         ContentState.createFromBlockArray(
            //             convertFromHTML(cloneData.description)
            //         )
            //     )});
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    }
    changeDrawer = (data) => {
        console.log("data is: ", data)
        this.setState({DrawerData: data})

    }


    render() {
        const {onCancel, submitting, visible} = this.props;
        const {addresses, editMode, phones, visibleMap, fileList, editorState} = this.state;
        const config = {
            readOnly: false,
            uploader: {
                insertImageAsBase64URI: true,
                insert: true,
            }
        }
        return (
            <Fragment>
                <Modal
                    width='80%'
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} layout="vertical">
                        <Row gutter={24}>
                            <Col xs={24} md={12}>
                                <Form.Item name="title" label={Resources.title} rules={Rules.required}>
                                    <Input type="text" prefix={<UserOutlined/>} placeholder={Resources.nameHint}/>
                                </Form.Item>
                            </Col>
                            {/*<Col xs={24} md={24}>*/}
                            {/*    <span> {Resources.description}</span>*/}
                            {/*    <Editor*/}
                            {/*        editorState={editorState}*/}
                            {/*        toolbarClassName="toolbarClassName"*/}
                            {/*        wrapperClassName="wrapperClassName"*/}
                            {/*        editorClassName="editorClassName"*/}
                            {/*        onEditorStateChange={this.onEditorStateChange}*/}
                            {/*    />;*/}
                            {/*</Col>*/}

                            <Col xs={24} md={24}>
                                <JoditEditor
                                    ref={this.formRef} // tabIndex of textarea
                                    tabIndex={3} // tabIndex of textarea
                                    value={this.state.DrawerData}
                                    config={config}
                                    // onChange={this.changeDrawer}
                                    onBlur={(newContent) => {
                                        this.setState({DrawerData: newContent})
                                    }}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default AddTerms;