import React, {Component, Fragment} from 'react';
import {cloneDeep} from "lodash";
import {Col, Modal, Row, Input, Form, Upload, Select} from "antd";
import Resources from "../scripts/resources";
import Rules from "../scripts/rules";
import {ProjectOutlined, InboxOutlined, DeploymentUnitOutlined} from '@ant-design/icons';

class AddBanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileList: [],
            title: '',
            userRoles: '',
            selectedRoles: null,
            addresses: [{id: 0}],
            phones: [],
            selectedAddress: 0,
            visibleMap: false,
            section: "article",
            id: ''
        };

        this.formRef = React.createRef();
        this.mapRef = React.createRef();
    }


    handleSubmit = () => {
        this.formRef.current.validateFields()
            .then(values => {
                const formData = new FormData;
                formData.append('link', values.link);
                formData.append('section', values.section);
                if (values.image && values.image.fileList && values.image.fileList.length)
                    formData.append('image', values.image.file, values.image.file.name);

                if (this.state.editMode){
                    formData.append("id", this.state.id);
                    this.props.onSubmit(formData, this.state.id);
                } else {
                    this.props.onSubmit(formData);
                }
            });
    };

    handleWillUnmount = () => {
        this.formRef.current.resetFields();
    };

    handleDidMount = (data = {}) => {
        const cloneData = cloneDeep(data);
        this.formRef.current.setFieldsValue(cloneData);
        console.log(!!cloneData.id);
        this.setState({editMode: !!cloneData.id, id: cloneData.id});
        if(this.state.editMode){
            this.setState({section: cloneData.section});
            console.log("cloneData.section", cloneData.section)
        }

    }


    render() {
        const {onCancel, submitting, visible} = this.props;
        const {addresses, editMode, phones, visibleMap, fileList, section} = this.state;


        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return { fileList: newFileList };
                });
            },
            beforeUpload: file => {
                this.setState({ fileList: [file], fileData: file });
                return false;
            },
            // fileList
        };

        return (
            <Fragment>
                <Modal
                    title={editMode ? Resources.edit : Resources.add}
                    visible={visible}
                    onOk={this.handleSubmit}
                    onCancel={onCancel}
                    okText={Resources.confirm}
                    cancelText={Resources.cancel}
                    okButtonProps={{loading: submitting}}
                    cancelButtonProps={{disabled: submitting}}
                >
                    <Form ref={this.formRef} layout="vertical">
                        <Row gutter={24}>
                            <Col xs={24} md={12}>
                                <Form.Item name="image" label={Resources.image} rules={editMode ? [] : Rules.required}>
                                    <Upload.Dragger disabled={submitting} {...props} className="ant-upload-block">
                                        <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                        <p className="ant-upload-hint">{Resources.uploadText}</p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="link" label={Resources.link} >
                                    <Input type="text" prefix={<ProjectOutlined/>}/>
                                </Form.Item>
                                <Form.Item name="section" label={Resources.section}  initialValue= {section}>
                                    <Select>
                                        <Select.Option  selected value="article">{Resources.article}</Select.Option>
                                        <Select.Option value="job_search">{Resources.jobSearch}</Select.Option>
                                        <Select.Option value="profile">{Resources.Profile}</Select.Option>
                                        <Select.Option value="house_search">{Resources.houseSearch}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default AddBanner;
